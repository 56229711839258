import Web3 from "web3";
import { ethers } from "ethers";
import { rpcLink, nftCollections } from "@config/CryptoConfig";

const GetBalance = (address, walletData) => {

    const { minABI } = nftCollections[address];

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(minABI, address);

    return new Promise(async(resolve, reject) => {
        try{
            await contract.methods.balanceOf('0x' + walletData.address).call().then((result) => {
                return resolve(result);
            }).catch((e) => {
                return reject(e);
            })
        } catch (e) {
            return reject(e);
        }
    })
}

export default GetBalance;