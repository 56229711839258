import React, { useState, useEffect } from "react";
import { Image } from 'react-image-and-background-image-fade';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Web3 from "web3";
import { NFTokens, rpcLink } from '@config/CryptoConfig';
import { GetBalance } from '@components/wallet/metasentry/utils/GetBalance';
import { GetTokenDetail } from '@components/wallet/metasentry/utils/GetTokenDetail';
import "@components/NFTokens/tokens/MetaSentry/styles/MetaSentry.scss";


const MetaSentryToken = () => {
    const navigate = useNavigate();
    const [connectionError, setConnectionError] = useState(false);
    const [balance, setBalance] = useState(0);
    const [tokenData, setTokenData] = useState([]);

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);

    const { minABI, contractAddress } = NFTokens.NFTMetaSentry;

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(minABI, contractAddress);

    useEffect(() => {
        GetBalance(contract, wallet)
            .then(balance => {
                setBalance(balance)
                GetTokenDetail([...Array(parseInt(balance)).keys()], contract, wallet)
                    .then(result => {
                        setTokenData(result)
                    })
                    .catch(e => {
                        setConnectionError(true)
                    })
            })
            .catch(e => {
                setConnectionError(true)
            })
    }, [])

    return (
        <div className="meta_container">
            <div className="mainWrapper">
                {connectionError ?
                    <div style={{ color: '#fff', fontSize: '24px' }}>Something went wrong!</div>
                    :
                    (<div className="yourMetasentry">
                        <h1 className="yourMetasentry__title">Your NFT MetaSentry Token:</h1>
                        <div className="yourMetasentry__content">
                            {balance > 0 ?
                                Object.entries(tokenData).map(([index, value]) => (
                                    <div key={index} className="yourMetasentry__elem">
                                        {value.act > 0 && (
                                            <div className="yourMetasentry__activated">
                                                <img
                                                    loading='lazy'
                                                    src={`/images/metasentry/done-icon.svg`}
                                                />
                                            </div>
                                        )}
                                        <div className={`yourMetasentry__imgWrap ${value.act > 0 && 'yourMetasentry__imgWrap-border'}`}>
                                            <img
                                                loading='lazy'
                                                className="yourMetasentry__img"
                                                src={`/images/metasentry/nft.png`}
                                            />
                                        </div>
                                        <div className="yourMetasentry__tokenId">Token ID: <b>{value.tId}</b></div>
                                    </div>
                                ))
                                :
                                <div className="yourMetasentry__title">You have no Metasentry Token</div>
                            }
                        </div>
                    </div>)
                }
            </div>
        </div>
    )
}

export default MetaSentryToken;