import React from "react";

const Loading = () => {
    return (
        <div className="errorInformer">
            Loading, please wait.
        </div>
    )
}

export default Loading;