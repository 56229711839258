import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { AristippusCodex2023Context } from "@components/NFTokens/contexts/AristippusCodex2023Context";
import { NFTokens } from "@config/CryptoConfig";
import { BackgroundImage } from 'react-image-and-background-image-fade';

const NftCard = ({ guidline }) => {

    const { color = 'yellow' } = useParams();

    const { nftColorGuidelineData } = useContext(AristippusCodex2023Context);

    const image = (guidline + 1) + '.png';
    const active = nftColorGuidelineData[guidline] > 0 ? true : false;
    const count = nftColorGuidelineData[guidline];

    // let maxCount = countData[guidline];
    // if(settings.collectionsQtys && settings.collectionsQtys[global.colorsName[color]] && settings.collectionsQtys[global.colorsName[color]][guidline]){
    //     maxCount = settings.collectionsQtys[global.colorsName[color]][guidline];
    // }

    return (
        <>
            {active ?
                <Link to={`/${NFTokens.NFTMetaCodex2023.baseUrl}/${color}/collection/${guidline + 1}`} className="maCollectionContent__link-active">
                    <div className={`maCollectionContent__link ${!active ? "maCollectionContent__link-disabled" : ""}`}>
                        <BackgroundImage
                            useChild
                            width='100%'
                            lazyLoad
                            src={require(`@images/aristippus_codex_2023/collection/${color}/${image}`)}
                        >
                            <div className="maCollectionContentLink__bg"></div>
                        </BackgroundImage>
                        <div className="maMainMenuLink__hover">
                            <div className="maMainMenuLinkHover__arrow">
                                →
                            </div>
                            <div className="maMainMenuLinkHover__text">
                                Click to view collection
                            </div>
                        </div>
                        <div className="maMainMenuLink__number">
                            <span className="maMainMenuLink__number-white">{count}</span>
                        </div>
                    </div>
                </Link>
                :
                <div>
                    <div className={`maCollectionContent__link ${!active ? "maCollectionContent__link-disabled" : ""}`}>
                        <BackgroundImage
                            useChild
                            width='100%'
                            lazyLoad
                            src={require(`@images/aristippus_codex_2023/collection/${color}/${image}`)}
                        >
                            <div className="maCollectionContentLink__bg"></div>
                        </BackgroundImage>

                        <div className="maMainMenuLink__hover">
                            <div className="maMainMenuLinkHover__arrow">
                                →
                            </div>
                            <div className="maMainMenuLinkHover__text">
                                Click to view collection
                            </div>
                        </div>
                        <div className="maMainMenuLink__number">
                            <span className="maMainMenuLink__number-white">{count}</span>
                        </div>
                    </div>
                </div>

            }
        </>

    )
}

export default NftCard;