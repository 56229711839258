import React from "react";
import NFTContainer from "@components/NFTokens/components/NFTContainer";
import Codex2023TokenCollection from "@components/NFTokens/tokens/Codex2023/Codex2023TokenCollection";
import AristippusCodex2023Provider from "@components/NFTokens/contexts/AristippusCodex2023Context";


const Codex2023CollectionPage = () => (
    <NFTContainer>
        <AristippusCodex2023Provider>
            <Codex2023TokenCollection />
        </AristippusCodex2023Provider>
    </NFTContainer>
);

export default Codex2023CollectionPage;