const ABIAristippusNft = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "getTokenDetailByIdx",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "tId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "owners",
                "type": "address"
            },
            {
                "internalType": "uint8",
                "name": "Guideline",
                "type": "uint8"
            },
            {
                "internalType": "uint8",
                "name": "Color",
                "type": "uint8"
            },
            {
                "internalType": "uint16",
                "name": "Number",
                "type": "uint16"
            },
            {
                "internalType": "uint256",
                "name": "lastFarming",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "coin",
                "type": "uint256"
            },
            {
                "internalType": "uint16",
                "name": "bns",
                "type": "uint16"
            },
            {
                "internalType": "uint16",
                "name": "nitro",
                "type": "uint16"
            },
            {
                "internalType": "uint16",
                "name": "akt",
                "type": "uint16"
            },
            {
                "internalType": "bool",
                "name": "placed",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getTokenDetail",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "tId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "owners",
                "type": "address"
            },
            {
                "internalType": "uint8",
                "name": "Guideline",
                "type": "uint8"
            },
            {
                "internalType": "uint8",
                "name": "Color",
                "type": "uint8"
            },
            {
                "internalType": "uint16",
                "name": "Number",
                "type": "uint16"
            },
            {
                "internalType": "uint256",
                "name": "lastFarming",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "coin",
                "type": "uint256"
            },
            {
                "internalType": "uint16",
                "name": "bns",
                "type": "uint16"
            },
            {
                "internalType": "uint16",
                "name": "nitro",
                "type": "uint16"
            },
            {
                "internalType": "uint16",
                "name": "akt",
                "type": "uint16"
            },
            {
                "internalType": "bool",
                "name": "placed",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function",
        "constant": true
    },
    {
        "constant": true,
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "feeFarming",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

export default ABIAristippusNft;