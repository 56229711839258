import React from "react";
import { NFTokens } from '@config/CryptoConfig';
import ListElement from "@components/NFTList/components/ListElement";
import styles from '@components/NFTList/styles/NFTList.module.scss';

const NFTList = () => (
    <div className={styles.NFTList_container}>
        {Object.keys(NFTokens).map((tokenKey) => (!NFTokens[tokenKey].hidden && <ListElement key={`link_${tokenKey}`} token={
            { ...NFTokens[tokenKey], name: tokenKey }
        } />))}
    </div>
);

export default NFTList;