import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthPage from '@pages/auth/AuthPage';

const AuthNavigation = () => {

    return (
        <Routes>
            <Route path="/" element={<AuthPage />}>
                <Route index element={<AuthPage />} />
            </Route>
        </Routes>
    )

}

export default AuthNavigation;