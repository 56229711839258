import React from "react";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { ethers } from "ethers";
import FeatherIcon from 'feather-icons-react';
import DefaultWrapper from "@components/wrappers/DefaultWrapper";
import Expansion from "@components/wallet/Expansion";
import Navbar from "@components/navbars/Navbar";

import { expansionConfig, coinsConfig } from "@config/CryptoConfig";
import ExpansionContext from "@providers/ExpansionContext";

const ExpansionPage = () => {

    const { coin } = useParams();
    const { title } = coinsConfig[coin];

    return (

        <DefaultWrapper>
            <Navbar />
            <div className="mainWrapper">
                <Link to="/" className="back__btn">← back</Link>
                <div className="mainWrapper__content">
                    <div className="title">Expansion: {title}</div>
                    <div className="expansions">

                        <ExpansionContext>
                            {expansionConfig[coin] && Object.keys(expansionConfig[coin]).length > 0 &&
                                <>
                                    {expansionConfig[coin].map((row, index) => {
                                        return (<Expansion coin={coin} index={index} key={index} />);
                                    })}
                                </>
                            }
                        </ExpansionContext>
                    </div>
                </div>
            </div>
        </DefaultWrapper>


    )

}

export default ExpansionPage;