const defaultState = {
    balance: null,
    farmingFee: 3,
    nftColorData: [],
    nftColorGuidelineData: [],
    nftCollectionData: [],
};

export const aristippusNftReducer = (state = defaultState, action) => {
    switch (action.type) {
        
        case 'SET_ANFT_BALANCE':
            return {...state, balance: action.payload}
        
        case 'SET_ANFT_FARMING_FEE':
            return {...state, farmingFee: action.payload}

        case 'SET_ANFT_COLOR_DATA':
            return {...state, nftColorData: action.payload}

        case 'SET_ANFT_COLOR_GUIDELINE_DATA':
            return {...state, nftColorGuidelineData: action.payload}

        case 'SET_ANFT_COLLECTION_DATA':
            return {...state, nftCollectionData: action.payload}
            
        case 'ANFT_PURGE':
            return defaultState;

        default:
            return state;
    }
}