import { GetBalance } from "@components/NFTokens/utils/GetBalance";
import { GetTokenDetail } from "@components/NFTokens/utils/GetTokenDetail";
import Web3 from "web3";
import { NFTokens, rpcLink } from "@config/CryptoConfig";
import ActionWrap from "@components/NFTokens/actions/ActionWrap";


export const fetchGOSHold = (contract, wallet) => new Promise(async (resolve, reject) => {

    try {
        const GOSHoldBalance = await GetBalance(contract, wallet);

        if (GOSHoldBalance < 1) throw new Error('Zero balance');

        const balanceArray = [...Array(parseInt(GOSHoldBalance)).keys()];
        const GOSHoldToken = await GetTokenDetail(balanceArray, contract, wallet);

        resolve(GOSHoldToken);
    } catch (err) {
        reject(err.message);
    }

});

export const fetchGOSHoldBonus = (contractBonus, wallet) => new Promise(async (resolve, reject) => {

    try {
        const GOSHoldBonusBalance = await GetBalance(contractBonus, wallet);

        if (GOSHoldBonusBalance < 1) throw new Error('Zero bonus balance');

        const bonusBalanceArray = [...Array(parseInt(GOSHoldBonusBalance)).keys()];
        const GOSHoldBonusToken = await GetTokenDetail(bonusBalanceArray, contractBonus, wallet);

        resolve(GOSHoldBonusToken);
    } catch (err) {
        reject(err.message);
    }

});

export const SendGOSHoldBonus = (tokenId, address, wallet) => (
    new Promise(async (resolve, reject) => {

        const web3Provider = new Web3.providers.HttpProvider(rpcLink);
        const web3 = new Web3(web3Provider);

        const { minABI, contractAddressBonus } = NFTokens.NFTGOSHold;
        const contract = new web3.eth.Contract(minABI, contractAddressBonus);

        try {
            const encoded = contract.methods.transferFrom('0x' + wallet.address, address, tokenId).encodeABI();

            ActionWrap(contractAddressBonus, wallet, encoded)
                .then((data) => {
                    //console.log(data);
                    return resolve(data);
                })
                .catch((e) => {
                    //console.log(e);
                    return reject(e);
                })

        } catch (e) {
            return reject(e);
        }
    })
);
