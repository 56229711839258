import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { TransformatorContext } from "@components/NFTokens/contexts/TransformatorContext";
import { NFTokens } from "@config/CryptoConfig";
import { BackgroundImage } from 'react-image-and-background-image-fade';

const NftCard = ({guidline}) => {

    const { color = 'yellow' } = useParams();
    const { nftColorGuidelineData } = useContext(TransformatorContext);

    const image = (guidline + 1) + '.png';
    const active = nftColorGuidelineData[guidline] > 0 ? true : false;
    const count = nftColorGuidelineData[guidline];

    return (
            <Link to={`/${NFTokens.NFTTransformator.baseUrl}/${color}${active ? `/collection/${guidline + 1}` : ``}`} className={`transformatorCard ${active ? "" : "transformatorCard-disabled"}`}>

                <BackgroundImage
                    src={require(`@images/transformator/collection/${color}/${image}`)}
                    className="transformatorCard__imgWrap"
                >
                <div className="transformatorCard__count">
                    <div className="transformatorCardCount__availabel">
                        {count}
                    </div>
                    {/* <div className="transformatorCardCount__all">
                        / 400
                    </div> */}
                </div>
                </BackgroundImage>
                <span className="transformatorCard__open">{active ? "Open →" : "Unavailable"}</span>

            </Link>
    )
}

export default NftCard;