const ABIAristippusNft = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getTokenDetail",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "tId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "owners",
                "type": "address"
            },
            {
                "internalType": "uint8",
                "name": "Guideline",
                "type": "uint8"
            },
            {
                "internalType": "uint8",
                "name": "Color",
                "type": "uint8"
            },
            {
                "internalType": "uint16",
                "name": "Number",
                "type": "uint16"
            },
            {
                "internalType": "uint256",
                "name": "act",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "getTokenDetailByIdx",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "tId",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "owners",
                "type": "address"
            },
            {
                "internalType": "uint8",
                "name": "Guideline",
                "type": "uint8"
            },
            {
                "internalType": "uint8",
                "name": "Color",
                "type": "uint8"
            },
            {
                "internalType": "uint16",
                "name": "Number",
                "type": "uint16"
            },
            {
                "internalType": "uint256",
                "name": "act",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "tokenByIndex",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

export default ABIAristippusNft;