import GOSHoldTokenCard from "@components/NFTokens/tokens/GOSHold/components/GOSHoldTokenCard";
import useGOSHold from "@components/NFTokens/tokens/GOSHold/hooks/useGOSHold";
import styles from "@components/NFTokens/tokens/GOSHold/styles/GOSHold.module.scss";

const GOSHoldToken = () => {

    const { GOSHoldToken, GOSHoldBonusToken, wallet, tokenName } = useGOSHold();

    return (
        <div>
            <h1 className={styles.GOSHold_header}>Your GOS <span className={styles.header_span}>Hold</span> Tokens</h1>
            {
                GOSHoldToken.length > 0 || GOSHoldBonusToken.length > 0 ?
                    <div className={styles.GOSHold_container}>
                        {GOSHoldToken.map((token) => <GOSHoldTokenCard key={token.tId} token={token} wallet={wallet} tokenName={tokenName} bonus={false} />)}
                        {GOSHoldBonusToken.map((token) => <GOSHoldTokenCard key={token.tId} token={token} wallet={wallet} tokenName={tokenName} isBonus={true} />)}
                    </div>
                    :
                    <div className={styles.GOSHold_message}>
                        <p className={styles.GOSHold_paragraph}>
                            No GOS Hold Tokens...
                        </p>
                    </div>

            }
        </div>
    );
}

export default GOSHoldToken;