export const GetTokenDetail = async (indexArray, contract, wallet) => {
    const promises = [];

    indexArray.map((index) => {
        promises.push(new Promise((resolve, reject) => {
            contract.methods.getTokenDetailByIdx('0x' + wallet.address, index).call().then((result) => {
                resolve(result);
            }).catch((e) => {
                reject(e);
            });
        }));
    });

    const results = await Promise.all(promises.map(p => p.catch(e => e)));
    const validResults = results.filter(result => !(result instanceof Error));

    return validResults;
}
