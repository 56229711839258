import React from "react";

const NotFound = () => {
    return (
        <div className="errorInformer">
            There are no entries in this section.
        </div>
    )
}

export default NotFound;