import React, { useContext } from "react";
import { coinsConfig } from "@config/CryptoConfig";
import { CoinContext } from "@providers/CoinContext";

const Me = () => {

    const coin = 'ME';

    const { balance } = useContext(CoinContext)
    const { title } = coinsConfig[coin];

    return (
        <div className="address-card__wrap address-card__wrap2">
            <div className={`address-card address-card__video address-card__${coin}`}>
                <div className="address-card__flex">
                    <div className="address-card__title">{title}</div>
                </div>
                <div className="address-card__flex">
                    <div className="address-card__label">Available</div>
                    <div className="address-card__balance">{balance[coin]}</div>
                </div>
            </div>
        </div>
    )

}

export default Me;