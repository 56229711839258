import { GetDataWithOffset } from "@components/NFTokens/utils/GetDataWithOffset";
import { sumArrays } from '@utils/Arrays';

export const GetCountByColor = (contract, wallet, balance) => {
    return new Promise((resolve, reject) => {
        GetDataWithOffset(contract.methods.balanceOfColor('0x' + wallet.address, 0), balance)
            .then(sumArrays)
            .then((result) => {
                return resolve(result);
            }).catch((e) => {
                return reject(e);
            });
    })
    
}