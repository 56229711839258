import React from "react";
import { Link, useParams } from "react-router-dom";
import { NFTokens } from "@config/CryptoConfig";

const TopColorSwitch = () => {

    let { color = 'yellow' } = useParams();

    return (
        <div className="ma__top">
            <div className="maTop__left">
                <div className="maMenu">
                    <Link to={`/${NFTokens.NFTMetaCodex2023.baseUrl}/yellow`} className={`maMenu__link ${color == 'yellow' ? 'maMenu__link-active' : ''}`}>
                        <span className="maMenuLink__span">YELLOW</span>
                        <img src={require("@images/aristippus_nft/menu-yellow.png")} className="maMenuLink__img" alt="" />
                    </Link>
                    <Link to={`/${NFTokens.NFTMetaCodex2023.baseUrl}/red`} className={`maMenu__link ${color == 'red' ? 'maMenu__link-active' : ''}`}>
                        <span className="maMenuLink__span">RED</span>
                        <img src={require("@images/aristippus_nft/menu-red.png")} className="maMenuLink__img" alt="" />
                    </Link>
                    <Link to={`/${NFTokens.NFTMetaCodex2023.baseUrl}/green`} className={`maMenu__link ${color == 'green' ? 'maMenu__link-active' : ''}`}>
                        <span className="maMenuLink__span">GREEN</span>
                        <img src={require("@images/aristippus_nft/menu-green.png")} className="maMenuLink__img" alt="" />
                    </Link>
                    <Link to={`/${NFTokens.NFTMetaCodex2023.baseUrl}/blue`} className={`maMenu__link ${color == 'blue' ? 'maMenu__link-active' : ''}`}>
                        <span className="maMenuLink__span">BLUE</span>
                        <img src={require("@images/aristippus_nft/menu-blue.png")} className="maMenuLink__img" alt="" />
                    </Link>
                    <Link to={`/${NFTokens.NFTMetaCodex2023.baseUrl}/purple`} className={`maMenu__link ${color == 'purple' ? 'maMenu__link-active' : ''}`}>
                        <span className="maMenuLink__span">PURPLE</span>
                        <img src={require("@images/aristippus_nft/menu-purple.png")} className="maMenuLink__img" alt="" />
                    </Link>
                </div>
            </div>
            <div className="maTop__center">
                <Link to={`/${NFTokens.NFTMetaCodex2023.baseUrl}/yellow`}>
                    <div className="maLogo">
                        <div className="maLogo__imgWrap">
                            <div className="maLogo__imgContent">
                                <img src={require("@images/aristippus_nft/logo.png")} className="maLogo__imgContent-img" alt="" />
                            </div>
                        </div>
                        <div className="maLogo__textWrap">
                            <div className="maLogoTextWrap__title">META ARISTIPPUS</div>
                            <div className="maLogoTextWrap__subTitle">exclusive nft collection</div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="maTop__right">
                <div className="maMenu2">
                    {/* <a href={settings.productLink} className="maMenu2__link"><Lang id='META_A_REACT_L85'>Product</Lang></a> */}
                    {/* <a href={settings.aboutLink1} className="maMenu2__link"><Lang id='META_A_REACT_L12'>About Collection</Lang></a> */}
                </div>
            </div>
        </div>
    )

}

export default TopColorSwitch;