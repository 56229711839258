import React from "react";
import { Link, useParams } from "react-router-dom";
import { NFTokens } from "@config/CryptoConfig";

const BottomColorSwitch = () => {

    let { color = 'yellow' } = useParams();

    return (
        <div className="ma__bottomColor">
            <Link to={`/${NFTokens.NFTAlphabet.baseUrl}/yellow`} className={`maBottomColor__elem ${color == 'yellow' ? 'maBottomColor__elem-active' : ''}`}>
                <div className="maBottomColor__cyrcle maBottomColor__cyrcle-yellow"></div>
                <div className="maBottomColor__text">YELLOW</div>
            </Link>
            <Link to={`/${NFTokens.NFTAlphabet.baseUrl}/red`} className={`maBottomColor__elem ${color == 'red' ? 'maBottomColor__elem-active' : ''}`}>
                <div className="maBottomColor__cyrcle maBottomColor__cyrcle-red"></div>
                <div className="maBottomColor__text">RED</div>
            </Link>
            <Link to={`/${NFTokens.NFTAlphabet.baseUrl}/green`} className={`maBottomColor__elem ${color == 'green' ? 'maBottomColor__elem-active' : ''}`}>
                <div className="maBottomColor__cyrcle maBottomColor__cyrcle-green"></div>
                <div className="maBottomColor__text">GREEN</div>
            </Link>
            <Link to={`/${NFTokens.NFTAlphabet.baseUrl}/purple`} className={`maBottomColor__elem ${color == 'purple' ? 'maBottomColor__elem-active' : ''}`}>
                <div className="maBottomColor__cyrcle maBottomColor__cyrcle-purple"></div>
                <div className="maBottomColor__text">PURPLE</div>
            </Link>
            <Link to={`/${NFTokens.NFTAlphabet.baseUrl}/blue`} className={`maBottomColor__elem ${color == 'blue' ? 'maBottomColor__elem-active' : ''}`}>
                <div className="maBottomColor__cyrcle maBottomColor__cyrcle-blue"></div>
                <div className="maBottomColor__text">BLUE</div>
            </Link>
        </div>
    )
}

export default BottomColorSwitch;