import Web3 from "web3";
import { rpcLink } from "@config/CryptoConfig";
import { NFTokens } from "@config/CryptoConfig";

import ActionWrap from "@components/NFTokens/actions/ActionWrap";

const StartFarm = async (tokenName, tokenId, walletData) => {

    const contractAddress = NFTokens[tokenName]['contractAddress'];
    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(NFTokens[tokenName]['minABIActions'], contractAddress);

    return new Promise(async (resolve, reject) => {

        try {
            const encoded = contract.methods.startFarming(tokenId).encodeABI();

            ActionWrap(contractAddress, walletData, encoded)
                .then((data) => {
                    //console.log(data);
                    return resolve(data);
                })
                .catch((e) => {
                    //console.log(e);
                    return reject(e);
                })

        } catch (e) {
            return reject(e);
        }
    });

}

export default StartFarm;