export const unfarmPrice = 1;
export const farmPrice = 3;
export const sendPrice = 1;
export const sendAktPrice = 3;

export const titleData = {
    "1": {
        "title": "BREADTH OF KNOWLEDGE",
        "descr": "Be able to accept different points of view and ideas."
    },
    "2": {
        "title": "RESPECT",
        "descr": "Be caring, kind and attentive to other people."
    },
    "3": {
        "title": "COLLABORATION",
        "descr": "Be able to cooperate with others."
    },
    "4": {
        "title": "POLITENESS",
        "descr": "Be polite and respect personal dignity of others."
    },
    "5": {
        "title": "FORGIVENESS",
        "descr": "Be able to forgive and understand people in situations where other people make mistakes."
    },
    "6": {
        "title": "SENSE OF HUMOR",
        "descr": "Be able to arouse fun and joy in other people."
    },
    "7": {
        "title": "INTEGRITY",
        "descr": "Be credible and follow high moral and ethical principles. "
    },
    "8": {
        "title": "OPEN-MINDEDNESS",
        "descr": "Be open and sincere when communicating with other people."
    },
    "9": {
        "title": "SOCIAL EQUALITY",
        "descr": "Be on equal footing with other people and avoid emphasizing differences in social status."
    },
    "10": {
        "title": "ADAPTIVITY",
        "descr": "Be flexible and able to change in response to new life circumstances."
    },
    "11": {
        "title": "INDEPENDENCE",
        "descr": "Be independent and free in actions."
    },
    "12": {
        "title": "CREATIVITY",
        "descr": "Be able to create new ideas and effectively fulfill them in your life."
    },
    "13": {
        "title": "PERSONAL DEVELOPMENT",
        "descr": "Be capable of achieving personal growth, learning and development."
    },
    "14": {
        "title": "EXPERIMENTALISM",
        "descr": "Be ready to apply a trial-and-error method to problem solving."
    },
    "15": {
        "title": "PERSISTENCE",
        "descr": "Be capable of persistently pursuing the goals you set."
    },
    "16": {
        "title": "DILIGENCE ",
        "descr": "Be able to work long and hard to achieve results."
    },
    "17": {
        "title": "DECISIVENESS",
        "descr": "Be capable to take responsibility in a difficult life situation with no hesitation."
    },
    "18": {
        "title": "AWARENESS",
        "descr": "Be able to know and understand your environment and those interested around you."
    },
    "19": {
        "title": "CONSCIENTIOUSNESS",
        "descr": "Be able to follow instructions and rules when circumstances require it."
    },
    "20": {
        "title": "CAUTIOUSNESS",
        "descr": "Be moderately careful and try to avoid unnecessary risks."
    },
    "21": {
        "title": "THRIFT",
        "descr": "Be economical and cautious in spending."
    },
    "22": {
        "title": "LOYALTY",
        "descr": "Adhere to existing ceremonies and respect the traditions of your group."
    },
    "23": {
        "title": "CONSISTENCY",
        "descr": "Be able to think rationally and only in terms of facts and figures."
    },
    "24": {
        "title": "NEATNESS",
        "descr": "Be neat, tidy and well-organized."
    }
}