import React from "react";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { ethers } from "ethers";
import FeatherIcon from 'feather-icons-react';
import DefaultWrapper from "@components/wrappers/DefaultWrapper";
import Navbar from "@components/navbars/Navbar";
import { coinsConfig } from "@config/CryptoConfig";

const TransactionsPage = () => {

    const { coin } = useParams();
    const { title } = coinsConfig[coin];
    // const route = useRoutes();
    // const { coin } = route.params;
    const transactions = useSelector(state => state.transactionReducer.transactions);

    return (

        <DefaultWrapper>
            <Navbar />
            <div className="mainWrapper">
                <Link to="/" className="back__btn">← back</Link>
                <div className="mainWrapper__content">
                    <div className="title">List of recent transactions: {title}</div>
                    <div className="transactions">
                        {transactions[coin] && Object.keys(transactions[coin]).length > 0 ?
                            <>
                                {transactions[coin].map((trans, index) => {
                                    let amount = 0;
                                    if (ethers.BigNumber.isBigNumber(trans.amount)) {
                                        amount = trans.amount.toHexString();
                                    } else {
                                        amount = trans.amount.hex;
                                    }

                                    return (
                                        <div key={index} className="transaction">
                                            <div className="transaction__elem">{trans.from}</div>
                                            <div className="transaction__elem"><FeatherIcon icon="arrow-right" size="20" strokeWidth="1" /></div>
                                            <div className="transaction__elem">{trans.receiver}</div>
                                            {amount ?
                                                <div className="transaction__elem">-{parseFloat(ethers.utils.formatUnits(amount, 8)).toFixed(8)}</div>
                                            :
                                                <></>
                                            }
                                        </div>
                                    )
                                })}
                            </>
                            :
                            <div className="transaction__empty">Can't find any transactions</div>
                        }
                    </div>
                </div>
            </div>
        </DefaultWrapper>


    )

}

export default TransactionsPage;