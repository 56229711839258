import React from 'react';
import styles from '@components/NFTokens/tokens/Volume/styles/TopHeader.module.scss';


const TopHeader = () => (
    <div className={styles.header_container}>
        <img className={styles.header_image} src={'/images/nft_volume/page_header.png'} />
    </div>
);

export default TopHeader;