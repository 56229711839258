import React, { useContext } from "react";
import { WisdomContext } from "@components/NFTokens/contexts/WisdomContext";
import { Link, useParams } from "react-router-dom";
import { BackgroundImage } from 'react-image-and-background-image-fade';
import { NFTokens } from "@config/CryptoConfig";
import '@components/NFTokens/tokens/Wisdom/styles/aristippus_wisdom.scss';

import Loading from "@components/NFTokens/components/Loading";
import NftCards from '@components/NFTokens/tokens/Wisdom/components/NftCards';
import TopColorSwitch from "@components/NFTokens/tokens/Wisdom/components/TopColorSwitch";
import BottomColorSwitch from "@components/NFTokens/tokens/Wisdom/components/BottomColorSwitch";

const WisdomToken = () => {

    const { color = 'green' } = useParams();
    const { loaded, nftColorData } = useContext(WisdomContext);

    if (!loaded) return <Loading />;

    return (
        <div className="wisdom_container">
            <TopColorSwitch />
            <div className="maMain__textWrap">
                <div className="maMain__text">
                    <b>NFT MA WISDOM</b> - original thematic NFT collection dedicated to the 12th anniversary of the business. The NFT digital collection reflects the wisdom and crucial data related to human behavior on the path to success.
                </div>
                <div className="maMain__text">
                    The <b>MA WISDOM</b> series is based on years of research by the Global Success Management Institute, the analysis of the achievements and failures of a large number of people around the world.
                </div>
            </div>

            <div className="maMain__menuWrap">
                <Link to={`/${NFTokens.NFTMetaWisdom.baseUrl}/green`} className={`maMain__menuLink maMain__menuLink-green ${color == 'green' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_wisdom/menu/MAW-G-Aristippus.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />GREEN set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-red">{nftColorData[0] ? nftColorData[0] : 0}</span>
                    </div>
                </Link>
                <Link to={`/${NFTokens.NFTMetaWisdom.baseUrl}/yellow`} className={`maMain__menuLink maMain__menuLink-yellow ${color == 'yellow' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_wisdom/menu/MAW-Y-Aristippus.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />YELLOW set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-red">{nftColorData[1] ? nftColorData[1] : 0}</span>
                    </div>
                </Link>
                <Link to={`/${NFTokens.NFTMetaWisdom.baseUrl}/red`} className={`maMain__menuLink maMain__menuLink-red ${color == 'red' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_wisdom/menu/MAW-R-Aristippus.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />RED set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-red">{nftColorData[2] ? nftColorData[2] : 0}</span>
                    </div>
                </Link>

                <Link to={`/${NFTokens.NFTMetaWisdom.baseUrl}/blue`} className={`maMain__menuLink maMain__menuLink-blue ${color == 'blue' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_wisdom/menu/MAW-B-Aristippus.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />BLUE set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-red">{nftColorData[3] ? nftColorData[3] : 0}</span>
                    </div>
                </Link>
                <Link to={`/${NFTokens.NFTMetaWisdom.baseUrl}/purple`} className={`maMain__menuLink maMain__menuLink-purple ${color == 'purple' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_wisdom/menu/MAW-P-Aristippus.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />PURPLE set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-red">{nftColorData[4] ? nftColorData[4] : 0}</span>
                    </div>
                </Link>
            </div>

            <div className="collectionWrap">
                {/* <div className="collectionWrap__row">
                    <div className="collectionWrapRow__elem">
                        Текущая стоимость коллекции:
                    </div>
                    <div className="collectionWrapRow__elem collectionWrapRow__elem-valueWrap">
                        <div className="collectionWrapRowElem__value">
                            100 <span className="gos__logo">GOS</span>
                        </div>
                        <div className="collectionWrapRowElem__value-line"></div>
                        <div className="collectionWrapRowElem__value">
                            100 <span className="bonus__logo">BONUS</span>
                        </div>
                    </div>
                </div> */}
                <div className="maCollection__content1">
                    <NftCards />

                    {/* <CollectAll /> */}
                </div>
            </div>
            <BottomColorSwitch />
        </div>
    )

}

export default WisdomToken;