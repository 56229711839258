import Web3 from "web3";
import { rpcLink, converterConfig } from "@config/CryptoConfig";
import NumberFormat from "@utils/NumberFormat";
import { FarmedFormat } from "@utils/NumberFormat";


const GetGicoAmount = (walletData, amount) => {

    const { minABI, contractAddress, transferFee } = converterConfig['gicoDaoConvert'];

    // console.log(contractAddress);

    amount = amount * 100000000;

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(minABI, contractAddress);

    return new Promise(async(resolve, reject) => {
        try{
            await contract.methods.getAmountC3(amount, walletData.address).call().then((result) => {
                // console.log('Gico', result['my']);
                
                if(result['my']){
                    result['my'] = FarmedFormat(result['my'], 8);
                }
                if(result['all']) {
                    result['all'] = FarmedFormat(result['my'], 8);
                }
            

                return resolve(result);
            }).catch((e) => {
                return reject(e);
            })
        } catch (e) {
            return reject(e);
        }
    })
}

export default GetGicoAmount;