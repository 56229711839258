import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from "react-redux";
import { CoinContext } from "@providers/CoinContext";

import Level from '@components/wallet/coins/gico_to_dao/Levels';
import GetGicoAmount from '@components/wallet/actions/gico_to_dao/GetGicoAmount';
import GetDaoAmount from '@components/wallet/actions/gico_to_dao/GetDaoAmount';

import ConvertGicoToDao from '@components/wallet/actions/gico_to_dao/ConvertGicoToDao';

import Swal from "sweetalert2"
import { countDecimals, countNumberLength } from "@utils/Number";

import '@components/wallet/address_card.scss';
import '@components/wallet/index.scss';

const GicoDaoConvert = () => {

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);
    const address = '0x' + wallet.address;

    const { balance, updateBalanceAllCoins } = useContext(CoinContext);

    const [gicoRate, setGicoRate] = useState(0);
    const [daoRate, setDaoRate] = useState(0);

    const [gicoAmount, setGicoAmount] = useState('');
    const [daoAmount, setDaoAmount] = useState(0.0010);

    const [isError, setIsError] = useState(false);
    // console.log('my balance', balance['GICO_PROGRESS']);

    useEffect(() => {
        GetDaoAmount(wallet, 1).then((data) => {
                setDaoRate(data);
            })
            .catch((e) => {
                console.log(e);
            })

        GetGicoAmount(wallet, 1)
            .then((data) => {
                setGicoRate(data);
                setGicoAmount(data['my'] / 1000);
            })
            .catch((e) => {
                console.log(e);
            })
    }, [balance]);

    const handleGicoInputChange = (value) => {

        const filteredValue = value.replace(/[^0-9.]/g, "");
        if(countDecimals(filteredValue) > 2) return;
        if(countNumberLength(filteredValue) > 8) return;

        setGicoAmount(filteredValue);

        if (parseFloat(filteredValue) >= 0.1 && !isNaN(parseFloat(filteredValue))) {
        //   setGicoAmount(parseFloat(filteredValue));
          setDaoAmount((filteredValue * daoRate['my']).toFixed(8));
          setIsError(false);
        } else {
          setDaoAmount('');
          setIsError(true);
        }
    };
      
    const handleDaoAmountChange = (value) => {

        const filteredValue = value.replace(/[^0-9.]/g, "");
        if(countDecimals(filteredValue) > 8) return;
        if(countNumberLength(filteredValue) > 8) return;

        setDaoAmount(filteredValue);

        if(parseFloat(filteredValue) >= 0.0001 && !isNaN(parseFloat(filteredValue))) {
            // setDaoAmount(parseFloat(filteredValue));
            setGicoAmount((filteredValue * gicoRate['my']).toFixed(8));
            setIsError(false);
        } else {
            setGicoAmount('');
            setIsError(true);
        }
    };

    const convert = () => {

        // console.log(parseFloat(gicoAmount), parseFloat(balance['GICO_PROGRESS']), parseFloat(gicoAmount) > parseFloat(balance['GICO_PROGRESS']));

        if (parseFloat(gicoAmount) > parseFloat(balance['GICO_PROGRESS'])) {
            Swal.fire({
                icon: 'error',
                title: 'Exchange error',
                text: 'You do not have enough GICO COIN to perform this exchange operation',
            });
            return;
        }
        
        Swal.fire({
            icon: 'warning',
            title: 'GICO to DAO convertation',
            html: `Do you agree to confirm the exchange operation for GICO COIN? <br /><br /> <b>${gicoAmount} GICO -> ${daoAmount} DAO</b>`,
            showCancelButton: true,
            confirmButtonText: `Exchange`,
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if(result.isConfirmed) {
                ConvertGicoToDao(wallet, address, gicoAmount)
                    .then((data) => {
                        updateBalanceAllCoins();
                        setGicoAmount(gicoRate['my'] / 1000);
                        setDaoAmount(0.0010);
                    })
                    .catch((e) => {
                        console.log(e);
                        console.log(1);
                    })
                }
        });
    }

    return(
        <>  
            <br/>
            <div className='convertDao'>

                <h2 className='exchange__title'>Convert GICO Coin to GR DAO Coin</h2>
                <br/>
                <Level />
                <div className="daoConvert">
                    <div className="daoConvertElem__row">
                        <div className='gico-balance'>GICO PROGRESS: {balance['GICO_PROGRESS']}</div>
                    </div>
                    <br/>
                    <div className="daoConvert__content">
                        <div className="daoConvert__wrap">
                            <div className="daoConvert__elem daoConvert__elem-1">
                                <div className="daoConvertElem__row">
                                    <div className="daoConvertElemRow__title">
                                        GICO COIN
                                    </div>

                                    <input
                                        type= "text"
                                        value={gicoAmount}
                                        onChange={e => handleGicoInputChange(e.target.value)} 
                                        className="daoConvertElemRow__amount" 
                                    />
                                    {isError && <p className='errorMsg'>Minimum - 0.1</p>}
                                </div>
                            </div>
                            <div className='daoConvert__iconWrap'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 51"><path d="M56.4 18.78c-.32.44-.58.93-.96 1.31a2338.8 2338.8 0 0 1-12.21 12.19c-.33.32-.78.62-1.22.71-1.2.25-2.12-.62-2.13-1.96l-.01-4.64c0-.37-.3-.68-.68-.68-2.8 0-13.53-.02-19.08.02-1.58.01-2.37-.93-2.28-2.3.03-.47.03-.94.02-1.4a.35.35 0 0 0-.59-.24L9.9 29.15a.34.34 0 0 0 0 .48l7.35 7.34c.22.22.59.06.59-.24v-1.45c0-1.57.68-2.24 2.27-2.25h13.85c.33 0 .68.02.99.12a1.8 1.8 0 0 1 1.24 1.9c-.1.87-.77 1.54-1.64 1.63-.2.02-.4.01-.6.01H22.02a.47.47 0 0 0-.47.46l-.02 4.75c0 .74-.19 1.39-.86 1.81-.68.43-1.46.4-2.1-.09a3.4 3.4 0 0 1-.44-.41L5.82 30.92c-1.08-1.08-1.08-2.05-.02-3.11 4.12-4.11 8.23-8.23 12.36-12.32.33-.33.77-.63 1.21-.73 1.19-.27 2.14.61 2.16 1.93.02 1.23.01 3.62.01 4.74 0 .33.27.6.6.6h19.32c1.39 0 2.09.71 2.09 2.1v1.61c0 .31.37.46.59.24l7.41-7.4a.34.34 0 0 0 0-.48l-7.54-7.53c-.16-.16-.44-.05-.44.18l-.03 2.12a1.8 1.8 0 0 1-1.64 1.78 2.7 2.7 0 0 1-.4.03H27.25a3 3 0 0 1-.84-.12 1.8 1.8 0 0 1-1.21-1.87c.09-.87.76-1.55 1.63-1.65.2-.02.4-.02.6-.02h11.98c.26 0 .47-.21.47-.47V5.78c0-.88.3-1.59 1.14-1.94.84-.35 1.55-.07 2.18.56 4.16 4.16 8.33 8.31 12.48 12.48.3.3.49.71.73 1.06l-.01.84z"/></svg>
                            </div>
                            <div className="daoConvert__elem daoConvert__elem-2">
                                <div className="daoConvertElem__row">
                                    <div className="daoConvertElemRow__title">
                                        GLOBAL RENAISSANCE DAO
                                    </div>
                                    <input
                                        type= "text"
                                        value={daoAmount}
                                        onChange={e => handleDaoAmountChange(e.target.value)} 
                                        className="daoConvertElemRow__amount" 
                                    />
                                    {isError && <p className='errorMsg'>Minimum - 0.0001</p>}
                                </div>
                            </div>
                        </div>
                        <div className="daoConvert__btnWrap">
                            <button type="button" disabled={isError ? true : false} onClick={() => isError ? undefined : convert()} className="convert__btn">Exchange</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default GicoDaoConvert;
