const defaultState = {
    balance: null,
    farmingFee: null,
    transferFee: null,
    nftColorData: [],
    nftColorGuidelineData: [],
    nftCollectionData: [],
};

export const nftVolumeReducer = (state = defaultState, action) => {
    switch (action.type) {

        case 'SET_NFTV_BALANCE':
            return { ...state, balance: action.payload }

        case 'SET_NFTV_FARMING_FEE':
            return { ...state, farmingFee: action.payload }

        case 'SET_NFTV_TRANSFER_FEE':
            return { ...state, transferFee: action.payload }

        case 'SET_NFTV_COLOR_DATA':
            return { ...state, nftColorData: action.payload }

        case 'SET_NFTV_COLOR_GUIDELINE_DATA':
            return { ...state, nftColorGuidelineData: action.payload }

        case 'SET_NFTV_COLLECTION_DATA':
            return { ...state, nftCollectionData: action.payload }

        case 'WNFNFTV_PURGE':
            return defaultState;

        default:
            return state;
    }
}