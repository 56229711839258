import React from "react";
import NFTContainer from "@components/NFTokens/components/NFTContainer";
import TransformatorProvider from "@components/NFTokens/contexts/TransformatorContext";
import TransformatorTokenCollection from "@components/NFTokens/tokens/Transformator/TransformatorTokenCollection";

const TransformatorCollectionPage = () => (
    <NFTContainer>
        <TransformatorProvider>
            <TransformatorTokenCollection />
        </TransformatorProvider>
    </NFTContainer>
);

export default TransformatorCollectionPage;