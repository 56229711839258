
const NFTVolumeRainbowDetailPage = () => {

    return (
        <div>
            <h1>
                RAINBOW DETAILS
            </h1>
        </div>
    );
}

export default NFTVolumeRainbowDetailPage;