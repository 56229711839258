import React from "react";

import { passwordCheck } from "@utils/PasswordCheck";
import { FeeDialog } from "@utils/FeeDialog";
import { AmountInput } from "@utils/AmountInput";
import { IntermediatePreloader } from "@utils/IntermediatePreloader";
import { GetGasAmount } from "@components/wallet/actions/utils/GetGasAmount";
import Swal from "sweetalert2"

import Web3 from "web3";
import { ethers } from "ethers";
import { rpcLink } from "@config/CryptoConfig";
import { expansionConfig, expansionMinAbi } from "@config/CryptoConfig";
// import minABI from "@config/ABI/ExpansionABI";

const Stake = async (coin, index, walletData, amount) => {

    const contractAddress = expansionConfig[coin][index]['address'];
 
    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(expansionMinAbi, contractAddress);

    
    return new Promise(async (resolve, reject) => {
        // await contract.methods.balanceOf('0x' + walletData.address).call().then((result) => {
            
            FeeDialog(amount).then((continueBtn) => {
                if(!continueBtn) return reject(10);

                passwordCheck().then((password) => {
                    if(!password) return reject(4);

                    IntermediatePreloader().then(() => {
                        try{
                            const wallet = web3.eth.accounts.decrypt(JSON.stringify(walletData), password, true);
                            if(!wallet){
                                return reject(5);
                            }
        
                            web3.eth.getGasPrice(function(e, r) {
        
                                const deploy = async () => {
        
                                    var count = await web3.eth.getTransactionCount('0x' + walletData.address, 'latest').catch((e) => {
                                        //console.log(e);
                                        return reject(6);
                                    });
        
                                    try {
                                        const encoded = contract.methods.stake(ethers.utils.parseUnits(amount, 8)).encodeABI();
                                        const gasAmount = await GetGasAmount('0x' + walletData.address, contractAddress, encoded);
                                
                                        var rawTransaction = {
                                            to: contractAddress, 
                                            from: '0x' + walletData.address,
                                            data: encoded,
                                            gas: gasAmount,
                                            gasPrice: r,
                                            chainId: process.env.REACT_APP_RPC_CHAIN,
                                            value: 0,
                                            nonce: "0x" + count.toString(16),
                                        }   
                                        
                                        var tx = await web3.eth.accounts.signTransaction(rawTransaction, wallet.privateKey).catch((e) => {
                                            //console.log(e);
                                            return reject(7);
                                        });
        
                                        var receipt = await web3.eth.sendSignedTransaction(tx.rawTransaction).catch((e) => {
                                            //console.log(e);
                                            return reject(8);
                                        });
        
                                        if(receipt && receipt.status){
                                            Swal.fire({
                                                icon: 'success',
                                                timer: 3000,
                                                showCloseButton: false,
                                                showConfirmButton: false,
                                                showDenyButton: false
                                            })
                                        
                                            // receipt.amount = ethers.utils.parseUnits(amount, 8);
                                            
                                            return resolve(receipt);
                                        } else {
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Error',
                                                text: 'Something went wrong. Try again later.',
                                                timer: 3000,
                                                showCloseButton: false,
                                                showConfirmButton: false,
                                                showDenyButton: false
                                            })
        
                                            return reject(9);
                                        }
        
                                    } catch(e) {
                                        //console.log(e);
                                        if(e == 'Error: Returned error: execution reverted: advanced NFT is too low'){
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Error',
                                                text: 'You do not have the required amount of NFT ADVANCED for this level.',
                                                timer: 3000,
                                                showCloseButton: false,
                                                showConfirmButton: false,
                                                showDenyButton: false
                                            })
        
                                        }
        
                                        return reject(61);
                                    }
                                    
                                }
        
                                deploy();
                            })
        
                        } catch(e) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'Something went wrong. Try again later.',
                                timer: 3000,
                                showCloseButton: false,
                                showConfirmButton: false,
                                showDenyButton: false
                            })
        
                            //console.log(e);
        
                            return reject(6);
                        }
                    }).catch((e) => {
                        //console.log(e);
                    })
                })
            })
        // }).catch((e) => {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Error',
        //         text: 'Operation failed, please try again in a while',
        //         timer: 3000,
        //         showCloseButton: false,
        //         showConfirmButton: false,
        //         showDenyButton: false
        //     })

        //     //console.log(e);
        // });
    })

    

}

export default Stake;