import NFTContainer from "@components/NFTokens/components/NFTContainer";
import AristippusTokenCollection from "@components/NFTokens/tokens/Aristippus/AristippusTokenCollection";
import AristippusProvider from "@components/NFTokens/contexts/AristippusContext";
import React from "react";

const AristippusCollectionPage = () => (
    <NFTContainer>
        <AristippusProvider>
            <AristippusTokenCollection />
        </AristippusProvider>
    </NFTContainer>
);

export default AristippusCollectionPage;