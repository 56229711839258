import React from "react";
import NFTContainer from "@components/NFTokens/components/NFTContainer";
import WisdomToken from "@components/NFTokens/tokens/Wisdom/WisdomToken";
import WisdomProvider from "@components/NFTokens/contexts/WisdomContext";

const WisdomPage = () => (
    <NFTContainer>
        <WisdomProvider>
            <WisdomToken />
        </WisdomProvider>
    </NFTContainer>
);

export default WisdomPage;