import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { nftCollections, nftCollectionsSlugs } from "@config/CryptoConfig";
import { NftCollectionContext } from "@providers/NftCollectionContext";
import DefaultDetail from "@components/wallet/nft_collection/detail/DefaultDetail";

const DetailCollection = () => {

    const { slug } = useParams();
    const address = nftCollectionsSlugs[slug];
    const { detailComponent } = nftCollections[address];
    const { getTokenData } = useContext(NftCollectionContext);

    useEffect(() => {
        getTokenData(slug);
    }, []);


    return (
        <>
            {detailComponent ? 
                <>{detailComponent}</>
            :
                <DefaultDetail address={address} />
            }
        </>
    )

}

export default DetailCollection;
