
const NFTVolumeCollectionPage = () => {

    return (
        <div>
            <h1>
                NFTVolumeCollectionPage
            </h1>
        </div>
    );
}

export default NFTVolumeCollectionPage;