import Web3 from "web3";
import { ethers } from "ethers";
import { rpcLink } from "@config/CryptoConfig";
import { expansionConfig } from "@config/CryptoConfig";
import minABI from "@config/ABI/ExpansionABI";

const GetBalanceExpansion = (coin, index, walletData) => {

    const contractAddress = expansionConfig[coin][index]['address'];

    // const { minABI, contractAddress, transferFee } = expansionConfig[ccy];

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(minABI, contractAddress);

    // //console.log(contractAddress, walletData.address);

    return new Promise(async(resolve, reject) => {
        try{
            await contract.methods.balanceOf('0x' + walletData.address).call().then((result) => {
                //console.log(result.hold, result.royalty);
                return resolve([result.hold / 100000000, result.royalty / 100000000]);
            }).catch((e) => {
                return reject(e);
            })
        } catch (e) {
            return reject(e);
        }
    })
}

export default GetBalanceExpansion;