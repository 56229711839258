import NFTContainer from "@components/NFTokens/components/NFTContainer";
import TransformatorToken from "@components/NFTokens/tokens/Transformator/TransformatorToken";
import TransformatorProvider from "@components/NFTokens/contexts/TransformatorContext";
import React from "react";


const TransformatorPage = () => (
    <NFTContainer>
        <TransformatorProvider>
            <TransformatorToken />
        </TransformatorProvider>
    </NFTContainer>
);

export default TransformatorPage;