import { NFTokens } from "@config/CryptoConfig";
import CoinContext from "@components/NFTokens/contexts/CoinContext";
import React from "react";
import { Route } from "react-router-dom";

export const NFTRoutes = Object.keys(NFTokens).map((NFTkey) => (
    !NFTokens[NFTkey].hidden && <React.Fragment key={NFTkey}>
        <Route exact path={`${NFTokens[NFTkey].baseUrl}/:color?`} element={
            <CoinContext>
                {NFTokens[NFTkey].mainPage}
            </CoinContext>
        } />

        {
            NFTokens[NFTkey].collectionPage && <Route exact path={`${NFTokens[NFTkey].baseUrl}/:color/collection/:guidline`} element={
                <CoinContext>
                    {NFTokens[NFTkey].collectionPage}
                </CoinContext>
            } />
        }

        {
            NFTokens[NFTkey].detailPage && <Route exact path={`${NFTokens[NFTkey].baseUrl}/:color/collection/:guidline/:index`} element={
                <CoinContext>
                    {NFTokens[NFTkey].detailPage}
                </CoinContext>
            } />
        }
    </React.Fragment>
));

export default NFTRoutes;