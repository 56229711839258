import MetaSentryToken from "@components/NFTokens/tokens/MetaSentry/MetaSentryToken";
import NFTContainer from "@components/NFTokens/components/NFTContainer";


const MetaSentryPage = () => (
    <NFTContainer>
        <MetaSentryToken />
    </NFTContainer>
);

export default MetaSentryPage;