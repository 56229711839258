
const VolumeRainbowToken = () => {

    return (
        <div>
            VOLUME RAINBOW
        </div>
    );
}

export default VolumeRainbowToken;