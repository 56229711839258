import React, { useState, useContext } from "react";
import { TransformatorContext } from "@components/NFTokens/contexts/TransformatorContext";
import Swal from "sweetalert2";
import { SpinnerWhite } from '@components/spinner';

import CollectGico from "@components/NFTokens/actions/CollectGico";

const Collect = ({ data }) => {

    const { wallet, getTokenDetailInformation } = useContext(TransformatorContext);
    const [loading, setLoading] = useState(false);

    const collect = (tokenId) => {

        setLoading(true);

        CollectGico('NFTTransformator', tokenId, wallet)
            .then((data) => {
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Your request has been successfully submitted'
                })
                getTokenDetailInformation();
                setLoading(false);
            })
            .catch((e) => {
                if (e) {
                    Swal.fire('Error', `Request failed: ${e}`, 'error');
                }
                // Swal.fire('Error', `Request failed: ${e}`, 'error');
                setLoading(false);
            })
    }

    return (
        <>
            {!loading ?
                <button type="button" onClick={() => data.coin > 0 ? collect(data.tId) : undefined} className={`cardFullInfoContent__btn cardFullInfoContent__btn-collect ${data.coin > 0 ? "" : "cardFullInfoContent__btn-disabled"}`}>
                    <span className="cardFullInfoContent__btn-cyrcle"></span>
                    Collect
                </button>
                :
                <button type="button" onClick={() => data.coin > 0 ? collect(data.tId) : undefined} className={`cardFullInfoContent__btn cardFullInfoContent__btn-collect ${data.coin > 0 ? "" : "cardFullInfoContent__btn-disabled"}`}>
                    <SpinnerWhite />
                </button>
            }
        </>
    )
}

export default Collect;