import React from "react"
import NftCard from "@components/NFTokens/tokens/Codex2023/components/NftCard";
import { useParams } from "react-router-dom";

const NftCards = () => {

    const { color = 'yellow' } = useParams();

    return (
        <div className={'ma__collection ma__collection-' + color} >
            <div className="maCollection__content">
                {[...Array(24)].map((x, i) =>
                    <NftCard key={i} guidline={i} />
                )}
            </div>
        </div>
    );
}

export default NftCards;