import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from 'feather-icons-react';
import './navbar.scss';
import Swal from "sweetalert2";
import { useNavigate, Link } from 'react-router-dom';

const Navbar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const wallets = useSelector(state => state.walletReducer.wallets);

    const logout = () => {
        Swal.fire({
            icon: 'warning',
            title: 'Log out',
            html: 'This will <b>disconnect</b> the wallet from this app. You will be able to restore your wallet using <b>Encrypted Private Key</b>.',
            showConfirmButton: true,
            confirmButtonText: 'Disconnect',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#7066e0',
            confirmButtonColor: '#6e7881',
        }).then((result) => {
            if(result.isConfirmed){
                dispatch({type: 'PURGE'})
                dispatch({type: 'WNFT_PURGE'})
                dispatch({type: 'ANFT_PURGE'})
                navigate("/");
                // <Link to="/" className="back__btn">← back</Link>
            }
        })
    }

    return(
        <div className="navbar">
            <div className="navbar__logo">
                <Link to={"/"} className="navbar__logo-link">
                    <div className="navbar__logo-text">GR Wallet</div>
                </Link>
            </div>
            {wallets.length > 0 &&
                <div className="navbar__item" onClick={() => logout()}>
                    <div className="navbar__title">Log out</div>
                    <FeatherIcon icon="log-out" size="25" strokeWidth="2" className="navbar__itemIcon" />
                </div>
            }
        </div>
    )
}

export default Navbar;