export const GetDataWithOffset = async (fn, balance) => {

    let count = 0;
    const promises = [];

    if(balance <= 0) return [];

    do{
        promises.push(new Promise((resolve, reject) => {
            const index = fn._method.inputs.findIndex((item) => item.name === '_offset');
            fn.arguments[index] = count;

            //console.log(fn._method.name, count, process.env.REACT_APP_ARISTIPPUS_OFFSET);
            
            fn.call().then((result) => {
                //console.log(result);
                return resolve(result);
            }).catch((e) => {
                //console.log(e);
                return reject(e);
            })
        }));

        count += parseInt(process.env.REACT_APP_ARISTIPPUS_OFFSET);
    } while (count < balance);

    const results = await Promise.all(promises.map(p => p.catch(e => e)));
    const validResults = results.filter(result => !(result instanceof Error));

    return validResults;
}