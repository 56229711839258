import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Web3 from "web3";
import bip39 from "bip39js";
import { ethers, getDefaultProvider, Wallet } from "ethers";

import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import { saveAs } from "file-saver";

import MnemonicCheck from "@components/auth/create/MnemonicCheck";
import { passwordInput } from "@utils/PasswordInput";
import CardSimple from "@components/cards/CardSimple";
// import ReactHtmlParser from 'react-html-parser';


import { createCipher, createDecipher, createCipheriv, createDecipheriv } from 'crypto-browserify';

// function Hex2Bin(n){if(!checkHex(n))return 0;return parseInt(n,16).toString(2)}

// var cryp = (typeof global === 'undefined') ? require('crypto-browserify') : require('crypto');

const TransferWallet = () => {
   
    const MySwal = withReactContent(Swal)

    const web3 = new Web3();

    const dispatch = useDispatch();

    const transfer = async () => {

        Swal.fire({
            icon: 'warning',
            title: 'Access Wallet with Encrypted Private Key',
            text: 'Enter your encrypted private key',
            input: 'password',
            inputAttributes: {
                autocapitalize: 'off',
                // placeholder: 'Phrase part 1',
            },
            showCancelButton: true,
            confirmButtonText: 'Continue',
            // showLoaderOnConfirm: true,
            // backdrop: true,
            preConfirm: (password) => {
                if(password.length <= 0){
                    Swal.showValidationMessage(`This field is required`)
                    return false;
                }
    
                // if(password.length < 8){
                //     Swal.showValidationMessage(`Minimal password length is 8 characters`)
                //     return false;
                // }            
            },
            allowOutsideClick: false
            // allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            // if (result.isConfirmed) {
            //     if(result.value){
            //         return result.value;
            //     }
            // }
            // return false;

            if (result.isConfirmed) {

                try {

                    // const arr = result.value.split('|');
                    const strHash = result.value.substr(0, result.value.length - 16)
                    var strIv = result.value.substr(result.value.length - 16);

                    const secretKey = new Buffer("efd77bed61e8fdd0437df1ac", "utf8");
                    const iv = new Buffer(strIv, 'hex');
                    const text = strHash;
                    const decipher = createDecipheriv('des-ede3-cbc', secretKey, iv);
                    const privateKey = decipher.update(text, 'hex', 'utf8') + decipher.final('utf8');

                    // console.log(privateKey);

                    try{
                        const provider = getDefaultProvider();
                        const walletWithProvider = new Wallet(privateKey, provider);

                        if(walletWithProvider){
                            passwordInput(false).then((password) => {
                                if(!password) return false;
                                passwordInput(true, password).then((password2) => {
                                    if(!password2) return false;

                                    Swal.fire({
                                        title: 'Importing your wallet', 
                                        text: 'Import progress: 0%',
                                        allowOutsideClick: false,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                    })
            
                                    walletWithProvider.encrypt(password2, callback).then((json) => {
                                        dispatch({type: 'IMPORT_WALLET', payload: json});
                                    });
                                });
                            })
                        }


                    } catch (e){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Incorrect private key',
                            // timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false,
                            showDenyButton: false,
                            showCancelButton: true
                        })
                       
                        //console.log(e);
                        return false;
                    }

                } catch (error) {
                    // console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Please try again later',
                        // timer: 3000,
                        showCloseButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        showCancelButton: true
                    })

                    //console.log(error);
                    return false;
                }

                         
            }


        })


    }

	const callback = (process) => {
		let percent = Math.round(process * 100);
		Swal.update({
			text: `Import progress: ${percent}%`
		})

		if(process === 1){
			Swal.close();
		}
	}


    return (
        <CardSimple 
            title="Transfer wallet"
            subtitle={"Access your wallet with encrypted private key.\n\nThis information is sensitive. Do not share your encrypted private key anywhere else."}
            importantFlag={true}
            onClick={() => transfer()}
        />
    )
}

export default TransferWallet;