import React from "react";
import { AddressInput } from "@utils/AddressInput";
import { passwordCheck } from "@utils/PasswordCheck";
import { CoinFeeDialog } from "@utils/CoinFeeDialog";
import { AmountInput } from "@utils/AmountInput";
import { IntermediatePreloader } from "@utils/IntermediatePreloader";

import { GetGasAmount } from "@components/wallet/actions/utils/GetGasAmount";
import Swal from "sweetalert2"

import Web3 from "web3";
import { ethers } from "ethers";
import { rpcLink, coinsConfig } from "@config/CryptoConfig";

const Send = async (ccy, walletData, updateBalance) => {

    const { minABI, contractAddress, transferFee } = coinsConfig[ccy];

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(minABI, contractAddress);

    const CoinFeeDialogTmp = (amount) => {
        if(!transferFee){
            return new Promise((resolve, reject) => {
                return resolve(true);
            })
        } else {
            return CoinFeeDialog(coinsConfig[ccy], '0x' + walletData.address, amount);
        }
    }

    return new Promise(async (resolve, reject) => {
        await contract.methods.balanceOf('0x' + walletData.address).call().then((result) => {
            
            const balance = result / 100000000; 

            AmountInput(balance, ccy).then((amount) => {
                if(!amount) return reject(1);
        
                CoinFeeDialogTmp(amount).then((confirmed) => {
                    if(!confirmed) return reject(2);
        
                    AddressInput(walletData).then((address) => {
                        if(!address) return reject(3);

                        passwordCheck().then((password) => {
                            if(!password) return reject(4);
                            IntermediatePreloader().then(() => {
                                try{
                                    const wallet = web3.eth.accounts.decrypt(JSON.stringify(walletData), password, true);
                                    if(!wallet){
                                        return reject(5);
                                    }
            
                                    web3.eth.getGasPrice(function(e, r) {
    
                                        const deploy = async () => {
    
                                            const count = await web3.eth.getTransactionCount('0x' + walletData.address, 'latest').catch((e) => {
                                                //console.log(e);
                                                return reject(6);
                                            });
    
                                            try{
                                                const encoded = contract.methods.transfer(address, ethers.utils.parseUnits(amount, 8)).encodeABI();
                                                
                                                const gasAmount = await GetGasAmount('0x' + walletData.address, contractAddress, encoded);
                                            
                                                const rawTransaction = {
                                                    to: contractAddress, 
                                                    from: '0x' + walletData.address,
                                                    data: encoded,
                                                    gas: gasAmount,
                                                    // gas: 3000000,
                                                    gasPrice: r,
                                                    chainId: process.env.REACT_APP_RPC_CHAIN,
                                                    value: 0,
                                                    nonce: "0x" + count.toString(16),
                                                }
                                            
                                                const tx = await web3.eth.accounts.signTransaction(rawTransaction, wallet.privateKey).catch((e) => {
                                                    //console.log(e);
                                                    return reject(7);
                                                });
                                                
                                                const receipt = await web3.eth.sendSignedTransaction(tx.rawTransaction).catch((e) => {
                                                    //console.log(e);
                                                    return reject(8);
                                                });
    
                                                if(receipt && receipt.status){
                                                    Swal.fire({
                                                        icon: 'success',
                                                        title: '',
                                                        // title: 'Success',
                                                        text: '',
                                                        // text: 'Send request has been successfully submitted',
                                                        timer: 3000,
                                                        showCloseButton: false,
                                                        showConfirmButton: false,
                                                        showDenyButton: false
                                                    })
                                                
                                                    receipt.amount = ethers.utils.parseUnits(amount, 8);
                                                    receipt.coin = ccy;                                            
                                                    receipt.receiver = address;
    
                                                    // //console.log(receipt);
    
                                                    return resolve(receipt);
                                                } else {
                                                    Swal.fire({
                                                        icon: 'error',
                                                        title: 'Error',
                                                        text: 'Operation failed, please try again in a while',
                                                        timer: 3000,
                                                        showCloseButton: false,
                                                        showConfirmButton: false,
                                                        showDenyButton: false
                                                    })
    
                                                    return reject(9);
                                                }
                                            } catch (e) {
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Error',
                                                    text: 'Operation failed, please try again in a while',
                                                    timer: 3000,
                                                    showCloseButton: false,
                                                    showConfirmButton: false,
                                                    showDenyButton: false
                                                })
    
                                                return reject(19);
                                            }
                                        }
            
                                        deploy();
                                    })
            
                                } catch(e) {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: 'Operation failed, please try again in a while',
                                        timer: 3000,
                                        showCloseButton: false,
                                        showConfirmButton: false,
                                        showDenyButton: false
                                    })
    
                                    //console.log(e);
    
                                    return reject(6);
                                }
                            }).catch((e) => {
                                //console.log(e);
                            })
                        })
                    })
                }).catch((e) => {
                
                    console.log(e);

                    // Swal.fire({
                    //     icon: 'error',
                    //     title: 'Error',
                    //     text: 'Operation failed, please try again in a while',
                    //     timer: 3000,
                    //     showCloseButton: false,
                    //     showConfirmButton: false,
                    //     showDenyButton: false
                    // })
                })
            })
        }).catch((e) => {

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Operation failed, please try again in a while',
                timer: 3000,
                showCloseButton: false,
                showConfirmButton: false,
                showDenyButton: false
            })

            //console.log(e);
        });
    })

    

}

export default Send;
