import React, { useState, useEffect } from "react";

import CardSimple from "@components/cards/CardSimple";

const CreateWalletCommingSoon = () => {
   
    return (
        <CardSimple
            commingSoon={true}
            title="Create new wallet"
            subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        />
    )
}

export default CreateWalletCommingSoon;