import React, { createContext, useContext, useEffect, useState } from 'react'
import { NFTokens, rpcLink } from '@config/CryptoConfig';
import Web3 from 'web3';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { GetBalance } from '@components/NFTokens/utils/GetBalance';
import { GetCountByColor } from '@components/NFTokens/utils/GetCountByColor';
import GetAllTokensByColor from '@components/NFTokens/utils/GetAllTokensByColor';
import { GetTokenDetail } from '@components/NFTokens/utils/GetTokenDetail';
import { GetTransferFee } from '@components/NFTokens/utils/GetTransferFee';
import useRedirect from "@components/NFTokens/hooks/useRedirect";
import { CoinContext } from "@components/NFTokens/contexts/CoinContext";


export const colorEnum = {
    'yellow': 0,
    'red': 1,
    'green': 2,
    'purple': 3,
    'blue': 4,
}

export const NFTVolumeContext = createContext();

const NFTVolumeProvider = ({ children }) => {

    const dispatch = useDispatch();
    const { color = 'yellow' } = useParams();

    const [loading, setLoading] = useState(true);
    const { setBalance } = useContext(CoinContext);
    const [connectionError, setConnectionError] = useState(false);

    const { minABI, contractAddress, minABICombiner, contractAddressCombiner } = NFTokens.NFTVolume;

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);

    const contract = new web3.eth.Contract(minABI, contractAddress);
    const contractCombiner = new web3.eth.Contract(minABICombiner, contractAddressCombiner);

    const colorIndex = colorEnum[color];

    useRedirect(color, colorEnum, setLoading, setBalance);

    useEffect(() => {
        setLoading(true);

        GetBalance(contract, wallet)
            .then((tokenAmount) => {
                dispatch({ type: 'SET_NFTV_BALANCE', payload: tokenAmount })

                GetCountByColor(contractCombiner, wallet, tokenAmount)
                    .then((tokenAmountByColor) => dispatch({ type: 'SET_NFTV_COLOR_DATA', payload: tokenAmountByColor }))
                    .catch((err) => {
                        console.log(err);
                        setConnectionError(true);
                    })

                GetAllTokensByColor(contractCombiner, wallet, colorIndex, tokenAmount)
                    .then((tokensByColor) => {
                        GetTokenDetail(tokensByColor, contract, wallet)
                            .then((tokens) => {
                                dispatch({ type: 'SET_NFTV_COLLECTION_DATA', payload: tokens });
                                setLoading(false);
                            })
                            .catch((e) => {
                                console.log(e);
                                setLoading(false);
                                setConnectionError(true);
                            })
                    })
                    .catch((e) => {
                        console.log(e);
                        setLoading(false);
                        setConnectionError(true);
                    })
            })
            .catch((err) => {
                dispatch({ type: 'SET_ANFT_BALANCE', payload: 0 });
                console.log(err);
                setLoading(false);
                setConnectionError(true);
            });

        GetTransferFee(contract, 'transFee')
            .then((res) => dispatch({ type: 'SET_NFTV_TRANSFER_FEE', payload: res }))
            .catch((err) => console.log(err))

    }, [color]);

    return (
        <NFTVolumeContext.Provider value={{
            loading,
            connectionError,
            tokenName: 'Structure Volume',
            cryptoConfigKey: 'NFTVolume',
            currentColor: color,
            wallet
        }}>
            {children}
        </NFTVolumeContext.Provider>
    )
}

export default NFTVolumeProvider;