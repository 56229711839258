import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { NFTokens } from "@config/CryptoConfig";
import { TransformatorContext } from "@components/NFTokens/contexts/TransformatorContext";

const Header = () => {

    let { color = 'yellow' } = useParams();
    const { nftColorData } = useContext(TransformatorContext);

    return (
        <>
            <h2 className="treeName">
                Transformator
                <img src={require(`@images/transformator/ico.png`)}></img>
            </h2>

            <div className="header__menu">
                <Link to={`/${NFTokens.NFTTransformator.baseUrl}/yellow`} className={`transformatorMenu__link transformatorMenu__link-yellow ${color == 'yellow' ? 'transformatorMenu__link-active' : ''}`}>
                    <span className="transformatorMenu__link-cyrcle"></span>yellow ({nftColorData[0] ? nftColorData[0] : 0})
                </Link>
                <Link to={`/${NFTokens.NFTTransformator.baseUrl}/red`} className={`transformatorMenu__link transformatorMenu__link-red ${color == 'red' ? 'transformatorMenu__link-active' : ''}`}>
                    <span className="transformatorMenu__link-cyrcle"></span>red ({nftColorData[1] ? nftColorData[1] : 0})
                </Link>
                <Link to={`/${NFTokens.NFTTransformator.baseUrl}/green`} className={`transformatorMenu__link transformatorMenu__link-green ${color == 'green' ? 'transformatorMenu__link-active' : ''}`}>
                    <span className="transformatorMenu__link-cyrcle"></span>green ({nftColorData[2] ? nftColorData[2] : 0})
                </Link>
                <Link to={`/${NFTokens.NFTTransformator.baseUrl}/purple`} className={`transformatorMenu__link transformatorMenu__link-purple ${color == 'purple' ? 'transformatorMenu__link-active' : ''}`}>
                    <span className="transformatorMenu__link-cyrcle"></span>purple ({nftColorData[3] ? nftColorData[3] : 0})
                </Link>
                <Link to={`/${NFTokens.NFTTransformator.baseUrl}/blue`} className={`transformatorMenu__link transformatorMenu__link-blue ${color == 'blue' ? 'transformatorMenu__link-active' : ''}`}>
                    <span className="transformatorMenu__link-cyrcle"></span>blue ({nftColorData[4] ? nftColorData[4] : 0})
                </Link>
            </div>
        </>
    )
}

export default Header;