import React, { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ConvertDao from "@components/wallet/actions/ConvertDao";
import { CoinContext } from "@providers/CoinContext";
import { useNavigate } from "react-router-dom";

const DaoConvert = () => {
    
    const { getBalance } = useContext(CoinContext);
    
    const route = window.location.pathname; 

    const [balance, setBalance] = useState([]);

    const navigate = useNavigate();

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);

    const address = '0x' + wallet.address;

    useEffect( () => {
        getBalance().then( data => { 
            if (!(data.dao > 0 && data.state == 0)) navigate('/');
            setBalance(data) 
        })
    }, []);

    const processConvert = () => {
        Swal.fire({
            icon: 'warning',
            text: `Do you agree to confirm the exchange operation for GR DAO?`,
            showCancelButton: true,
            confirmButtonText: `Exchange`,
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                ConvertDao(wallet, address)
                    .then(() => {
                        getBalance().then((data) => setBalance(data))
                        .catch((e) => {
                            console.log(e);
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                    })
            }
        });

    }

    return (
        <div className={`daoConvert ${ route != '/' ? 'daoConvertPage' : ''}`}>
            {/* <div className="daoConvert__title">
                                GLOBAL RENAISSANCE DAO – GOVERNS!
                            </div>
                            <div className='daoConvert__text'>
                            From now on and forever ALL the important decisions will be made taking into account the results of the voting of the holders of the DAO governance coins.
                            </div> */}
            <div className="daoConvert__content">
                <div className="daoConvert__wrap">
                    <div className="daoConvert__elem daoConvert__elem-1">
                        <div className="daoConvertElem__row">
                            <div className="daoConvertElemRow__title">
                                GICO COIN
                            </div>
                            <div className="daoConvertElemRow__amount">
                                {balance.gico}
                            </div>
                        </div>
                        <div className="daoConvertElem__row">
                            <div className="daoConvertElemRow__title">
                                GOS COIN
                            </div>
                            <div className="daoConvertElemRow__amount">
                                {balance.gos}
                            </div>
                        </div>
                    </div>
                    <div className='daoConvert__iconWrap'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 51"><path d="M56.4 18.78c-.32.44-.58.93-.96 1.31a2338.8 2338.8 0 0 1-12.21 12.19c-.33.32-.78.62-1.22.71-1.2.25-2.12-.62-2.13-1.96l-.01-4.64c0-.37-.3-.68-.68-.68-2.8 0-13.53-.02-19.08.02-1.58.01-2.37-.93-2.28-2.3.03-.47.03-.94.02-1.4a.35.35 0 0 0-.59-.24L9.9 29.15a.34.34 0 0 0 0 .48l7.35 7.34c.22.22.59.06.59-.24v-1.45c0-1.57.68-2.24 2.27-2.25h13.85c.33 0 .68.02.99.12a1.8 1.8 0 0 1 1.24 1.9c-.1.87-.77 1.54-1.64 1.63-.2.02-.4.01-.6.01H22.02a.47.47 0 0 0-.47.46l-.02 4.75c0 .74-.19 1.39-.86 1.81-.68.43-1.46.4-2.1-.09a3.4 3.4 0 0 1-.44-.41L5.82 30.92c-1.08-1.08-1.08-2.05-.02-3.11 4.12-4.11 8.23-8.23 12.36-12.32.33-.33.77-.63 1.21-.73 1.19-.27 2.14.61 2.16 1.93.02 1.23.01 3.62.01 4.74 0 .33.27.6.6.6h19.32c1.39 0 2.09.71 2.09 2.1v1.61c0 .31.37.46.59.24l7.41-7.4a.34.34 0 0 0 0-.48l-7.54-7.53c-.16-.16-.44-.05-.44.18l-.03 2.12a1.8 1.8 0 0 1-1.64 1.78 2.7 2.7 0 0 1-.4.03H27.25a3 3 0 0 1-.84-.12 1.8 1.8 0 0 1-1.21-1.87c.09-.87.76-1.55 1.63-1.65.2-.02.4-.02.6-.02h11.98c.26 0 .47-.21.47-.47V5.78c0-.88.3-1.59 1.14-1.94.84-.35 1.55-.07 2.18.56 4.16 4.16 8.33 8.31 12.48 12.48.3.3.49.71.73 1.06l-.01.84z" /></svg>
                    </div>
                    <div className="daoConvert__elem daoConvert__elem-2">
                        <div className="daoConvertElem__row">
                            <div className="daoConvertElemRow__title">
                                GLOBAL RENAISSANCE DAO
                            </div>
                            <div className="daoConvertElemRow__amount">
                                {balance.dao}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="daoConvert__btnWrap">
                    <button type="button" onClick={() => processConvert()} className="convert__btn">Exchange</button>
                </div>
            </div>
        </div>
    )
}

export default DaoConvert;
