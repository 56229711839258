import Swal from "sweetalert2"
import Web3 from "web3";

export const AddressInput = async (wallet = undefined) => {

    // //console.log(wallet);

    return await Swal.fire({
        icon: 'warning',
        title: 'Send To',
        text: 'Enter wallet address',
        input: 'text',
        inputAttributes: {
            autocapitalize: 'off',
            placeholder: 'Address',
        },
        showCancelButton: true,
        confirmButtonText: 'Continue',
        showLoaderOnConfirm: true,
        backdrop: true,
        preConfirm: (address) => {
            var web3 = new Web3();
            if(!web3.utils.isAddress(address)){
                Swal.showValidationMessage(`Incorrect address format`)
                return false;
            }

            if(wallet){
                if('0x' + wallet.address.toLowerCase() == address.toLowerCase()){
                    Swal.showValidationMessage(`Incorrect address`)
                    return false;
                }
            }
        },
        // allowOutsideClick: () => !Swal.isLoading()
        allowOutsideClick: false
    }).then((result) => {
        if (result.isConfirmed) {
            if(result.value){
                return result.value;
            }
        }
        return false;
    })
}