import React from "react";
import styles from '@components/NFTokens/styles/NFTContainer.module.scss';
import Navbar from "@components/navbars/Navbar";
import { useNavigate } from "react-router-dom";

const NFTContainer = ({ children }) => {

    const navigate = useNavigate();

    return (
        <div className={styles.defaultContainer}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.content__wrap}>
                        <Navbar />
                        <div className={styles.mainWrapper}>
                            <div onClick={() => navigate(-1)} className={styles.back_btn}>← back</div>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NFTContainer;