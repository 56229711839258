
import React, { createContext, useEffect, useState } from 'react';
import { NFTokens, converterConfig, rpcLink } from '@config/CryptoConfig';
import Web3 from 'web3';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";

import { GetBalance } from '@components/NFTokens/utils/GetBalance';
import { GetCountByColor } from '@components/NFTokens/utils/GetCountByColor';
import { GetGuidelineBalanceOfColor } from '@components/NFTokens/utils/GetGuidelineBalanceOfColor';
import { GetGuidelineColorItems } from '@components/NFTokens/utils/GetGuidelineColorItems';
import { GetTokenDetail } from '@components/NFTokens/utils/GetTokenDetail';
import { GetFarmingPrice } from '@components/NFTokens/utils/GetFarmingPrice';
import { GetDaoMultiplier } from '@components/NFTokens/utils/GetDaoMultiplier';

export const AristippusCodex2023Context = createContext();

const AristippusCodex2023Provider = ({ children }) => {

    const navigate = useNavigate();
    const { color = 'yellow', guidline, index } = useParams();
    const dispatch = useDispatch();

    const balance = useSelector(state => state.aristippusNftReducer.balance)
    const farmingFee = useSelector(state => state.aristippusNftReducer.farmingFee)
    const nftColorData = useSelector(state => state.aristippusNftReducer.nftColorData);

    const nftColorGuidelineData = useSelector(state => state.aristippusNftReducer.nftColorGuidelineData);
    const nftCollectionData = useSelector(state => state.aristippusNftReducer.nftCollectionData);

    const [loaded, setLoaded] = useState(false);
    const [loadedCollection, setLoadedCollection] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    const [daoMultiplier, setDaoMultiplier] = useState(0);
    const [canReceive, setCanReceive] = useState(true);

    const colors = {
        'yellow': 0,
        'red': 1,
        'green': 2,
        'blue': 3,
        'purple': 4,
    }

    const totalByColor = [
        1000,
        1000,
        1000,
        1000,
        1000
    ];

    const { minABI, contractAddress, minABICombiner, contractAddressCombiner } = NFTokens.NFTMetaCodex2023;

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contractCombiner = new web3.eth.Contract(minABICombiner, contractAddressCombiner);
    const contract = new web3.eth.Contract(minABI, contractAddress);
    const contractDao = new web3.eth.Contract(converterConfig.daoConverter.minABI, converterConfig.daoConverter.contractAddress);

    useEffect(() => {
        GetFarmingPrice(contract)
            .then((result1) => {
                dispatch({ type: 'SET_ANFT_FARMING_FEE', payload: result1 })
            })
            .catch((e) => {
                console.log(e);
            });

        GetDaoMultiplier(contract)
            .then((result1) => {
                setDaoMultiplier(result1 / 100000000)
            })
            .catch((e) => {
                console.log(e);
            });

        GetBalance(contractDao, wallet).then(result => {
            setCanReceive((result.yes == 0 && result.co2 > 0) ? false : true);
        })
    }, []);

    useEffect(() => {

        if (guidline || index) {
            setLoaded(true);
            return false;
        }

        GetBalance(contract, wallet)
            .then((result1) => {
                dispatch({ type: 'SET_ANFT_BALANCE', payload: result1 })

                GetCountByColor(contractCombiner, wallet, result1)
                    .then((result2) => {
                        dispatch({ type: 'SET_ANFT_COLOR_DATA', payload: result2 })

                        GetGuidelineBalanceOfColor(contractCombiner, wallet, color ? colors[color] : 0, result1)
                            .then((result3) => {
                                dispatch({ type: 'SET_ANFT_COLOR_GUIDELINE_DATA', payload: result3 });
                                setLoaded(true);
                            })
                            .catch((e) => {
                                //console.log(e);
                                setConnectionError(true);
                            })
                    })
                    .catch((e) => {
                        //console.log(e);
                        setConnectionError(true);
                    })
            })
            .catch((e) => {
                //console.log(e);
                dispatch({ type: 'SET_ANFT_BALANCE', payload: 0 });
                setConnectionError(true);
            });
    }, []);

    useEffect(() => {
        if (!color || balance == null) return false;

        GetGuidelineBalanceOfColor(contractCombiner, wallet, colors[color], balance)
            .then((result) => {
                dispatch({ type: 'SET_ANFT_COLOR_GUIDELINE_DATA', payload: result });
                setLoaded(true);
            })
            .catch((e) => {
                //console.log(e);
                setConnectionError(true);
            })

    }, [color]);

    useEffect(() => {
        if (!color || !guidline || balance == null) return false;
        getTokenDetailInformation();

    }, [color, guidline]);

    const getTokenDetailInformation = () => {
        GetBalance(contract, wallet)
            .then((result1) => {
                dispatch({ type: 'SET_ANFT_BALANCE', payload: result1 })

                // console.log(result1);

                GetGuidelineColorItems(contractCombiner, wallet, colors[color], guidline, result1)
                    .then((result2) => {
                        // console.log(result2);
                        GetTokenDetail(result2, contract, wallet)
                            .then((result3) => {
                                // console.log(result3);
                                dispatch({ type: 'SET_ANFT_COLLECTION_DATA', payload: result3 });
                                setLoadedCollection(true);
                            }).catch((e) => {
                                //console.log(e);
                            })
                    })
                    .catch((e) => {
                        //console.log(e);
                        setConnectionError(true);
                    })
            })
            .catch((e) => {
                //console.log(e);
                setConnectionError(true);
            })
    }

    const redirect = (link) => {
        navigate(link, { replace: true });
    }

    return (
        <AristippusCodex2023Context.Provider value={{
            loaded,
            connectionError,

            nftColorData,
            nftColorGuidelineData,
            totalByColor,
            nftCollectionData,
            loadedCollection,
            farmingFee,

            wallet,
            getTokenDetailInformation,
            redirect,
            daoMultiplier,
            canReceive,
        }}>
            {children}
        </AristippusCodex2023Context.Provider>
    )
}

export default AristippusCodex2023Provider;
