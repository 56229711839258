import { useContext, useRef } from "react";
import SwapSVG from "@components/wallet/converter/components/SwapSVG";
import { handleExchange, handleWalletSwap } from "@components/wallet/converter/handlers/handlers";
import useConverter from "@components/wallet/converter/hooks/useConverter";
import ConverterInput from "@components/wallet/converter/components/ConverterInput";
import { CoinContext } from '@providers/CoinContext';

const Converter = ({ wallet, action, error }) => {

    const { walletDetails, setWalletDetails } = wallet;
    const { updateBalanceAllCoins } = useContext(CoinContext);
    const exchangeRef = useRef(null);

    useConverter(walletDetails, exchangeRef);

    return (
        <div className="convertDao">
            <h2 className="exchange__title">Exchange</h2>
            <div className="converter">
                <div className="converter__content">
                    <ConverterInput status='from' walletDetails={walletDetails} setWalletDetails={setWalletDetails} />
                    <button className="swap__changeBtn" onClick={() => handleWalletSwap(setWalletDetails)}>
                        <SwapSVG svgProps={{ width: '25px', height: '25px' }} />
                    </button>
                    <ConverterInput status='to' walletDetails={walletDetails} setWalletDetails={setWalletDetails} />
                </div>
            </div>
            <div className="swap__well">
                <p className="swapWell__text" id="rate">
                    <span id="rate1">
                        1 {walletDetails.coin.from.name} = <span>{walletDetails.course.from}</span> {walletDetails.coin.to.name}
                    </span>
                </p>
            </div>
            <div className="daoConvert__btnWrap">
                <button ref={exchangeRef} className="convert__btn" onClick={() => handleExchange(
                    walletDetails,
                    setWalletDetails,
                    action,
                    updateBalanceAllCoins,
                    error.setError
                )}>
                    Exchange
                </button>
            </div>
            <div className="swap__informer">
                <div className="swapInformer__textWrap">
                    <p className="swapInformer__text">
                        Keep in mind that every trade changes the price of both of the tokens
                        that are being traded. Therefore, a small difference between the initial request price and the final
                        price is possible.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Converter;