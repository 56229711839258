import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import NumberFormat from "@utils/NumberFormat";
import { coinsConfig } from "@config/CryptoConfig";
import { CoinContext } from "@providers/CoinContext";
import { useSelector } from "react-redux";

const Gos = () => {

    const coin = 'GOS';

    const { transactions, send, balance } = useContext(CoinContext)
    const { title } = coinsConfig[coin];
    const navigate = useNavigate();

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);

    function redirectToSolex24() {

        var urlSend = process.env.REACT_APP_SOLEX_24_WEBSITE+'/login/jwt-grwallet';
        var formData = new FormData();
        var sendForm = document.createElement("form");
        sendForm.target = "_blank";  
        sendForm.method = "POST";
        sendForm.action = urlSend;

        var sendInput = document.createElement("input");
        sendInput.type = "text";
        sendInput.name = "address";
        sendInput.value = '0x' + wallet.address;
        sendForm.appendChild(sendInput);

        document.body.appendChild(sendForm);
        sendForm.submit();

    }

    return (
        <div className="address-card__wrap">

            <div className={`address-card address-card__video address-card__${coin}`}>
                <div className="address-card__flex">
                    <div className="address-card__title">{title}</div>
                </div>
                <div className="address-card__flex">
                    {/* <div className="address-card__balance">{NumberFormat(balance[coin], 8, '.', '')}</div> */}
                    <div className="address-card__label">Available</div>
                    <div className="address-card__balance">{balance[coin]}</div>
                </div>

                <div className="address-card__mt address-card__buttons">
                    <div className="address-card__buttonWrap">
                        <button className="address-card__button" onClick={() => send(coin)}>Send</button>
                    </div>
                    <div className="address-card__buttonWrap">
                        <button className="address-card__button" onClick={() => navigate(`transactions/${coin}`)}>Transactions ({transactions[coin] ? transactions[coin].length : 0})</button>
                    </div>
                </div>
                <div className="address-card__mt address-card__buttons address-card__buttons2">
                    <div className="address-card__buttonWrap">
                            <span className="address-card-text">TOP UP</span>
                            <button className="address-card__button" onClick={() => redirectToSolex24()}>Use CRYPTO</button>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default Gos;