import React, { useEffect, useState, useContext } from "react";
import CheckLevel from '@components/wallet/actions/gico_to_dao/CheckLevel';
import { useSelector } from "react-redux";
import { CoinContext } from "@providers/CoinContext";

const Level = () => {

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);
    const { balance } = useContext(CoinContext);

    const [level, setLevel] = useState(0);

    useEffect(() => {

        // console.log('here 1');
        CheckLevel(wallet).then((level) => {
            // console.log("Уровень:", level);
            setLevel(level);
    
        }).catch((e) => {
            console.log("Ошибка:", e);
        });
    
    }, [balance]);
    
    return (
        <div className="convertDao__lvl">
            <div className={`address-card__levelBox convertDao__lvl-elem ${level == 1 ? 'level-active' : ''}`}>
                    <div className="address-card address-card__video convertDaoLvlElem__wrap address-card__">
                            <div className="convertDaoLvl__value">1</div>
                            <div className="convertDaoLvl__title">Level</div>
                    </div>
            </div>
            <div className={`address-card__levelBox convertDao__lvl-elem ${level == 2 ? 'level-active' : ''}`}>
                    <div className="address-card address-card__video convertDaoLvlElem__wrap address-card__">
                            <div className="convertDaoLvl__value">2</div>
                            <div className="convertDaoLvl__title">Level</div>
                    </div>
            </div>
            <div className={`address-card__levelBox convertDao__lvl-elem ${level == 3 ? 'level-active' : ''}`}>
                    <div className="address-card address-card__video convertDaoLvlElem__wrap address-card__">
                            <div className="convertDaoLvl__value">3</div>
                            <div className="convertDaoLvl__title">Level</div>
                    </div>
            </div>
        </div>
    )

}

export default Level;