import React, { useState, useContext } from "react";
import { TransformatorContext } from "@components/NFTokens/contexts/TransformatorContext";
import { CoinContext } from "@components/NFTokens/contexts/CoinContext";
import Swal from "sweetalert2";
import { SpinnerWhite } from '@components/spinner';
import Web3 from "web3";
import { NFTokens } from "@config/CryptoConfig";
import SendNft from "@components/NFTokens/actions/SendNft";
import { sendPrice, sendAktPrice } from "@config/TransformatorConfig";

const Send = ({ data }) => {

    const { wallet, redirect } = useContext(TransformatorContext);
    const { balance, undateCoinBalance } = useContext(CoinContext);

    const [loading, setLoading] = useState(false);

    const price = data.akt > 0 ? sendAktPrice : sendPrice;

    const send = (tokenId) => {

        if (balance['GOS'] && balance['GOS'] < price) {
            Swal.fire({
                icon: 'error',
                width: 600,
                title: 'Not enough GOS Coin on the account.',
                text: `The cost of this operation is ${price} GOS Coin`,
            });
            return false;
        }

        Swal.fire({
            icon: 'info',
            title: 'This function is not available at the moment',
            text: 'You will be able to send UTT starting from 14.03.2023',
        });
    }

    const sendFetch = (address, tokenId) => {
        Swal.fire({
            icon: 'warning',
            title: `Are you sure that you want to transfer NFT Meta Aristippus?`,
            html: `Send to address: ${address}`,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonColor: '#d33',
            denyButtonText: `Cancel`,
            preConfirm: async () => {

                await SendNft('NFTTransformator', tokenId, address, wallet)
                    .then((data) => {
                        return data;
                    })
                    .catch((e) => {
                        Swal.showValidationMessage(
                            `Request failed: ${e}`
                        )
                        setLoading(false);
                        return false;
                    })
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'Your request has been successfully submitted'
                    })
                        .then(() => redirect(`/${NFTokens.NFTTransformator.baseUrl}/yellow`))

                    undateCoinBalance('GOS');
                } else {
                    Swal.fire('Error', `Something went wrong. Try again later.`, 'error');
                }
            } else {
                setLoading(false);
            }
        });
    }

    return (
        <>
            {!loading ?
                <button type="button" onClick={() => send(data.tId)} className="cardFullInfoContent__btn">
                    <span className="cardFullInfoContent__btn-cyrcle"></span>
                    Send
                </button>
                :
                <button type="button" onClick={() => send(data.tId)} className="cardFullInfoContent__btn">
                    <SpinnerWhite />
                </button>
            }
        </>
    )
}

export default Send;
