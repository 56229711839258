import React from "react";

const Activate = ({ data }) => {

    return (
        <>
            {data.act == 0 ?
                <button type="button" className={`collectionBttn2 collectionBttn2-active`}>
                    Activate on the Platform
                </button>
                :
                <button type="button" className={`collectionBttn collectionBttn-active`}>
                    Activated
                </button>
            }
        </>
    );
}

export default Activate;