const NumberFormat = (number, decimals, dec_point, thousands_sep) => {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export default NumberFormat;

export const FarmedFormat = (number, decimals = 8) => {
    number = number.toString();
    let a = 0;
    if(number.length > 8) a = parseInt(number.slice(0, number.length - 8));
    let b = number.slice(-8).padStart(8, 0);

    //console.log(a, b);
    
    return NumberFormat(parseFloat(a + '.' + b), decimals, '.', '');
}

export const FeeFarmingFormat = (number) => {
    return parseFloat(number / 100000000);
}