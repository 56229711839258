import { Link } from "react-router-dom";
import useNFTList from "@components/NFTList/hooks/useNFTList";

const ListElement = ({ token }) => {

    const { balance } = useNFTList({ token });

    return (
        <div className="nftBlock__wrapper">
            <div className="nftBlock">
                <div className="walletCheck walletCheck-nft">
                    <div className="walletCheck__content">
                        <div className="walletCheck__name">
                            {token.title}
                        </div>
                        <div className="nft__right">

                            <Link to={token.slug} className="aristippusNft-link">
                                <div className="walletCheck__btnWrap">
                                    <div className="walletBttn walletBttn-receive">
                                        <span className="walletBttn__icon">
                                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 52"><path d="M33.57 3.5a1 1 0 1 0-.68 1.88 23 23 0 1 1-15.87.03 1 1 0 1 0-.69-1.87 25 25 0 1 0 17.23 0ZM15.02 25a1 1 0 0 0-.29.71 1 1 0 0 0 .3.71L23.82 35a1.82 1.82 0 0 0 2.55 0l8.56-8.56a1 1 0 0 0 0-1.42 1 1 0 0 0-1.41 0l-7.42 7.42V1a1 1 0 0 0-2 0v31.48L16.46 25a1 1 0 0 0-1.44 0Z" fill="#05a84a"></path></svg>
                                        </span>
                                        <span className="walletBttn__label">Open</span>
                                    </div>
                                </div>
                            </Link>

                            <div className="walletCheck__amountNft">
                                <div className="walletCheckAmountNft__label">
                                    Amount:
                                </div>
                                <div className="walletCheckAmountNft__value">
                                    {balance}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListElement;