import React from "react"
import NftCard from '@components/NFTokens/tokens/Wisdom/components/NftCard';
import { useParams } from "react-router-dom";

const NftCards = () => {

    const { color = 'green' } = useParams();

    return (
        <div className={'ma__collection ma__collection-' + color} >
            <div className="maCollection__content">
                {[...Array(40)].map((x, i) => {
                    let d = Math.floor(i / 4);
                    let m = i % 4;
                    let idx = m * 10 + d;
                    return(
                        <NftCard key={idx} guidline={idx} />
                    )
                })}
            </div>
        </div>
    );
}

export default NftCards;