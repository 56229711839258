import React, { useContext } from "react";
import { TransformatorContext } from "@components/NFTokens/contexts/TransformatorContext";
import { CoinContext } from "@components/NFTokens/contexts/CoinContext";
import Swal from "sweetalert2";
import StartFarm from "@components/NFTokens/actions/StartFarm";
import FinishFarm from "@components/NFTokens/actions/FinishFarm";
import { unfarmPrice } from "@config/TransformatorConfig";
import { FeeFarmingFormat } from "@utils/NumberFormat";

const Farm = ({ data }) => {

    const { wallet, getTokenDetailInformation, farmingFee } = useContext(TransformatorContext);
    const { balance, undateCoinBalance } = useContext(CoinContext);

    const farmPrice = FeeFarmingFormat(farmingFee);

    let totalMultiplyer = parseFloat(data.bns) + parseFloat(data.nitro) + parseFloat(data.akt);

    const farm = (event, tokenId) => {
        event.preventDefault();
        if (data.lastFarming > 0) {
            finishFarm(tokenId)
        } else {
            startFarm(tokenId)
        }
    }

    const startFarm = (tokenId) => {

        if (balance['GOS'] && balance['GOS'] < farmPrice) {
            Swal.fire({
                icon: 'error',
                width: 600,
                title: 'Not enough GOS Coin on the account.',
                text: `The cost of this operation is ${farmPrice} GOS Coin`,
            });
            return false;
        }

        Swal.fire({
            icon: 'warning',
            title: 'Start farming?',
            html: `Attention! The cost of this operation: ${farmPrice} GOS Coin`,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                await StartFarm('NFTTransformator', tokenId, wallet)
                    .then((data) => {
                        return data;
                    })
                    .catch((e) => {
                        Swal.showValidationMessage(
                            `Request failed: ${e}`
                        )
                        return false;
                    })
            },
            allowOutsideClick: () => !Swal.isLoading(),
            backdrop: true
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'Your request has been successfully submitted'
                    })

                    undateCoinBalance('GOS');
                    getTokenDetailInformation();
                } else {
                    Swal.fire('Error', `Something went wrong. Try again later.`, 'error');
                }
            }
        })
    }

    const finishFarm = (tokenId) => {

        if (balance['GOS'] && balance['GOS'] < unfarmPrice) {
            Swal.fire({
                icon: 'error',
                width: 600,
                title: 'Not enough GOS Coin on the account.',
                text: `The cost of this operation is ${unfarmPrice} GOS Coin`,
            });
            return false;
        }

        Swal.fire({
            icon: 'warning',
            title: 'Finish farming?',
            html: `Attention! The cost of this operation: ${unfarmPrice} GOS Coin`,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                await FinishFarm('NFTTransformator', tokenId, wallet)
                    .then((data) => {
                        return data;
                    })
                    .catch((e) => {
                        Swal.showValidationMessage(
                            `Request failed: ${e}`
                        )
                        return false;
                    })
            },
            allowOutsideClick: () => !Swal.isLoading(),
            backdrop: true
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'Your request has been successfully submitted'
                    })

                    undateCoinBalance('GOS');
                    getTokenDetailInformation();
                } else {
                    Swal.fire('Error', `Something went wrong. Try again later.`, 'error');
                }
            }
        })
    }

    return (
        <button type="button" className={`cardFullInfoContent__btn cardFullInfoContent__btn2 ${data.lastFarming > 0 ? 'cardFullInfoContent__btn-unfarm' : ''}`} onClick={(e) => farm(e, data.tId)}>
            <span className="cardFullInfoContent__btn-cyrcle"></span>
            {data.lastFarming > 0 ? 'Unfarm' : 'FARM 1 GICO PER DAY'}
        </button>
    )
}

export default Farm;