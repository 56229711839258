import TopColorSwitch from "@components/NFTokens/tokens/Volume/components/TopColorSwitch";
import styles from '@components/NFTokens/tokens/Volume/styles/VolumeToken.module.scss';
import TokenList from "@components/NFTokens/tokens/Volume/components/TokenList";
import { useContext } from "react";
import { NFTVolumeContext } from "@components/NFTokens/contexts/NFTVolumeContext";
import TopHeader from '@components/NFTokens/tokens/Volume/components/TopHeader';
import Preloader from "@components/preloaders/components/Preloader";

const VolumeToken = () => {

    const { loading } = useContext(NFTVolumeContext);

    return (
        <div className={styles.volume_container}>
            <div style={{ backgroundImage: 'url(/images/nft_volume/page_background.jpg)' }} className={styles.content_wrapper}>
                <TopHeader />
                <TopColorSwitch />
                <div className={styles.token_wrapper}>
                    {
                        loading
                            ? <div className={styles.preloader_wrapper}><Preloader /></div>
                            : <TokenList />
                    }
                </div>
            </div>
        </div>
    );
}

export default VolumeToken;