import NFTContainer from "@components/NFTokens/components/NFTContainer";
import Codex2023Token from "@components/NFTokens/tokens/Codex2023/Codex2023Token";
import AristippusCodex2023Provider from "@components/NFTokens/contexts/AristippusCodex2023Context";
import React from "react";

const Codex2023Page = () => (
    <NFTContainer>
        <AristippusCodex2023Provider>
            <Codex2023Token />
        </AristippusCodex2023Provider>
    </NFTContainer>
);

export default Codex2023Page;