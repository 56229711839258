import React, { useContext } from "react";
import { WisdomContext } from "@components/NFTokens/contexts/WisdomContext";
import { CoinContext } from "@components/NFTokens/contexts/CoinContext";
import Swal from "sweetalert2";
import StartFarm from "@components/NFTokens/actions/StartFarm";
import FinishFarm from "@components/NFTokens/actions/FinishFarm";
import { unfarmPrice } from "@config/AristippusNftConfig";
import NumberFormat, { FeeFarmingFormat } from "@utils/NumberFormat";
import { converterConfig } from "@config/CryptoConfig"
import { useNavigate } from "react-router-dom";

const Farm = ({ data }) => {

    const navigate = useNavigate();

    const { wallet, getTokenDetailInformation, farmingFee, daoMultiplier, canReceive } = useContext(WisdomContext);
    const { balance, undateCoinBalance } = useContext(CoinContext);

    const farmPrice = FeeFarmingFormat(farmingFee);

    let totalMultiplyer = (parseFloat(data.bns) + parseFloat(data.nitro) + parseFloat(data.akt)) * daoMultiplier;

    const farm = (event, tokenId) => {
        event.preventDefault();
        if (data.lastFarming > 0) {
            finishFarm(tokenId)
        } else {
            startFarm(tokenId)
        }
    }

    const startFarm = (tokenId) => {

        if (balance['GOS'] && balance['GOS'] < farmPrice) {
            Swal.fire({
                icon: 'error',
                width: 600,
                title: 'Not enough GOS Coin on the account.',
                text: `The cost of this operation is ${farmPrice} GOS Coin`,
            });
            return false;
        }

        Swal.fire({
            icon: 'warning',
            title: 'Start farming?',
            html: `Attention! The cost of this operation: ${farmPrice} GOS Coin`,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                await StartFarm('NFTMetaWisdom', tokenId, wallet)
                    .then((data) => {
                        return data;
                    })
                    .catch((e) => {
                        Swal.showValidationMessage(
                            `Request failed: ${e}`
                        )
                        return false;
                    })
            },
            allowOutsideClick: () => !Swal.isLoading(),
            backdrop: true
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'Your request has been successfully submitted'
                    })

                    undateCoinBalance('GOS');
                    getTokenDetailInformation();
                } else {
                    Swal.fire('Error', `Something went wrong. Try again later.`, 'error');
                }
            }
        })
    }

    const finishFarm = (tokenId) => {

        if (!canReceive) {
            Swal.fire({
                icon: 'error',
                title: 'Action is not available!',
                text: 'You did not exchange resources from the special transaction account for GR DAO Coin.',
                confirmButtonText: 'More details',
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    return navigate(converterConfig.daoConverter.slug);
                }
            });

            return false;
        }

        if (balance['GOS'] && balance['GOS'] < unfarmPrice) {
            Swal.fire({
                icon: 'error',
                width: 600,
                title: 'Not enough GOS Coin on the account.',
                text: `The cost of this operation is ${unfarmPrice} GOS Coin`,
            });
            return false;
        }

        Swal.fire({
            icon: 'warning',
            title: 'Finish farming?',
            html: `Attention! The cost of this operation: ${unfarmPrice} GOS Coin`,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                await FinishFarm('NFTMetaWisdom', tokenId, wallet)
                    .then((data) => {
                        return data;
                    })
                    .catch((e) => {
                        Swal.showValidationMessage(
                            `Request failed: ${e}`
                        )
                        return false;
                    })
            },
            allowOutsideClick: () => !Swal.isLoading(),
            backdrop: true
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'Your request has been successfully submitted'
                    })

                    undateCoinBalance('GOS');
                    getTokenDetailInformation();
                } else {
                    Swal.fire('Error', `Something went wrong. Try again later.`, 'error');
                }
            }
        })
    }

    return (
        <div className="maDetailContentBtnWrap__checkbox">
            <div className="switch">
                <input
                    type="checkbox"
                    name="switch"
                    className="switch-checkbox"
                    id={"myswitch_" + data.tId}
                    checked={data.lastFarming > 0 ? true : false}
                    onChange={(e) => farm(e, data.tId)}
                />
                <label className="switch-labels" htmlFor={"myswitch_" + data.tId}>
                    <span className="switch-text"></span>
                    <span className="switch-dot">
                        <span className="switchDot__on">ON</span>
                        <span className="switchDot__off dao_enableFarming">
                            <span className="switchDotoText dao_enableFarming">{NumberFormat(totalMultiplyer / 1000, 6, '.', '')} <span className="switchDotoText__gico">GRDA</span></span>
                            <span className="switchDotoText2 dao_enableFarming">per day</span>
                        </span>
                    </span>
                </label>
            </div>
        </div>
    )
}

export default Farm;
