import Swal from "sweetalert2"

export const FeeDialog = async (amount) => {
    return await Swal.fire({
        icon: 'warning',
        title: 'Attention!',
        text: `The cost of this operation is ${amount} GOS Coin`,
        showCancelButton: true,
        confirmButtonText: 'OK',
        showLoaderOnConfirm: true,
        backdrop: true,
        allowOutsideClick: false
    }).then((result) => {
        if (result.isConfirmed) {
            return true;
        }
        return false;
    })
}