import React from "react";
import './index.scss';

const CardSimple = ({title, subtitle, onClick, commingSoon, importantFlag = false}) => {

    return(
        <div className="cardWrap">
            <div className={['card', commingSoon ? 'commingSoon' : ''].join(' ')} onClick={onClick}>
                <div>
                    <div className="card__title">{title}</div>
                    {importantFlag ?
                        <div className="card__content">
                            <div className="cardContent__informer"><div className="cardContent__informer-text new-line">{subtitle}</div></div>
                        </div>
                        :
                        <div className="card__content new-line">{subtitle}</div>
                    }
                   
                </div>
            </div>
            {commingSoon && <div className="commingSoonBg"></div>}
        </div>
    )
}

export default CardSimple;