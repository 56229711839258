import NFTContainer from "@components/NFTokens/components/NFTContainer";
import TransformatorTokenDetail from "@components/NFTokens/tokens/Transformator/TransformatorTokenDetail";
import TransformatorProvider from "@components/NFTokens/contexts/TransformatorContext";
import React from "react";


const TransformatorDetailPage = () => (
    <NFTContainer>
        <TransformatorProvider>
            <TransformatorTokenDetail />
        </TransformatorProvider>
    </NFTContainer>
);

export default TransformatorDetailPage;