import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const useRedirect = (currentColor, colorEnum, setLoading = null, setBalance = null) => {
    const navigate = useNavigate();

    useEffect(() => {
        (colorEnum[currentColor] == undefined) && navigate("/");

        return () => {
            setLoading && setLoading(false);
            setBalance && setBalance(null);
        }

    }, []);
}

export default useRedirect;