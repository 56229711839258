import Swal from "sweetalert2"

export const IntermediatePreloader = async () => {

    return await Swal.fire({
        // icon: 'info',
        title: `In processing`,
        // text: 'Please wait your request is being processed',
        timer: 1000,
        // timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
        },
    }).then((result) => {
        return true;
    })
}