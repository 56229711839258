import React from "react";
import { useSelector } from "react-redux";

import DefaultWrapper from "@components/wrappers/DefaultWrapper";
import AuthPageContent from "@components/auth/AuthPageContent";
import Navbar from "@components/navbars/Navbar";

const AuthPage = () => {

    return (
        <DefaultWrapper>
            <div className="wrapper wrapper2">
                <div className="main__content">
                    <Navbar />
                    <div className="title">Welcome to GR Wallet</div>
                    <div className="subtitle">Simple and convenient wallet, without extra functionality</div>
                    <div>
                        <AuthPageContent />
                    </div>
                </div>
            </div>
        </DefaultWrapper>
    )

}

export default AuthPage;