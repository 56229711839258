import React, { useState, useContext } from "react";
import { AristippusCodex2023Context } from "@components/NFTokens/contexts/AristippusCodex2023Context";
import Swal from "sweetalert2";
import { SpinnerWhite } from '@components/spinner';
import { NFTokens, converterConfig } from "@config/CryptoConfig";
import BurnNft from "@components/NFTokens/actions/BurnNft";
import { useNavigate } from "react-router-dom";

const Burn = ({ data }) => {

    const navigate = useNavigate();

    const { wallet, redirect, canReceive } = useContext(AristippusCodex2023Context);

    const [loading, setLoading] = useState(false);

    const burn = () => {

        if (!canReceive) {
            Swal.fire({
                icon: 'error',
                title: 'Action is not available!',
                text: 'You did not exchange resources from the special transaction account for GR DAO Coin.',
                confirmButtonText: 'More details',
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    return navigate(converterConfig.daoConverter.slug);
                }
            });

            return false;
        }

        if (!data.tId) return;
        setLoading(true);

        Swal.fire({
            icon: 'warning',
            title: 'Burn token?',
            html: 'WARNING: Once confirmed, the NFT is permanently destroyed. If you are ever unsure, DON’T BURN!',
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                burnFetch();
            } else {
                setLoading(false);
            }
        })
    }

    const burnFetch = () => {
        Swal.fire({
            icon: 'warning',
            title: 'Burn token?',
            html: 'WARNING: Once confirmed, the NFT is permanently destroyed. If you are ever unsure, DON’T BURN!',
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                await BurnNft('NFTMetaCodex2023', data.tId, wallet)
                    .then((data) => {
                        return data;
                    })
                    .catch((e) => {
                        Swal.showValidationMessage(
                            `Request failed: ${e}`
                        )
                        setLoading(false);
                        return false;
                    })
            },
            allowOutsideClick: () => !Swal.isLoading(),
            backdrop: true

        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'Your request has been successfully submitted'
                    })

                    redirect(`/${NFTokens.NFTMetaCodex2023.baseUrl}/yellow`);
                    // getTokenDetailInformation();
                } else {
                    Swal.fire('Error', `Something went wrong. Try again later.`, 'error');
                }
            } else {
                setLoading(false);
            }
        })
    }

    return (
        <>
            {!loading ?
                <div onClick={() => burn()} className="burnBtn__wrap">
                    <img className="burnBtn__img" src={require('@images/aristippus_nft/burn.png')} />
                    burn
                </div>
                :
                <div className="burnBtn__wrap">
                    <img className="burnBtn__img" src={require('@images/aristippus_nft/burn.png')} />
                    <SpinnerWhite />
                </div>

            }
        </>
    )
}

export default Burn;
