import Web3 from "web3";
import { rpcLink } from "@config/CryptoConfig";
import { DaoDaoaCourse_ABI } from "@config/ABI/DaoDaoaABI";
import { ethers } from "ethers";
import { MultiplyOnDecimals } from "@utils/MultiplyOnDecimals";

const web3Provider = new Web3.providers.HttpProvider(rpcLink);
const web3 = new Web3(web3Provider);

const contract_status_course = new web3.eth.Contract(DaoDaoaCourse_ABI, process.env.REACT_APP_DEFI_DAO_ADAO);
const contract_exchange = new web3.eth.Contract(DaoDaoaCourse_ABI, process.env.REACT_APP_DEFI_DAO_ADAO);


export const setDaoDaoaBalance = (balance, setDaoDaoaProps) => {
    setDaoDaoaProps((prevState) => ({
        ...prevState,
        balance: {
            from: (prevState.coin.from.name === 'DAO') ? balance.DAO : balance.DAOA,
            to: (prevState.coin.to.name === 'DAO') ? balance.DAO : balance.DAOA
        }
    }));
}

export const setFee = (balance, setDaoDaoaProps) => {
    setDaoDaoaProps((prevState) => ({
        ...prevState,
        fee: {
            ...prevState.fee,
            balance: balance['GOS']
        }
    }));
}

export const getContractCourse = (setDaoDaoaProps, setError) => {
    contract_status_course.methods.getPrice(1, 1).call()
        .then((res) => {
            const daoToDaoa = parseFloat(res['_priceL'] / (10 ** 8));
            const daoaToDao = parseFloat(res['_priceR'] / (10 ** 8));

            setDaoDaoaProps((prevState) => ({
                ...prevState,
                course: {
                    from: (prevState.coin.from.name === 'DAO') ? daoToDaoa : daoaToDao,
                    to: (prevState.coin.from.name === 'DAO') ? daoaToDao : daoToDaoa
                }
            }));
        })
        .catch((err) => setError(err))
}

export const setWalletData = (setDaoDaoaProps, wallet_data) => {
    setDaoDaoaProps((prevState) => ({
        ...prevState,
        wallet_data: wallet_data
    }));
}

export const exchangeWallet = (walletDetails, password) => {

    const res = new Promise(async (resolve, reject) => {

        try {
            const wallet_address = '0x' + walletDetails.wallet_data.address;

            const wallet = web3.eth.accounts.decrypt(JSON.stringify(walletDetails.wallet_data), password, true);
            if (!wallet) return reject(5);

            web3.eth.getGasPrice(async function (e, r) {

                const count = await web3.eth.getTransactionCount(wallet_address, 'latest')
                    .catch((e) => {
                        return reject(6);
                    });

                let encoded = null;

                if (walletDetails.coin.from.name === 'DAO') encoded = contract_exchange.methods.convert2R(String(MultiplyOnDecimals(walletDetails.input.from, 8))).encodeABI();


                if (walletDetails.coin.from.name === 'DAO ADVANCED') encoded = contract_exchange.methods.convert2L(String(MultiplyOnDecimals(walletDetails.input.from, 8))).encodeABI();


                const gasAmount = await web3.eth.estimateGas({
                    to: process.env.REACT_APP_DEFI_DAO_ADAO,
                    from: wallet_address,
                    data: encoded,
                })
                    .catch((err) => { return reject(7) })

                const rawTransaction = {
                    to: process.env.REACT_APP_DEFI_DAO_ADAO,
                    from: wallet_address,
                    data: encoded,
                    gas: gasAmount,
                    gasPrice: r,
                    chainId: process.env.REACT_APP_RPC_CHAIN,
                    value: 0,
                    nonce: "0x" + count.toString(16)
                }

                // const weiBalance = await web3.eth.getBalance(wallet_address)
                //     .catch((err) => {
                //         console.log(err)
                //     })

                // console.log("Gas amount: " + gasAmount);
                // console.log("Gas price: " + r);
                // console.log("Gas value: " + 0);
                // console.log("Gas total value: " + (gasAmount * r + 0));
                // console.log("Gas to Ether: " + (web3.utils.fromWei(String(gasAmount * r + 0), 'ether')));

                // console.log("Ether balance: " + (web3.utils.fromWei(String(weiBalance), 'ether')));

                const sign = await web3.eth.accounts.signTransaction(rawTransaction, wallet.privateKey)
                    .catch((err) => {
                        return reject(8);
                    });

                if (!sign) return reject(9);

                const receipt = await web3.eth.sendSignedTransaction(sign.rawTransaction)
                    .catch((err) => {
                        return reject(10);
                    });

                if (receipt && receipt.status) return resolve(true);

                return reject("Convertation failed");
            })
        }
        catch (err) {
            reject(err.message);
        }
    });

    return res;
}