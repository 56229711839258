import Send from '@components/NFTokens/tokens/GOSHold/actions/Send';
import styles from '@components/NFTokens/tokens/GOSHold/styles/GOSHoldCard.module.scss';
import { msToSQLDate } from '@helpers/dateHelper';
import { useSelector } from 'react-redux';

const GOSHoldTokenCard = ({ token, wallet, tokenName, isBonus }) => {
    const reservedUntil = new Date(parseInt(token.releaseTime * (10 ** 3)));
    const date_string = msToSQLDate(reservedUntil);
    const { GOSHoldReducer } = useSelector(state => state);

    return (
        <div className={styles.GOSHoldCard}>
            <div className={styles.image_wrapper}>
                <img alt={`token_${token.tid}`} loading="lazy" className={styles.image} src="/images/nft_GOSHold/GOSHold_lock.jpg" />
            </div>

            <div className={styles.data_wrapper}>
                <div className={styles.text_wrapper}>
                    <p className={styles.text}><span className={styles.text_heading}>ID:</span> {token.tId}</p>
                    <p className={styles.text}><span className={styles.text_heading}>Amount:</span> <span className={styles.text_details}>{token.amount / (10 ** 8)} GOS COIN</span></p>
                    <p className={styles.text}><span className={styles.text_heading}>Reserved until:</span> {date_string}</p>
                </div>

                <div className={styles.button_wrapper}>
                    <Send token={token} wallet={wallet} GOSHoldReducer={GOSHoldReducer} tokenName={tokenName} isBonus={isBonus} />
                </div>
            </div>
        </div>
    );
}

export default GOSHoldTokenCard;