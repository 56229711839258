import React, { useContext } from "react";
import { AristippusCodex2023Context } from "@components/NFTokens/contexts/AristippusCodex2023Context";
import { Link, useParams } from "react-router-dom";
import { NFTokens } from "@config/CryptoConfig";
import { BackgroundImage } from 'react-image-and-background-image-fade';
import '@components/NFTokens/tokens/Codex2023/styles/aristippus_codex_2023.scss';
import Loading from "@components/NFTokens/components/Loading";
import NftCards from "@components/NFTokens/tokens/Codex2023/components/NftCards";
import TopColorSwitch from "@components/NFTokens/tokens/Codex2023/components/TopColorSwitch";
import BottomColorSwitch from "@components/NFTokens/tokens/Codex2023/components/BottomColorSwitch";

const Codex2023Token = () => {

    const { color = 'yellow' } = useParams();
    const { loaded, nftColorData } = useContext(AristippusCodex2023Context);

    if (!loaded) return <Loading />;

    return (
        <div className="codex2023_container">
            <TopColorSwitch />
            <div className="maMain__textWrap">
                <div className="maMain__text">
                    <b>NFT CODEX 2023</b> is a conceptual collection of NFTs, a new rethinking of the philosophy of Aristippus in an era of global change in digital business.
                </div>
                <div className="maMain__text">
                    The collection is distinguished by a special visual style, in which the elegance of modern crypto art coexists with the age-old wisdom of the outstanding thinker.
                </div>
            </div>

            <div className="maMain__menuWrap">
                <Link to={`/${NFTokens.NFTMetaCodex2023.baseUrl}/yellow`} className={`maMain__menuLink maMain__menuLink-yellow ${color == 'yellow' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_codex_2023/main/yellow-main.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />YELLOW set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-white">{nftColorData[0] ? nftColorData[0] : 0}</span>
                    </div>
                </Link>
                <Link to={`/${NFTokens.NFTMetaCodex2023.baseUrl}/red`} className={`maMain__menuLink maMain__menuLink-red ${color == 'red' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_codex_2023/main/red-main.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />RED set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-white">{nftColorData[1] ? nftColorData[1] : 0}</span>
                    </div>
                </Link>
                <Link to={`/${NFTokens.NFTMetaCodex2023.baseUrl}/green`} className={`maMain__menuLink maMain__menuLink-green ${color == 'green' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_codex_2023/main/green-main.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />GREEN set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-white">{nftColorData[2] ? nftColorData[2] : 0}</span>
                    </div>
                </Link>
                <Link to={`/${NFTokens.NFTMetaCodex2023.baseUrl}/blue`} className={`maMain__menuLink maMain__menuLink-blue ${color == 'blue' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_codex_2023/main/blue-main.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />BLUE set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-white">{nftColorData[3] ? nftColorData[3] : 0}</span>
                    </div>
                </Link>
                <Link to={`/${NFTokens.NFTMetaCodex2023.baseUrl}/purple`} className={`maMain__menuLink maMain__menuLink-purple ${color == 'purple' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_codex_2023/main/purple-main.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />PURPLE set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-white">{nftColorData[4] ? nftColorData[4] : 0}</span>
                    </div>
                </Link>
            </div>

            <div className="collectionWrap">
                {/* <div className="collectionWrap__row">
                    <div className="collectionWrapRow__elem">
                        Текущая стоимость коллекции:
                    </div>
                    <div className="collectionWrapRow__elem collectionWrapRow__elem-valueWrap">
                        <div className="collectionWrapRowElem__value">
                            100 <span className="gos__logo">GOS</span>
                        </div>
                        <div className="collectionWrapRowElem__value-line"></div>
                        <div className="collectionWrapRowElem__value">
                            100 <span className="bonus__logo">BONUS</span>
                        </div>
                    </div>
                </div> */}
                <div className="maCollection__content1">
                    <NftCards />

                    {/* <CollectAll /> */}
                </div>
            </div>
            <BottomColorSwitch />
        </div>
    )

}

export default Codex2023Token;