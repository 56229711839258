import React from "react";
import NFTContainer from "@components/NFTokens/components/NFTContainer";
import AristippusToken from "@components/NFTokens/tokens/Aristippus/AristippusToken";
import AristippusProvider from "@components/NFTokens/contexts/AristippusContext";

const AristippusPage = () => (
    <NFTContainer>
        <AristippusProvider>
            <AristippusToken />
        </AristippusProvider>
    </NFTContainer>
);

export default AristippusPage;