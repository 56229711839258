import React, { createContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Send from "@components/wallet/actions/Send";
import GetBalance from "@components/wallet/actions/GetBalance";
import { coins } from '@config/CryptoConfig';
import { rpcLink, converterConfig } from "@config/CryptoConfig";

import Web3 from "web3";

export const CoinContext = createContext();

const CoinProvider = ({ children }) => {

    const transactions = useSelector(state => state.transactionReducer.transactions);
    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);
    const dispatch = useDispatch();
    const [balance, setBalance] = useState([]);

    const { minABI, contractAddress } = converterConfig['daoConverter'];

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(minABI, contractAddress);

    const address = '0x' + wallet.address;

    const getBalance = () => {
        return new Promise(async (resolve, reject) => {
            try {
                await contract.methods.balanceOf(address).call().then((result) => {
                    const data = [];
                    data['gos'] = (result['co1'] / 100000000).toFixed(8);
                    data['gico'] = (result['co3'] / 100000000).toFixed(8);
                    data['dao'] = (result['co2'] / 100000000).toFixed(8);
                    data['ecn'] = result['ro2'];
                    data['state'] = result['yes'];

                    return resolve(data);
                }).catch((e) => {
                    return reject(e);
                })
            } catch (e) {
                return reject(e);
            }
        });
    }

    const updateBalanceAllCoins = async () => {

        const promises = [];
        const balanceTmp = [];

        coins.map((coin) => {
            promises.push(new Promise(async (resolve, reject) => {
                await GetBalance(coin, wallet).then((result) => {
                    return resolve({ coin: coin, balance: result });
                }).catch((e) => {
                    // //console.log(e);
                    return reject(e);
                })
            }))
        });

        await Promise.all(promises.map(promise =>
            promise.then((data) => {
                balanceTmp[data.coin] = data.balance;
                return data;
            }).catch((e) => {
                // //console.log(e);
            })
        )).catch((e) => {
            // //console.log(e);
        })

        // console.log(balanceTmp);

        setBalance(balanceTmp);
    }

    const undateCoinBalance = (coin) => {
        const balanceTmp = balance;
        GetBalance(coin, wallet).then((result) => {
            balanceTmp[coin] = result;
            setBalance(balanceTmp);
        }).catch((e) => {
            //console.log(e);
        })
    }

    useEffect(() => {
        //console.log('Update all coin balance');
        updateBalanceAllCoins();
    }, [])

    const send = (coin) => {
        Send(coin, wallet).then((receipt) => {
            dispatch({ type: 'ADD_TRANSACTION', payload: receipt });
            updateBalanceAllCoins();
        }).catch((e) => {
            //console.log(e);
        })
    }

    return (
        <CoinContext.Provider value={{
            transactions,
            send,
            balance,
            setBalance,
            undateCoinBalance,
            updateBalanceAllCoins,
            getBalance
        }}>
            {children}
        </CoinContext.Provider>
    )
}

export default CoinProvider;
