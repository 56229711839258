import { useEffect, useState } from "react";

const useConverter = (walletDetails, exchangeRef) => {

    useEffect(() => {
        (walletDetails.input.from === '' || walletDetails.input.to === '' ||
            parseFloat(walletDetails.input.from) == 0 || parseFloat(walletDetails.input.to) == 0 ||
            parseFloat(walletDetails.input.from) < 0.001)
            ? exchangeRef.current.disabled = true
            : exchangeRef.current.disabled = false;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletDetails]);
}

export default useConverter;