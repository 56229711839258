import Web3 from "web3";
import { rpcLink, converterConfig } from "@config/CryptoConfig";
import NumberFormat from "@utils/NumberFormat";

const CheckLevel = (walletData) => {

    const { minABI, contractAddress, transferFee } = converterConfig['gicoDaoConvert'];

    // console.log(contractAddress);

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(minABI, contractAddress);

    return new Promise(async(resolve, reject) => {
        try{
            await contract.methods.checkLvl('0x' + walletData.address).call().then((result) => {
                // console.log(result);
               return resolve(result);
            }).catch((e) => {
                return reject(e);
            })
        } catch (e) {
            return reject(e);
        }
    })
}

export default CheckLevel;