const DaoConverterABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "co1",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "co3",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "co2",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "ro2",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "yes",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "convert",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
];

export default DaoConverterABI;