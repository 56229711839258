import React, { useContext } from "react";
import { WisdomContext } from "@components/NFTokens/contexts/WisdomContext";
import { useParams, Link } from "react-router-dom";
import NumberFormat, { FarmedFormat } from "@utils/NumberFormat";
import { Romanize } from "@utils/Number";
import { BackgroundImage } from 'react-image-and-background-image-fade';
import { NFTokens } from "@config/CryptoConfig";

import Farm from "@components/NFTokens/tokens/Wisdom/actions/Farm";
import Collect from "@components/NFTokens/tokens/Wisdom/actions/Collect";
import Send from "@components/NFTokens/tokens/Wisdom/actions/Send";
import NotFound from "@components/NFTokens/components/NotFound";

const CollectionBlock = ({ index }) => {

    const { color = 'green', guidline } = useParams();
    const { nftCollectionData, loadedCollection, daoMultiplier } = useContext(WisdomContext);

    if (!loadedCollection) return <NotFound />;

    const data = nftCollectionData[index];

    const image = 'MAW-' + color[0].toUpperCase() + '-' + Romanize(guidline) + '.png';

    const isNitro = parseFloat(data.nitro) > 0 ? true : false;
    const isCombo = parseFloat(data.bns) > 1000 ? true : false;
    const isAdvanced = parseFloat(data.akt) > 0 ? true : false;
    const hasMultiplyer = isNitro || isCombo || isAdvanced ? true : false;
    const totalMultiplyer = (parseFloat(data.bns) + parseFloat(data.nitro) + parseFloat(data.akt)) * daoMultiplier;

    return (
        <div className={["maCollection__row", data.lastFarming > 0 ? 'maCollection__row-farm' : '', hasMultiplyer ? "maCollection__row-multiplyer" : ''].join(' ')}>
            <div className="maCollectionRow__left">
                <Link to={`/${NFTokens.NFTMetaWisdom.baseUrl}/${color}/collection/${guidline}/${index}`} className="maCollectionContent__link ">
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        src={require(`@images/aristippus_wisdom/collection/${color}/${image}`)}
                    >
                        <div className="maCollectionContentLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Click to view collection
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-red">{data.Number}</span>
                    </div>
                </Link>
            </div>
            <div className="maCollectionRow__right">

                <div className="maCollection__promotion-icons">
                    {isNitro &&
                        <div className="maCollection__promotion maCollection__promotion-nitro">
                            <img src={require(`@images/aristippus_nft/icons/n.png`)} />
                        </div>
                    }

                    {isCombo &&
                        <div className="maCollection__promotion maCollection__promotion-combo">
                            <img src={require(`@images/aristippus_nft/icons/c.png`)} />
                        </div>
                    }

                    {isAdvanced &&
                        <div className="maCollection__promotion maCollection__promotion-advanced">
                            <img src={require(`@images/aristippus_nft/icons/a.png`)} />
                        </div>
                    }
                </div>

                <div className="maCollectionRowRight__wrap">

                    {totalMultiplyer > 1 &&
                        <span className="maCollection__row-combination-bg">x{NumberFormat(totalMultiplyer, 2, '.', '')}</span>
                    }

                    <div className="maCollectionRowRight__top">
                        <div className="maCollectionRowRightTop__left">
                            <div className="maCollectionRowRightTop__row">
                                <div className="maCollectionRowRightTop__elem maCollectionRowRightTop__elem-name">
                                    Number:
                                </div>
                                <div className="maCollectionRowRightTop__elem maCollectionRowRightTop__elem-sep"></div>
                                <div className="maCollectionRowRightTop__elem maCollectionRowRightTop__elem-value">
                                    {data.Number}
                                </div>
                            </div>

                            <div className="maCollectionRowRightTop__row maCollectionRowRightTop__row-token">
                                <div className="maCollectionRowRightTop__elem maCollectionRowRightTop__elem-name">
                                    Token ID:
                                </div>
                                <div className="maCollectionRowRightTop__elem maCollectionRowRightTop__elem-sep"></div>
                                <div className="maCollectionRowRightTop__elem maCollectionRowRightTop__elem-value">
                                    {data.tId}
                                </div>
                            </div>
                        </div>

                        {data.lastFarming > 0 ?
                            <div className="maCollectionRowRightTop__right">
                                <div className="maCollectionRowRightTop__farmCountWrap">
                                    <div className="maCollectionRowRightTop__farmCount">
                                        <div className="maCollectionRowRightTopFarmCount__elem maCollectionRowRightTopFarmCount__elem-name">
                                            FARMED:
                                        </div>
                                        <div className="maCollectionRowRightTopFarmCount__elem maCollectionRowRightTopFarmCount__elem-value">
                                            {FarmedFormat(data.coin, 4)} <span className="grda__logo">GRDA</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="maCollectionRowRightTop__right">
                                <div className={['maCollectionRowRightTop__farmBlock'].join(' ')} >
                                    START FARM<br /> TO GET<br /><span className="maCollectionRowRightTop__farmBlock-red">{NumberFormat(totalMultiplyer / 1000, 6, '.', '')} <span className="grda__logo">GRDA</span></span> PER DAY
                                </div>
                            </div>
                        }
                    </div>
                    <div className="maCollectionRowRight__bottom" style={{ minHeight: 70 }}>

                        <div className="maCollectionRowRightBottom__right">
                            {data.lastFarming > 0 ?
                                <>
                                    <Collect data={data} />
                                </>
                                :
                                <>
                                    <Send data={data} />
                                </>
                            }

                            <Farm data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CollectionBlock;
