import React, { createContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Send from "@components/wallet/actions/Send";
import GetBalance from "@components/wallet/actions/GetBalance";
import { coins, expansionConfig } from '@config/CryptoConfig';
import { useParams } from "react-router-dom";
import Stake from "@components/wallet/actions/Stake";
import UnStake from "@components/wallet/actions/UnStake";
import Collect from "@components/wallet/actions/Collect";
import GetBalanceExpansion from "@components/wallet/actions/GetBalanceExpansion";

export const ExpansionContext = createContext();

const ExpansionProvider = ({children}) => {

    const { coin } = useParams();
    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);
    const dispatch = useDispatch();
    const [balance, setBalance] = useState(0);
    const [expansionBalance, setExpansionBalance] = useState([]);

    const getBalance = () => {
        GetBalance(coin, wallet).then((result) => {
            setBalance(result);
        }).catch((e) => {
            // //console.log(e);
        })
    }

    const updateBalanceAllCoins = async () => {

        const promises = [];
        const balanceTmp = [];

        expansionConfig[coin].map((row, index) => {
            promises.push(new Promise(async (resolve, reject) => {
                await GetBalanceExpansion(coin, index, wallet).then((result) => {
                    return resolve({index, balance: result});
                }).catch((e) => {
                    // //console.log(e);
                    return reject(e);
                })
            }))
        });

        await Promise.all(promises.map(promise => 
            promise.then((data) => {
                balanceTmp[data.index] = data.balance;
                return data;
            }).catch((e) => {
                // //console.log(e);
            })
        )).catch((e) => {

        })

        setExpansionBalance(balanceTmp);
    }

    const stake = ( index, amount ) => {
        Stake( coin, index, wallet, amount ).then((receipt) => {
            getBalance();
            updateBalanceAllCoins();
        }).catch((e) => {
            //console.log(e);
        })
    }

    const collect = ( index ) => {
        Collect( coin, index, wallet ).then((receipt) => {
            getBalance();
            updateBalanceAllCoins();
        }).catch((e) => {
            // //console.log(e);
        })
    }

    const unStake = ( index ) => {
        UnStake( coin, index, wallet ).then((receipt) => {
            getBalance();
            updateBalanceAllCoins();
        }).catch((e) => {
            // //console.log(e);
        })
    }

    useEffect(() => {
        getBalance();
        updateBalanceAllCoins();
    }, [])
    

    return (
        <ExpansionContext.Provider value={{
            stake,
            unStake,
            collect,
            // updateBalance,
            balance,
            expansionBalance
        }}>
            {children}
        </ExpansionContext.Provider>
    )
}

export default ExpansionProvider;