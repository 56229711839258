import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { rpcLink } from "@config/CryptoConfig";
import Web3 from "web3";
import { GetBalance } from "@components/NFTokens/utils/GetBalance";

const useNFTList = ({ token }) => {

    const [balance, setBalance] = useState(0);

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(token.minABI, token.contractAddress);

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);

    useEffect(() => {
        switch (token.name) {
            case 'NFTGOSHold':
                const gosHoldBonusContract = new web3.eth.Contract(token.minABI, token.contractAddressBonus);

                GetBalance(contract, wallet)
                    .then((balance) => {
                        GetBalance(gosHoldBonusContract, wallet)
                            .then((bonusBalance) => setBalance(parseInt(balance) + parseInt(bonusBalance)))
                            .catch((err) => console.log(err));
                    })
                    .catch((err) => console.log(err));
                break;

            default:
                GetBalance(contract, wallet)
                    .then((res) => setBalance(res))
                    .catch((err) => console.log(err));
        }

    }, []);

    return {
        balance
    };
}

export default useNFTList;