import { handleChange } from '@components/wallet/converter/handlers/handlers';
import styles from '@components/wallet/converter/styles/Converter.module.scss';

const ConverterInput = ({ status, walletDetails, setWalletDetails }) => (
    <div className="converterContent__row">
        <div className="converterContent__left">
            <label className="converterContent__label">You {(status === 'from' ? 'give' : 'receive')}:</label>
            <input className="converterContent__input" value={walletDetails.input[status]} name={status} type="text" placeholder="Enter amount" onChange={(event) => handleChange(event, walletDetails, setWalletDetails)} />
        </div>
        <div className="converterContent__right">
            <div className={`swapForm__logo ${(status === 'from' ? 'swapForm__logo-dao' : 'swapForm__logo-dao-adv')}`} style={{ backgroundColor: walletDetails.coin[status].color }}>
                <div className="swapFormLogo__iconWrap">
                    <img className="swapFormLogo__icon" src={walletDetails.coin[status].icon} alt={walletDetails.coin[status].name} />
                </div>
                <p className="swapFormLogo__text">{walletDetails.coin[status].name}</p>
            </div>
        </div>
        <p className="swapForm__balance">Balance: <span>{walletDetails.balance[status]}</span></p>
    </div>
);


export default ConverterInput;