import React, {useContext} from "react";
import { WisdomContext } from "@components/NFTokens/contexts/WisdomContext";
import { useParams } from "react-router-dom";
import { Romanize } from "@utils/Number";
import { titleData } from "@config/AristippusWisdomConfig";

import CollectionBlock from "@components/NFTokens/tokens/Wisdom/components/CollectionBlock";
import NotFound from "@components/NFTokens/components/NotFound";
import Loading from "@components/NFTokens/components/Loading";
import BottomColorSwitch from "@components/NFTokens/tokens/Wisdom/components/BottomColorSwitch";
import TopColorSwitch from "@components/NFTokens/tokens/Wisdom/components/TopColorSwitch";

const WisdomTokenCollection = () => {

    let { color, guidline } = useParams();
    const { nftCollectionData, loadedCollection } = useContext(WisdomContext);

    const count = nftCollectionData.length;

    if(!loadedCollection) return <Loading />;

    if(!count) return <NotFound />;

    return (
        <div className="wisdom_container">
            <TopColorSwitch />
            <div className={`ma__collection ma__collectionPage ma__collection-${color}`}>
                <div className="maDetail__top">
                    <div className="maDetailTop__titleWrap">
                        <div className="maDetailTop__titleWrap">
                            <div className="maDetailTop__title">{Romanize(guidline)} - {titleData[guidline]['title']}</div>
                        </div>
                    </div>
                </div>
                <div className="collectionWrap">
                    <div className="maCollection__content1">
                        {[...Array(count)].map((x, i) =>
                            <CollectionBlock key={i} index={i} />
                        )}
                    </div>
                </div>
            </div>
            <BottomColorSwitch />
        </div>
    )

}

export default WisdomTokenCollection;