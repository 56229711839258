import React, { useContext } from "react";
import { AlphabetContext } from "@components/NFTokens/contexts/AlphabetContext";
import { useParams } from "react-router-dom";
import { titleData } from "@config/AristippusWisdomConfig";
import Activate from '@components/NFTokens/tokens/Alphabet/actions/Activate';
import NotFound from "@components/NFTokens/components/NotFound";

const CollectionBlock = ({ index }) => {

    const { guidline } = useParams();
    const { nftCollectionData, loadedCollection } = useContext(AlphabetContext);

    if (!loadedCollection) return <NotFound />;

    const data = nftCollectionData[index];

    const image = (guidline) + '.png';

    return (
        <div className="collectionRow">
            <div className="collectionRow__number">
                <b>{data.Number}</b>
            </div>
            <div className="collectionRow__icon">
                <img src={require(`@images/alphabet/icons/${image}`)} />
            </div>
            <div className="collectionRow__title">{titleData[guidline]['title']}</div>
            <div className="collectionRow__button">
                <Activate data={data} />
            </div>
        </div>
    )

}

export default CollectionBlock;