import React from "react";

import { passwordCheck } from "@utils/PasswordCheck";
import { AmountInput } from "@utils/AmountInput";
import { IntermediatePreloader } from "@utils/IntermediatePreloader";
import { GetGasAmount } from "@components/wallet/actions/utils/GetGasAmount";
import Swal from "sweetalert2"

import Web3 from "web3";
import { ethers } from "ethers";
import { rpcLink } from "@config/CryptoConfig";
import { expansionConfig, expansionMinAbi } from "@config/CryptoConfig";
// import minABI from "@config/ABI/ExpansionABI";

const UnStake = async ( coin, index, walletData ) => {

    const contractAddress = expansionConfig[coin][index]['address'];

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(expansionMinAbi, contractAddress);

    
    return new Promise(async (resolve, reject) => {
        // await contract.methods.balanceOf('0x' + walletData.address).call().then((result) => {
            
            // const balance = result / 100000000; 

            // AmountInput(balance, coin).then((amount) => {
            //     if(!amount) return reject(1);
        
                // CoinFeeDialogTmp(amount).then((confirmed) => {
                //     if(!confirmed) return reject(2);
        
                    // AddressInput().then((address) => {
                    //     if(!address) return reject(3);
                        passwordCheck().then((password) => {
                            if(!password) return reject(4);
                            IntermediatePreloader().then(() => {
                                try{
                                    const wallet = web3.eth.accounts.decrypt(JSON.stringify(walletData), password, true);
                                    if(!wallet){
                                        return reject(5);
                                    }
            
                                    web3.eth.getGasPrice(function(e, r) {
    
                                        //console.log('r:', r);
    
                                        // r = 2000000000;
                                        // const factorToMultiplyGasEstimate = 50000;
                                        // r = r * factorToMultiplyGasEstimate;
    
                                        const deploy = async () => {
    
                                            //console.log('r:', r);
    
                                            var count = await web3.eth.getTransactionCount('0x' + walletData.address, 'latest').catch((e) => {
                                                //console.log(e);
                                                return reject(6);
                                            });
    
                                            //console.log('Count: ', count);
    
                                            try {
                                                const encoded = contract.methods.unstakeAll().encodeABI();
                                        
                                                //console.log('encoded: ', encoded);
    
                                                const gasAmount = await GetGasAmount('0x' + walletData.address, contractAddress, encoded);
                                                //console.log('gasAmount: ', gasAmount, r);
        
                                                var rawTransaction = {
                                                    to: contractAddress, 
                                                    from: '0x' + walletData.address,
                                                    data: encoded,
                                                    gas: gasAmount,
                                                    // gas: 3000000,
                                                    gasPrice: r,
                                                    chainId: process.env.REACT_APP_RPC_CHAIN,
                                                    value: 0,
                                                    nonce: "0x" + count.toString(16),
                                                }   
                                                
                                                //console.log('rawTransaction: ', rawTransaction);
                                            
                                                var tx = await web3.eth.accounts.signTransaction(rawTransaction, wallet.privateKey).catch((e) => {
                                                    //console.log(e);
                                                    return reject(7);
                                                });
        
                                                //console.log('tx: ', tx);
                                                
                                                var receipt = await web3.eth.sendSignedTransaction(tx.rawTransaction).catch((e) => {
                                                    //console.log(e);
                                                    return reject(8);
                                                });
        
                                                //console.log('receipt: ', receipt);
                
                                                if(receipt && receipt.status){
                                                    Swal.fire({
                                                        icon: 'success',
                                                        text: '',
                                                        timer: 3000,
                                                        showCloseButton: false,
                                                        showConfirmButton: false,
                                                        showDenyButton: false
                                                    })
                                                
                                                    //receipt.amount = ethers.utils.parseUnits(amount, 8);
                                                    // receipt.coin = ccy;                                            
                                                    
                                                    return resolve(receipt);
                                                } else {
                                                    Swal.fire({
                                                        icon: 'error',
                                                        title: 'Error',
                                                        text: '',
                                                        timer: 3000,
                                                        showCloseButton: false,
                                                        showConfirmButton: false,
                                                        showDenyButton: false
                                                    })
        
                                                    return reject(9);
                                                }
    
                                            } catch(e) {
                                                //console.log(e);
                                                return reject(61);
                                            }
                                            
                                        }
            
                                        deploy();
                                    })
            
                                } catch(e) {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: '',
                                        timer: 3000,
                                        showCloseButton: false,
                                        showConfirmButton: false,
                                        showDenyButton: false
                                    })
    
                                    //console.log(e);
                                    return reject(6);
                                }
                            }).catch((e) => {
                                //console.log(e);
                            })
                        })
                    // })
                // }).catch((e) => {
                //     //console.log(e);
                // })
            // })
        // }).catch((e) => {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Error',
        //         text: 'Operation failed, please try again in a while',
        //         timer: 3000,
        //         showCloseButton: false,
        //         showConfirmButton: false,
        //         showDenyButton: false
        //     })

        //     //console.log(e);
        // });
    })

    

}

export default UnStake;