import GosCoinABI from "@config/ABI/GosCoinABI";
import GicoCoinABI from "@config/ABI/GicoCoinABI";
import DaoCoinABI from "@config/ABI/DaoCoinABI";
import DaoACoinABI from "@config/ABI/DaoACoinABI";
import MeCoinABI from "@config/ABI/MeCoinABI";
import NftCollectorABI from "@config/ABI/NftCollectorABI";
import NftCollectionABI from "@config/ABI/NftCollectionABI";

import ExpansionABI from "@config/ABI/ExpansionABI";

import AristippusNftABI from "@config/ABI/AristippusNftABI";
import AristippusNftCombiner from "@config/ABI/AristippusNftCombiner";
import AristippusNftActions from "@config/ABI/AristippusNftActions";

import AristippusWisdomNftABI from "@config/ABI/AristippusWisdomNftABI";

import DaoConverterABI from "@config/ABI/DaoConverterABI";
import GicoDaoExchangeABI from "@config/ABI/GicoDaoExchangeABI";

import TransformatorABI from "@config/ABI/TransformatorABI";
import AlphabetABI from "@config/ABI/AlphabetABI";

import MetasentryABI from "@config/ABI/MetasentryABI";

import GOSHoldABI from '@config/ABI/GOSHoldABI';

import NFTVolumeABI from '@config/ABI/NFTVolumeABI';

import { NFTVolumeRainbowABI } from "@config/ABI/NFTVolumeRainbowABI";

import * as NFTokenPages from '@config/ExportShorthand/NFTExports';

import SmallCard from "@components/wallet/nft_collection/SmallCard";

export const rpcLink = process.env.REACT_APP_RPC_LINK;

export const coins = ['GOS', 'GICO', 'GICO_PROGRESS', 'DAO', 'DAOA', 'ME'];

export const coinsConfig = {
    "GOS": {
        "coin": "GOS",
        "title": "GOS COIN",
        "contractAddress": process.env.REACT_APP_GOS,
        "minABI": GosCoinABI,
        "transferFee": false,
        "coinVideo": "/video/coin.mp4",
    },
    "GICO": {
        "coin": "GICO",
        "title": "GICO COIN",
        "contractAddress": process.env.REACT_APP_GICO,
        "minABI": GicoCoinABI,
        "transferFee": true,
        "background": "#141950",
    },
    "GICO_PROGRESS": {
        "coin": "GICO_PROGRESS",
        "title": "GICO COIN PROGRESS",
        "contractAddress": process.env.REACT_APP_GICO_PROGRESS,
        "minABI": GicoCoinABI,
        "transferFee": true,
        "background": "#141950",
    },
    "DAO": {
        "coin": "DAO",
        "title": "GLOBAL RENAISSANCE DAO",
        "contractAddress": process.env.REACT_APP_DAO,
        "minABI": DaoCoinABI,
        "transferFee": true,
        "background": "#1ba600",
    },
    "DAOA": {
        "coin": "DAOA",
        "title": "GLOBAL RENAISSANCE DAO ADVANCED",
        "contractAddress": process.env.REACT_APP_DAOA,
        "minABI": DaoACoinABI,
        "transferFee": true,
        "background": "#fee985"
    },
    "NFT_COLLECTOR": {
        "contractAddress": process.env.REACT_APP_NFT_COLLECTOR,
        "minABI": NftCollectorABI,
    },
    "ME": {
        "coin": "ME",
        "title": "M*E COIN",
        "contractAddress": process.env.REACT_APP_ME_COIN,
        "minABI": MeCoinABI,
        "background": "#fee985"
    },
}

export const DAO_COLLECTION = process.env.REACT_APP_DAO_COLLECTION;
export const ROYALTY_COLLECTION = process.env.REACT_APP_ROYALTY_COLLECTION;
export const AMBASSADOR_COLLECTION = process.env.REACT_APP_AMBASSADOR_COLLECTION;
export const METAVERSE_COLLECTION = process.env.REACT_APP_METAVERSE_COLLECTION;
export const DINNER_GUEST_COLLECTION = process.env.REACT_APP_DINNER_GUEST_COLLECTION;
export const DINNER_WINNER_COLLECTION = process.env.REACT_APP_DINNER_WINNER_COLLECTION;
export const RELIABLE_1_COLLECTION = process.env.REACT_APP_RELIABLE_1_COLLECTION;
export const RELIABLE_2_COLLECTION = process.env.REACT_APP_RELIABLE_2_COLLECTION;
export const RELIABLE_3_COLLECTION = process.env.REACT_APP_RELIABLE_3_COLLECTION;
export const PATRON_COLLECTION = process.env.REACT_APP_PATRON_COLLECTION;
export const SUPREME_COLLECTION = process.env.REACT_APP_SUPREME_COLLECTION;
export const CONFIDENCE_COLLECTION = process.env.REACT_APP_CONFIDENCE_COLLECTION;
export const IMPULSE_COLLECTION = process.env.REACT_APP_IMPULSE_COLLECTION;
export const CREDENCE_COLLECTION = process.env.REACT_APP_CREDENCE_COLLECTION;
export const DAO_CUP_STAGE_1_COLLECTION = process.env.REACT_APP_DAO_CUP_STAGE_1_COLLECTION;
export const DAO_CUP_STAGE_2_COLLECTION = process.env.REACT_APP_DAO_CUP_STAGE_2_COLLECTION;
export const DAO_CUP_STAGE_3_COLLECTION = process.env.REACT_APP_DAO_CUP_STAGE_3_COLLECTION;
export const DAO_CUP_STAGE_4_COLLECTION = process.env.REACT_APP_DAO_CUP_STAGE_4_COLLECTION;
export const RELIABLE_21_COLLECTION = process.env.REACT_APP_RELIABLE_21_COLLECTION;
export const RELIABLE_22_COLLECTION = process.env.REACT_APP_RELIABLE_22_COLLECTION;
export const RELIABLE_23_COLLECTION = process.env.REACT_APP_RELIABLE_23_COLLECTION;
export const NEXT_ERA_STAGE_1 = process.env.REACT_APP_NEXT_ERA_STAGE_1;
export const NEXT_ERA_STAGE_2 = process.env.REACT_APP_NEXT_ERA_STAGE_2;
export const NEXT_ERA_STAGE_3 = process.env.REACT_APP_NEXT_ERA_STAGE_3;
export const NEXT_ERA_STAGE_4 = process.env.REACT_APP_NEXT_ERA_STAGE_4;
export const THE_BIG_DAY_2023 = process.env.REACT_APP_THE_BIG_DAY_2023;
export const NFT_COLLECTION_CORNERSTONE_CUSTODY = process.env.REACT_APP_NFT_COLLECTION_CORNERSTONE_CUSTODY;
export const GLORY_BLAZE_1 = process.env.REACT_APP_GLORY_BLAZE_1;
export const GLORY_BLAZE_2 = process.env.REACT_APP_GLORY_BLAZE_2;
export const GLORY_BLAZE_3 = process.env.REACT_APP_GLORY_BLAZE_3;
export const NEXTERA_WINNER = process.env.REACT_APP_NEXTERA_WINNER;
export const NEXTERA_GUEST = process.env.REACT_APP_NEXTERA_GUEST;
export const METHOS_VISION = process.env.REACT_APP_METHOS_VISION;
export const IA_APP = process.env.REACT_APP_IA_APP;
export const BR23 = process.env.REACT_APP_BR23;
export const GRDCUG1 = process.env.REACT_APP_GRDCUG1;
export const GRDCUG2 = process.env.REACT_APP_GRDCUG2;
export const GRDCUG3 = process.env.REACT_APP_GRDCUG3;
export const GRDCUG4 = process.env.REACT_APP_GRDCUG4;
export const FDoM = process.env.REACT_APP_FDoM;
export const UGW = process.env.REACT_APP_UNITY_GLOBE_WINNER;
export const UGG = process.env.REACT_APP_UNITY_GLOBE_GUEST;

export const nftCollections = {
    [DAO_COLLECTION]: {
        "title": "First Day",
        "minABI": NftCollectionABI,
        "slug": "dao",
        "icon": "/images/nft_collection/icon-dao.png",
        "image": "/images/nft_collection/nft-dao-card.gif",
        "component": <SmallCard address={DAO_COLLECTION} />,
    },
    [ROYALTY_COLLECTION]: {
        "title": "Origin",
        "minABI": NftCollectionABI,
        "slug": "royalty",
        "icon": "/images/nft_collection/icon-royalty.png",
        "image": "/images/nft_collection/nft-royalty-card.gif",
        "component": <SmallCard address={ROYALTY_COLLECTION} />,
    },
    [AMBASSADOR_COLLECTION]: {
        "title": "Ambassador",
        "minABI": NftCollectionABI,
        "slug": "ambassador",
        "icon": "/images/nft_collection/icon-ambassador.png",
        "image": "/images/nft_collection/nft-amb-card.gif",
        "component": <SmallCard address={AMBASSADOR_COLLECTION} />,
    },
    [METAVERSE_COLLECTION]: {
        "title": "The Metaverse Cornerstone",
        "minABI": NftCollectionABI,
        "slug": "metaverse",
        "icon": "/images/nft_collection/icon-metaverse.png",
        "image": "/images/nft_collection/nft-metaverse-cornerstone.gif",
        "component": <SmallCard address={METAVERSE_COLLECTION} />,
        "detailBackgroundImage": "/assets/images/metaverse-bg.jpg",
    },
    [DINNER_WINNER_COLLECTION]: {
        "title": "Participant of the presidential reception",
        "minABI": NftCollectionABI,
        "slug": "dinner-winner",
        "icon": "/images/nft_collection/icon-dinner.png",
        "image": "/images/nft_collection/winner_dinner_nft-big.gif",
        "component": <SmallCard address={DINNER_WINNER_COLLECTION} />,
        "detailBackgroundImage": "/assets/images/dinner-guest-bg.jpg",
    },
    [DINNER_GUEST_COLLECTION]: {
        "title": "Guest of the presidential reception",
        "minABI": NftCollectionABI,
        "slug": "dinner-guest",
        "icon": "/images/nft_collection/icon-dinner-guest.png",
        "image": "/images/nft_collection/dinner-guest-full.gif",
        "component": <SmallCard address={DINNER_GUEST_COLLECTION} />,
        "detailBackgroundImage": "/assets/images/dinner-guest-bg.jpg",
    },
    [RELIABLE_1_COLLECTION]: {
        "title": "Reliable partner",
        "minABI": NftCollectionABI,
        "slug": "reliable",
        "icon": "/images/nft_collection/icon-reliable-partner.png",
        "image": "/images/nft_collection/nft-reliable-partner-full.gif",
        "component": <SmallCard address={RELIABLE_1_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/reliable-partner-bg.jpg",
    },
    [RELIABLE_2_COLLECTION]: {
        "title": "Gold reliable partner",
        "minABI": NftCollectionABI,
        "slug": "gold-reliable",
        "icon": "/images/nft_collection/icon-gold-reliable-partner.png",
        "image": "/images/nft_collection/nft-gold-reliable-partner-small.gif",
        "component": <SmallCard address={RELIABLE_2_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/gold-reliable-bg.jpg",
    },
    [RELIABLE_3_COLLECTION]: {
        "title": "Unlimited reliable partner",
        "minABI": NftCollectionABI,
        "slug": "unlimited-reliable",
        "icon": "/images/nft_collection/icon-unlimited-reliable-partner.png",
        "image": "/images/nft_collection/nft-unlimited-reliable-partner-small.gif",
        "component": <SmallCard address={RELIABLE_3_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/unlimited-reliable-bg.jpg",
    },
    [PATRON_COLLECTION]: {
        "title": "The Patron",
        "minABI": NftCollectionABI,
        "slug": "patron",
        "icon": "/images/nft_collection/icon-patron.png",
        "image": "/images/nft_collection/NFT-Patron-Normal.gif",
        "component": <SmallCard address={PATRON_COLLECTION} />,
        "detailBackgroundImage": "/assets/images/patron-bg.jpg",
    },
    // [SUPREME_COLLECTION]: {
    //     "title": "The Supreme",
    //     "minABI": NftCollectionABI,
    //     "slug": "supreme",
    //     "icon": "/images/nft_collection/icon-supreme.png",
    //     "image": "/images/nft_collection/nft-supreme-small-card.gif",
    //     "component": <SmallCard address={SUPREME_COLLECTION} />,
    //     "detailBackgroundImage": "/images/nft_collection/supreme-bg.jpg",
    // },
    [CONFIDENCE_COLLECTION]: {
        "title": "The Metaverse Confidence",
        "minABI": NftCollectionABI,
        "slug": "confidence",
        "icon": "/images/nft_collection/icon-confidence.png",
        "image": "/images/nft_collection/confidence.gif",
        "component": <SmallCard address={CONFIDENCE_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/confidence-bg.jpg",
    },
    [IMPULSE_COLLECTION]: {
        "title": "NFT Metaverse Impulse",
        "minABI": NftCollectionABI,
        "slug": "impulse",
        "icon": "/images/nft_collection/icon-impulse.png",
        "image": "/images/nft_collection/nft-impulse-small.gif",
        "component": <SmallCard address={IMPULSE_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/impulse-bg.jpg",
    },
    [CREDENCE_COLLECTION]: {
        "title": "NFT METAVERSE CORNERSTONE 3.0 — CREDENCE",
        "minABI": NftCollectionABI,
        "slug": "credence",
        "icon": "/images/nft_collection/icon-credence.png",
        "image": "/images/nft_collection/nft-credence-small.gif",
        "component": <SmallCard address={CREDENCE_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/credence-bg.jpg",
    },
    [DAO_CUP_STAGE_1_COLLECTION]: {
        "title": "GR DAO CUP 2023 STAGE 1",
        "minABI": NftCollectionABI,
        "slug": "dao-cup-stage-1",
        "icon": "/images/nft_collection/stage1_icon.png",
        "image": "/images/nft_collection/dao_cup_2023_stage1_small.gif",
        "component": <SmallCard address={DAO_CUP_STAGE_1_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/stage-1-bg.jpg",
    },
    [DAO_CUP_STAGE_2_COLLECTION]: {
        "title": "GR DAO CUP 2023 STAGE 2",
        "minABI": NftCollectionABI,
        "slug": "dao-cup-stage-2",
        "icon": "/images/nft_collection/stage2_icon.png",
        "image": "/images/nft_collection/dao_cup_2023_stage2_small.gif",
        "component": <SmallCard address={DAO_CUP_STAGE_2_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/stage-1-bg.jpg",
    },
    [DAO_CUP_STAGE_3_COLLECTION]: {
        "title": "GR DAO CUP 2023 STAGE 3",
        "minABI": NftCollectionABI,
        "slug": "dao-cup-stage-3",
        "icon": "/images/nft_collection/stage3_icon.png",
        "image": "/images/nft_collection/dao_cup_2023_stage3_small.gif",
        "component": <SmallCard address={DAO_CUP_STAGE_3_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/stage-1-bg.jpg",
    },
    [DAO_CUP_STAGE_4_COLLECTION]: {
        "title": "GR DAO CUP 2023 STAGE 4",
        "minABI": NftCollectionABI,
        "slug": "dao-cup-stage-4",
        "icon": "/images/nft_collection/stage4_icon.png",
        "image": "/images/nft_collection/dao_cup_2023_stage4_small.gif",
        "component": <SmallCard address={DAO_CUP_STAGE_4_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/stage-1-bg.jpg",
    },
    [RELIABLE_21_COLLECTION]: {
        "title": "Reliable partner 2",
        "minABI": NftCollectionABI,
        "slug": "reliable-2",
        "icon": "/images/nft_collection/icon-reliable-partner-2.png",
        "image": "/images/nft_collection/nft-reliable-partner-2-small.gif",
        "component": <SmallCard address={RELIABLE_21_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/reliable-partner-bg.jpg",
    },
    [RELIABLE_22_COLLECTION]: {
        "title": "Gold reliable partner 2",
        "minABI": NftCollectionABI,
        "slug": "gold-reliable-2",
        "icon": "/images/nft_collection/icon-gold-reliable-partner-2.png",
        "image": "/images/nft_collection/nft-gold-reliable-partner-2-small.gif",
        "component": <SmallCard address={RELIABLE_22_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/gold-reliable-bg.jpg",
    },
    [RELIABLE_23_COLLECTION]: {
        "title": "Unlimited reliable partner 2",
        "minABI": NftCollectionABI,
        "slug": "unlimited-reliable-2",
        "icon": "/images/nft_collection/icon-unlimited-reliable-partner-2.png",
        "image": "/images/nft_collection/nft-unlimited-reliable-partner-2-small.gif",
        "component": <SmallCard address={RELIABLE_23_COLLECTION} />,
        "detailBackgroundImage": "/images/nft_collection/unlimited-reliable-bg.jpg",
    },
    [NEXT_ERA_STAGE_1]: {
        "title": "Nextera Stage 1",
        "minABI": NftCollectionABI,
        "slug": "nextera-stage-1",
        "icon": "/images/nft_collection/icon-nextera-st1.png",
        "image": "/images/nft_collection/nft-nextera-st1-small.gif",
        "component": <SmallCard address={NEXT_ERA_STAGE_1} />,
        "detailBackgroundImage": "/images/nft_collection/nextera-1-bg.jpg",
    },
    [NEXT_ERA_STAGE_2]: {
        "title": "Nextera Stage 2",
        "minABI": NftCollectionABI,
        "slug": "nextera-stage-2",
        "icon": "/images/nft_collection/icon-nextera-st2.png",
        "image": "/images/nft_collection/nft-nextera-st2-small.gif",
        "component": <SmallCard address={NEXT_ERA_STAGE_2} />,
        "detailBackgroundImage": "/images/nft_collection/nextera-2-bg.jpg",
    },
    [NEXT_ERA_STAGE_3]: {
        "title": "Nextera Stage 3",
        "minABI": NftCollectionABI,
        "slug": "nextera-stage-3",
        "icon": "/images/nft_collection/icon-nextera-st3.png",
        "image": "/images/nft_collection/nft-nextera-st3-small.gif",
        "component": <SmallCard address={NEXT_ERA_STAGE_3} />,
        "detailBackgroundImage": "/images/nft_collection/nextera-3-bg.jpg",
    },
    [NEXT_ERA_STAGE_4]: {
        "title": "Nextera Stage 4",
        "minABI": NftCollectionABI,
        "slug": "nextera-stage-4",
        "icon": "/images/nft_collection/icon-nextera-st4.png",
        "image": "/images/nft_collection/nft-nextera-st4-small.gif",
        "component": <SmallCard address={NEXT_ERA_STAGE_4} />,
        "detailBackgroundImage": "/images/nft_collection/nextera-4-bg.jpg",
    },
    [THE_BIG_DAY_2023]: {
        "title": "NFT The Big Day 2023",
        "minABI": NftCollectionABI,
        "slug": "the-big-day-2023",
        "icon": "/images/nft_collection/icon-the-big-day-2023.png",
        "image": "/images/nft_collection/nft-the-big-day-2023-small.gif",
        "component": <SmallCard address={THE_BIG_DAY_2023} />,
        "detailBackgroundImage": "/images/nft_collection/the-big-day-2023-bg.png",
    },
    [NFT_COLLECTION_CORNERSTONE_CUSTODY]: {
        "title": "NFT METAVERSE CORNERSTONE 4.0 — CUSTODY",
        "minABI": NftCollectionABI,
        "slug": "custody",
        "icon": "/images/nft_collection/icon-custody.png",
        "image": "/images/nft_collection/nft-custody-small-card.gif",
        "component": <SmallCard address={NFT_COLLECTION_CORNERSTONE_CUSTODY} />,
        "detailBackgroundImage": "/images/nft_collection/custody-bg.png",
    },
    [GLORY_BLAZE_1]: {
        "title": "NFT GLORY BLAZE 1",
        "minABI": NftCollectionABI,
        "slug": "glory-blaze-1",
        "icon": "/images/nft_collection/nft_Glory_Blaze_1_Icon.png",
        "image": "/images/nft_collection/nft_Glory_Blaze_1_smallsize.gif",
        "component": <SmallCard address={GLORY_BLAZE_1} />,
        "detailBackgroundImage": "/images/nft_collection/glory-blaze-1-bg.jpg",
    },
    [GLORY_BLAZE_2]: {
        "title": "NFT GLORY BLAZE 2",
        "minABI": NftCollectionABI,
        "slug": "glory-blaze-2",
        "icon": "/images/nft_collection/nft_Glory_Blaze_2_Icon.png",
        "image": "/images/nft_collection/nft_Glory_Blaze_2_smallsize.gif",
        "component": <SmallCard address={GLORY_BLAZE_2} />,
        "detailBackgroundImage": "/images/nft_collection/glory-blaze-2-bg.jpg",
    },
    [GLORY_BLAZE_3]: {
        "title": "NFT GLORY BLAZE 3",
        "minABI": NftCollectionABI,
        "slug": "glory-blaze-3",
        "icon": "/images/nft_collection/nft_Glory_Blaze_3_Icon.png",
        "image": "/images/nft_collection/nft_Glory_Blaze_3_smallsize.gif",
        "component": <SmallCard address={GLORY_BLAZE_3} />,
        "detailBackgroundImage": "/images/nft_collection/glory-blaze-3-bg.jpg",
    },
    [NEXTERA_WINNER]: {
        "title": "NEXTERA WINNER",
        "minABI": NftCollectionABI,
        "slug": "nextera-winner",
        "icon": "/images/nft_collection/ne-winner-icon.png",
        "image": "/images/nft_collection/ne-winner-nft_small.gif",
        "component": <SmallCard address={NEXTERA_WINNER} />,
        "detailBackgroundImage": "/images/nft_collection/ne-winner-bg.jpg",
    },
    [NEXTERA_GUEST]: {
        "title": "NEXTERA PARTICIPANT",
        "minABI": NftCollectionABI,
        "slug": "nextera-participant",
        "icon": "/images/nft_collection/ne-guest-icon.png",
        "image": "/images/nft_collection/ne-guest-nft_small.gif",
        "component": <SmallCard address={NEXTERA_GUEST} />,
        "detailBackgroundImage": "/images/nft_collection/ne-guest-bg.jpg",
    },
    [METHOS_VISION]: {
        "title": "M*ETHOS VISION",
        "minABI": NftCollectionABI,
        "slug": "methos-vision",
        "icon": "/images/nft_collection/methos-vision-icon.png",
        "image": "/images/nft_collection/methos-vision-nft_small.gif",
        "component": <SmallCard address={METHOS_VISION} />,
        "detailBackgroundImage": "/images/nft_collection/methos-vision-bg.png",
    },
    [IA_APP]: {
        "title": "NFT IA APP",
        "minABI": NftCollectionABI,
        "slug": "nft-ia-app",
        "icon": "/images/nft_collection/ai_icon.png",
        "image": "/images/nft_collection/ai_nft_small.gif",
        "component": <SmallCard address={IA_APP} />,
        "detailBackgroundImage": "/images/nft_collection/ai_app_bg.png",
    },
    [BR23]: {
        "title": "NFT RENESAR BRAZIL 2023",
        "minABI": NftCollectionABI,
        "slug": "nft-renesar-brazil-2023",
        "icon": "/images/nft_collection/icon-renaser-brazil-2023.png",
        "image": "/images/nft_collection/nft_renesar_brazil_small.gif",
        "component": <SmallCard address={BR23} />,
        "detailBackgroundImage": "/images/nft_collection/nft_renesar_brazil_bg.jpg",
    },
    [GRDCUG1]: {
        "title": "GR DAO CUP UnityGlobe 1",
        "minABI": NftCollectionABI,
        "slug": "unityglobe-stage-1",
        "icon": "/images/nft_collection/icon-unity-globe-stage-1.png",
        "image": "/images/nft_collection/nft_small.gif",
        "component": <SmallCard address={GRDCUG1} />,
        "detailBackgroundImage": null,
        "detailBackgroundImage": "/images/nft_collection/unity_globe_bg.jpg"
    },
    [GRDCUG2]: {
        "title": "GR DAO CUP UnityGlobe 2",
        "minABI": NftCollectionABI,
        "slug": "unityglobe-stage-2",
        "icon": "/images/nft_collection/icon-unity-globe-stage-2.png",
        "image": "/images/nft_collection/nft_small_2.gif",
        "component": <SmallCard address={GRDCUG2} />,
        "detailBackgroundImage": null,
        "detailBackgroundImage": "/images/nft_collection/unity_globe_bg.jpg"
    },
    [GRDCUG3]: {
        "title": "GR DAO CUP UnityGlobe 3",
        "minABI": NftCollectionABI,
        "slug": "unityglobe-stage-3",
        "icon": "/images/nft_collection/icon-unity-globe-stage-3.png",
        "image": "/images/nft_collection/nft_small_3.gif",
        "component": <SmallCard address={GRDCUG3} />,
        "detailBackgroundImage": null,
        "detailBackgroundImage": "/images/nft_collection/unity_globe_bg.jpg"
    },
    [GRDCUG4]: {
        "title": "GR DAO CUP UnityGlobe 4",
        "minABI": NftCollectionABI,
        "slug": "unityglobe-stage-4",
        "icon": "/images/nft_collection/icon-unity-globe-stage-4.png",
        "image": "/images/nft_collection/nft_small_4.gif",
        "component": <SmallCard address={GRDCUG4} />,
        "detailBackgroundImage": null,
        "detailBackgroundImage": "/images/nft_collection/unity_globe_bg.jpg"
    },
    [FDoM]: {
        "title": "FOR THE DEVELOPMENT OF METAVERSE",
        "minABI": NftCollectionABI,
        "slug": "for-the-development-of-metaverse",
        "icon": "/images/nft_collection/icon-for-the-development-of-metaverse.png",
        "image": "/images/nft_collection/fdom_small.gif",
        "component": <SmallCard address={FDoM} />,
        "detailBackgroundImage": "/images/nft_collection/fdom_bg.jpg",
    },
    [UGW]: {
        "title": "GR DAO CUP UnityGlobe Winner",
        "minABI": NftCollectionABI,
        "slug": "unityglobe-winner",
        "icon": "/images/nft_collection/unity_globe_winner_icon.png",
        "image": "/images/nft_collection/unity_globe_winner.gif",
        "component": <SmallCard address={UGW} />,
        "detailBackgroundImage": "/images/nft_collection/unity_globe_bg.jpg",
    },
    [UGG]: {
        "title": "GR DAO CUP UnityGlobe Guest",
        "minABI": NftCollectionABI,
        "slug": "unityglobe-guest",
        "icon": "/images/nft_collection/unity_globe_guest_icon.png",
        "image": "/images/nft_collection/unity_globe_guest.gif",
        "component": <SmallCard address={UGG} />,
        "detailBackgroundImage": "/images/nft_collection/unity_globe_bg.jpg",
    },
}

export const nftCollectionsOrder = [
    NFT_COLLECTION_CORNERSTONE_CUSTODY,
    CREDENCE_COLLECTION,
    CONFIDENCE_COLLECTION,
    // SUPREME_COLLECTION,
    PATRON_COLLECTION,
    METAVERSE_COLLECTION,
    AMBASSADOR_COLLECTION,
    ROYALTY_COLLECTION,
    DAO_COLLECTION,
    DINNER_WINNER_COLLECTION,
    DINNER_GUEST_COLLECTION,
    RELIABLE_1_COLLECTION,
    RELIABLE_2_COLLECTION,
    RELIABLE_3_COLLECTION,
    IMPULSE_COLLECTION,
    DAO_CUP_STAGE_1_COLLECTION,
    DAO_CUP_STAGE_2_COLLECTION,
    DAO_CUP_STAGE_3_COLLECTION,
    DAO_CUP_STAGE_4_COLLECTION,
    RELIABLE_21_COLLECTION,
    RELIABLE_22_COLLECTION,
    RELIABLE_23_COLLECTION,
    NEXT_ERA_STAGE_1,
    NEXT_ERA_STAGE_2,
    NEXT_ERA_STAGE_3,
    NEXT_ERA_STAGE_4,
    THE_BIG_DAY_2023,
    GLORY_BLAZE_1,
    GLORY_BLAZE_2,
    GLORY_BLAZE_3,
    NEXTERA_WINNER,
    NEXTERA_GUEST,
    METHOS_VISION,
    IA_APP,
    BR23,
    GRDCUG1,
    GRDCUG2,
    GRDCUG3,
    GRDCUG4,
    FDoM,
    UGW,
    UGG
];

export const nftCollectionsSlugs = {
    "dao": DAO_COLLECTION,
    "royalty": ROYALTY_COLLECTION,
    "ambassador": AMBASSADOR_COLLECTION,
    "metaverse": METAVERSE_COLLECTION,
    "dinner-winner": DINNER_WINNER_COLLECTION,
    "dinner-guest": DINNER_GUEST_COLLECTION,
    "reliable": RELIABLE_1_COLLECTION,
    "gold-reliable": RELIABLE_2_COLLECTION,
    "unlimited-reliable": RELIABLE_3_COLLECTION,
    "patron": PATRON_COLLECTION,
    // "supreme": SUPREME_COLLECTION,
    "confidence": CONFIDENCE_COLLECTION,
    "impulse": IMPULSE_COLLECTION,
    "credence": CREDENCE_COLLECTION,
    "dao-cup-stage-1": DAO_CUP_STAGE_1_COLLECTION,
    "dao-cup-stage-2": DAO_CUP_STAGE_2_COLLECTION,
    "dao-cup-stage-3": DAO_CUP_STAGE_3_COLLECTION,
    "dao-cup-stage-4": DAO_CUP_STAGE_4_COLLECTION,
    "reliable-2": RELIABLE_21_COLLECTION,
    "gold-reliable-2": RELIABLE_22_COLLECTION,
    "unlimited-reliable-2": RELIABLE_23_COLLECTION,
    "nextera-stage-1": NEXT_ERA_STAGE_1,
    "nextera-stage-2": NEXT_ERA_STAGE_2,
    "nextera-stage-3": NEXT_ERA_STAGE_3,
    "nextera-stage-4": NEXT_ERA_STAGE_4,
    "the-big-day-2023": THE_BIG_DAY_2023,
    "custody": NFT_COLLECTION_CORNERSTONE_CUSTODY,
    "glory-blaze-1": GLORY_BLAZE_1,
    "glory-blaze-2": GLORY_BLAZE_2,
    "glory-blaze-3": GLORY_BLAZE_3,
    "nextera-winner": NEXTERA_WINNER,
    "nextera-participant": NEXTERA_GUEST,
    "methos-vision": METHOS_VISION,
    "nft-ia-app": IA_APP,
    "nft-renesar-brazil-2023": BR23,
    "unityglobe-stage-1": GRDCUG1,
    "unityglobe-stage-2": GRDCUG2,
    "unityglobe-stage-3": GRDCUG3,
    "unityglobe-stage-4": GRDCUG4,
    "for-the-development-of-metaverse": FDoM,
    "unityglobe-winner": UGW,
    "unityglobe-guest": UGG
}

export const aristippus = {
    // 1: {
    //     "minABI": AristippusNftABI,
    //     "minABICombiner": AristippusNftCombiner,
    //     "minABIActions": AristippusNftActions,
    //     "contractAddress": process.env.REACT_APP_ARISTIPPUS_NFT,
    //     "contractAddressCombiner": process.env.REACT_APP_ARISTIPPUS_NFT_COMBINER,
    //     "title": "META ARISTIPPUS",
    //     "slug": "meta-aristippus/yellow",
    //     "baseUrl": "meta-aristippus",
    //     "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/codex',
    // },
    // 2: {
    //     "minABI": AristippusWisdomNftABI,
    //     "minABICombiner": AristippusNftCombiner,
    //     "minABIActions": AristippusNftActions,
    //     "contractAddress": process.env.REACT_APP_ARISTIPPUS_WISDOM,
    //     "contractAddressCombiner": process.env.REACT_APP_ARISTIPPUS_WISDOM_COMBINER,
    //     "title": "META WISDOM",
    //     "slug": "meta-wisdom/green",
    //     "baseUrl": "meta-wisdom",
    //     "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/wisdom',
    // },
    // 3: {
    //     "minABI": AristippusNftABI,
    //     "minABICombiner": AristippusNftCombiner,
    //     "minABIActions": AristippusNftActions,
    //     "contractAddress": process.env.REACT_APP_META_CODEX_2023,
    //     "contractAddressCombiner": process.env.REACT_APP_META_CODEX_2023_COMBINER,
    //     "title": "META CODEX 2023",
    //     "slug": "meta-codex-2023/yellow",
    //     "baseUrl": "meta-codex-2023",
    //     "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/codex-2023',
    // },
    // 4: {
    //     "minABI": AlphabetABI,
    //     "minABICombiner": AristippusNftCombiner,
    //     "minABIActions": AristippusNftActions,
    //     "contractAddress": process.env.REACT_APP_ALPHABET,
    //     "contractAddressCombiner": process.env.REACT_APP_ALPHABET_COMBINER,
    //     "title": "ALPHABET",
    //     "slug": "alphabet-token/yellow",
    //     "baseUrl": "alphabet-token",
    //     "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/alphabet',
    // },
    // 5: {
    //     "minABI": TransformatorABI,
    //     "minABICombiner": AristippusNftCombiner,
    //     "minABIActions": AristippusNftActions,
    //     "contractAddress": process.env.REACT_APP_TRANSFORMATOR,
    //     "contractAddressCombiner": process.env.REACT_APP_TRANSFORMATOR_COMBINER,
    //     "title": "TRANSFORMATOR",
    //     "slug": "transformator-token/yellow",
    //     "baseUrl": "transformator-token",
    //     "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/transformator',
    // },
    // 6: {
    //     "minABI": GOSHoldABI,
    //     "minABICombiner": AristippusNftCombiner,
    //     "minABIActions": AristippusNftActions,
    //     "contractAddress": process.env.REACT_APP_GOSHOLD,
    //     "contractAddressBonus": process.env.REACT_APP_GOSHOLD_BONUS,
    //     "title": "GOSHOLD",
    //     "slug": "goshold-token",
    //     "baseUrl": "goshold-token",
    //     "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/gos-hold'
    // },
    // 7: {
    //     "minABI": NFTVolumeABI,
    //     "minABICombiner": AristippusNftCombiner,
    //     "minABIActions": AristippusNftActions,
    //     "contractAddress": process.env.REACT_APP_NFTVolume,
    //     "contractAddressCombiner": process.env.REACT_APP_NFTVolume_COMBINER,
    //     "title": "NFTVOLUME",
    //     "slug": "nft-volume/yellow",
    //     "baseUrl": "nft-volume",
    //     "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/volume-nft/tokens'
    // },
}

export const NFTokens = {
    NFTMetaAristippus: {
        "minABI": AristippusNftABI,
        "minABICombiner": AristippusNftCombiner,
        "minABIActions": AristippusNftActions,
        "contractAddress": process.env.REACT_APP_ARISTIPPUS_NFT,
        "contractAddressCombiner": process.env.REACT_APP_ARISTIPPUS_NFT_COMBINER,
        "title": "META ARISTIPPUS",
        "slug": "/meta-aristippus/yellow",
        "baseUrl": "meta-aristippus",
        "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/codex',
        "mainPage": <NFTokenPages.AristippusPage />,
        "collectionPage": <NFTokenPages.AristippusCollectionPage />,
        "detailPage": <NFTokenPages.AristippusDetailPage />,
        "hidden": false
    },
    NFTMetaWisdom: {
        "minABI": AristippusWisdomNftABI,
        "minABICombiner": AristippusNftCombiner,
        "minABIActions": AristippusNftActions,
        "contractAddress": process.env.REACT_APP_ARISTIPPUS_WISDOM,
        "contractAddressCombiner": process.env.REACT_APP_ARISTIPPUS_WISDOM_COMBINER,
        "title": "META WISDOM",
        "slug": "/meta-wisdom/green",
        "baseUrl": "meta-wisdom",
        "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/wisdom',
        "mainPage": <NFTokenPages.WisdomPage />,
        "collectionPage": <NFTokenPages.WisdomCollectionPage />,
        "detailPage": <NFTokenPages.WisdomDetailPage />,
        "hidden": false
    },
    NFTMetaCodex2023: {
        "minABI": AristippusNftABI,
        "minABICombiner": AristippusNftCombiner,
        "minABIActions": AristippusNftActions,
        "contractAddress": process.env.REACT_APP_META_CODEX_2023,
        "contractAddressCombiner": process.env.REACT_APP_META_CODEX_2023_COMBINER,
        "title": "META CODEX 2023",
        "slug": "/meta-codex-2023/yellow",
        "baseUrl": "meta-codex-2023",
        "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/codex-2023',
        "mainPage": <NFTokenPages.Codex2023Page />,
        "collectionPage": <NFTokenPages.Codex2023CollectionPage />,
        "detailPage": <NFTokenPages.Codex2023DetailPage />,
        "hidden": false
    },
    NFTAlphabet: {
        "minABI": AlphabetABI,
        "minABICombiner": AristippusNftCombiner,
        "minABIActions": AristippusNftActions,
        "contractAddress": process.env.REACT_APP_ALPHABET,
        "contractAddressCombiner": process.env.REACT_APP_ALPHABET_COMBINER,
        "title": "ALPHABET",
        "slug": "/alphabet-token/yellow",
        "baseUrl": "alphabet-token",
        "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/alphabet',
        "mainPage": <NFTokenPages.AlphabetPage />,
        "collectionPage": <NFTokenPages.AlphabetCollectionPage />,
        "detailPage": <NFTokenPages.AlphabetDetailPage />,
        "hidden": true
    },
    NFTTransformator: {
        "minABI": TransformatorABI,
        "minABICombiner": AristippusNftCombiner,
        "minABIActions": AristippusNftActions,
        "contractAddress": process.env.REACT_APP_TRANSFORMATOR,
        "contractAddressCombiner": process.env.REACT_APP_TRANSFORMATOR_COMBINER,
        "title": "TRANSFORMATOR",
        "slug": "/transformator-token/yellow",
        "baseUrl": "transformator-token",
        "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/transformator',
        "mainPage": <NFTokenPages.TransformatorPage />,
        "collectionPage": <NFTokenPages.TransformatorCollectionPage />,
        "detailPage": <NFTokenPages.TransformatorDetailPage />,
        "hidden": true
    },
    NFTMetaSentry: {
        "minABI": MetasentryABI,
        "contractAddress": process.env.REACT_APP_METASENTRY,
        "title": "METASENTRY",
        "slug": "/metasentry/collection",
        "baseUrl": "metasentry",
        "mainPage": <NFTokenPages.MetaSentryPage />,
        "collectionPage": false,
        "detailPage": false,
        "hidden": false
    },
    NFTGOSHold: {
        "minABI": GOSHoldABI,
        "minABICombiner": AristippusNftCombiner,
        "minABIActions": AristippusNftActions,
        "contractAddress": process.env.REACT_APP_GOSHOLD,
        "contractAddressBonus": process.env.REACT_APP_GOSHOLD_BONUS,
        "title": "GOS HOLD",
        "slug": "/goshold-token/collection",
        "baseUrl": "goshold-token",
        "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/gos-hold',
        "mainPage": <NFTokenPages.GOSHoldPage />,
        "collectionPage": false,
        "detailPage": false,
        "hidden": false
    },
    NFTVolume: {
        "minABI": NFTVolumeABI,
        "minABICombiner": AristippusNftCombiner,
        "minABIActions": AristippusNftActions,
        "contractAddress": process.env.REACT_APP_NFTVolume,
        "contractAddressCombiner": process.env.REACT_APP_NFTVolume_COMBINER,
        "title": "NFT VOLUME",
        "slug": "/nft-volume/yellow",
        "baseUrl": "nft-volume",
        "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/volume-nft/tokens',
        "mainPage": <NFTokenPages.NFTVolumePage />,
        "collectionPage": <NFTokenPages.NFTVolumeCollectionPage />,
        "detailPage": <NFTokenPages.NFTVolumeDetailPage />,
        "hidden": false
    },
    NFTVolumeRainbow: {
        "minABI": NFTVolumeRainbowABI,
        "minABICombiner": AristippusNftCombiner,
        "minABIActions": AristippusNftActions,
        "contractAddress": process.env.REACT_APP_NFTVolume_RAINBOW,
        "contractAddressDetailer": process.env.REACT_APP_NFTVolume_RAINBOW_DETAILER,
        "contractAddressCombiner": process.env.REACT_APP_NFTVolume_RAINBOW_COMBINER,
        "title": "NFT VOLUME RAINBOW",
        "slug": "/nft-volume-rainbow/yellow",
        "baseUrl": "nft-volume-rainbow",
        "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/volume-nft/stands',
        "mainPage": <NFTokenPages.NFTVolumeRainbowPage />,
        "collectionPage": <NFTokenPages.NFTVolumeRainbowCollectionPage />,
        "detailPage": <NFTokenPages.NFTVolumeRainbowDetailPage />,
        "hidden": true
    }
}

export const expansionMinAbi = ExpansionABI;

export const expansionConfig = {
    "DAO": [
        {
            'address': process.env.REACT_APP_DAO_EXPANSION_1,
            'dayPercent': 0.05,
            'yearPercent': 18.25,
        },
        {
            'address': process.env.REACT_APP_DAO_EXPANSION_2,
            'dayPercent': 0.1,
            'yearPercent': 36.5,
        },
        {
            'address': process.env.REACT_APP_DAO_EXPANSION_3,
            'dayPercent': 0.15,
            'yearPercent': 54.75,
        },
    ],
    "DAOA": [
        {
            'address': process.env.REACT_APP_DAOA_EXPANSION_1,
            'dayPercent': 0.065,
            'yearPercent': 23.725,
        },
        {
            'address': process.env.REACT_APP_DAOA_EXPANSION_2,
            'dayPercent': 0.135,
            'yearPercent': 49.275,
        },
        {
            'address': process.env.REACT_APP_DAOA_EXPANSION_3,
            'dayPercent': 0.2,
            'yearPercent': 73,
        },
    ],
}

export const converterConfig = {
    'daoConverter': {
        'minABI': DaoConverterABI,
        'contractAddress': process.env.REACT_APP_DAO_CONVERTER,
        'slug': '/dao-converter',
    },

    'gicoDaoConvert': {
        'minABI': GicoDaoExchangeABI,
        'contractAddress': process.env.REACT_APP_GICO_DAO_EXCHANGE,
    },

}

// export const metasentry = {
//     "minABI": MetasentryABI,
//     "contractAddress": process.env.REACT_APP_METASENTRY,
//     "title": "METASENTRY",
//     "slug": "metasentry",
// }
