import NFTContainer from "@components/NFTokens/components/NFTContainer";
import AlphabetTokenCollection from "@components/NFTokens/tokens/Alphabet/AlphabetTokenCollection";
import AlphabetProvider from "@components/NFTokens/contexts/AlphabetContext";
import React from "react";


const AlphabetCollectionPage = () => (
    <NFTContainer>
        <AlphabetProvider>
            <AlphabetTokenCollection />
        </AlphabetProvider>
    </NFTContainer>
);

export default AlphabetCollectionPage;