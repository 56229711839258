import React, { useState, useContext } from "react";
import { AristippusContext } from "@components/NFTokens/contexts/AristippusContext";
import Swal from "sweetalert2";
import { SpinnerWhite } from '@components/spinner';
import { converterConfig } from "@config/CryptoConfig"
import { useNavigate } from "react-router-dom";
import CollectGico from "@components/NFTokens/actions/CollectGico";

const Collect = ({ data }) => {

    const navigate = useNavigate();

    const { wallet, getTokenDetailInformation, canReceive } = useContext(AristippusContext);
    const [loading, setLoading] = useState(false);

    const collect = (tokenId) => {

        if (!canReceive) {
            Swal.fire({
                icon: 'error',
                title: 'Action is not available!',
                text: 'You did not exchange resources from the special transaction account for GR DAO Coin.',
                confirmButtonText: 'More details',
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    return navigate(converterConfig.daoConverter.slug);
                }
            });

            return false;
        }

        setLoading(true);

        CollectGico('NFTMetaAristippus', tokenId, wallet)
            .then((data) => {
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Your request has been successfully submitted'
                })
                getTokenDetailInformation();
                setLoading(false);
            })
            .catch((e) => {
                if (e) {
                    Swal.fire('Error', `Request failed: ${e}`, 'error');
                }
                setLoading(false);
            })
    }

    return (
        <>
            {!loading ?
                <div
                    // onClick={() => collect(data.tId)} 
                    onClick={() => data.coin > 0 ? collect(data.tId) : undefined}
                    className={data.coin > 0 ? "maDetailContent__btn" : "maDetailContent__btn maDetailContent__btn-disabled"}>
                    collect
                </div>
                :
                <div
                    className={data.coin > 0 ? "maDetailContent__btn" : "maDetailContent__btn maDetailContent__btn-disabled"}>
                    <SpinnerWhite />
                </div>

            }
        </>
    )
}

export default Collect;
