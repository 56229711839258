export const Romanize = (num) => {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
            "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
            "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

export const countDecimals = (value) => {
    if(Math.floor(value) === value) return 0;
    const numberParts = value.toString().split(".");
    if(numberParts[1]){
        return value.toString().split(".")[1].length || 0; 
    }

    return 0;
    
}

export const countNumberLength = (value) => {
    if(Math.floor(value) === value) return 0;
    const numberParts = value.toString().split(".");
    if(numberParts[0]){
        return value.toString().split(".")[0].length || 0; 
    }

    return 0;
    
}