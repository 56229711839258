
import React, { useContext } from "react";
import { TransformatorContext } from "@components/NFTokens/contexts/TransformatorContext";
import { useParams } from "react-router-dom";
import NftCards from "@components/NFTokens/tokens/Transformator/components/NftCards";
import '@components/NFTokens/tokens/Transformator/styles/transformator.scss';
import TopColorSwitch from "@components/NFTokens/tokens/Transformator/components/TopColorSwitch";
import BottomColorSwitch from "@components/NFTokens/tokens/Transformator/components/BottomColorSwitch";
import Loading from "@components/NFTokens/components/Loading";


const TransformatorToken = () => {

    const { color = 'yellow' } = useParams();
    const { loaded, nftColorData, totalByColor } = useContext(TransformatorContext);

    if (!loaded) return <Loading />;

    return (
        <div className="transformator__main">
            <TopColorSwitch />
            <NftCards />
            <BottomColorSwitch />
        </div>
    )

}

export default TransformatorToken;