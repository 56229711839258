import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AppNavigation from "@navigation/AppNavigation";
import AuthNavigation from "@navigation/AuthNavigation";

import { useLocation } from 'react-router-dom';

const Navigator = () => {
    
    const location = useLocation();
    const wallets = useSelector(state => state.walletReducer.wallets);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [location]);
    

    return(
        <>
            {wallets.length > 0 ?
                <AppNavigation />
            :
                <AuthNavigation />
            }
        </>
    )

}

export default Navigator;