import React, { useContext } from "react";
import { AristippusContext } from "@components/NFTokens/contexts/AristippusContext";
import { useParams } from "react-router-dom";
import { Romanize } from "@utils/Number";
import CollectionBlock from "@components/NFTokens/tokens/Aristippus/components/CollectionBlock";
import { titleData } from "@config/AristippusNftConfig";

import Loading from '@components/NFTokens/components/Loading';
import NotFound from '@components/NFTokens/components/NotFound';
import BottomColorSwitch from "@components/NFTokens/tokens/Aristippus/components/BottomColorSwitch";
import TopColorSwitch from "@components/NFTokens/tokens/Aristippus/components/TopColorSwitch";

const AristippusTokenCollection = () => {

    let { color = 'yellow', guidline } = useParams();
    const { nftCollectionData, loadedCollection } = useContext(AristippusContext);
    const count = nftCollectionData.length;

    if (!loadedCollection) return <Loading />;
    if (!count) return <NotFound />;

    return (
        <div className="aristippus_container">
            <TopColorSwitch />
            <div className={`ma__collection ma__collectionPage ma__collection-${color}`}>

                <div className="maDetail__top">
                    <div className="maDetailTop__titleWrap">
                        <div className="maDetailTop__title">{Romanize(guidline)} - {titleData[guidline]['title']}</div>
                    </div>
                </div>
                <div className="collectionWrap">
                    <div className="maCollection__content1">
                        {[...Array(count)].map((x, i) =>
                            <CollectionBlock key={i} index={i} />
                        )}
                    </div>
                </div>
            </div>
            <BottomColorSwitch />
        </div>
    )

}

export default AristippusTokenCollection;