import React, { useEffect, useState, useContext } from 'react';
import GicoDaoConvert from "@components/wallet/coins/gico_to_dao/GicoDaoConvert";
import DaoConvert from '@components/wallet/coins/gico_to_dao/DaoConvert';
import { CoinContext } from '@providers/CoinContext';
import DaoDaoaConvertation from '@components/wallet/convertations/DaoDaoaConvertation/DaoDaoaConvertation';
import GetBalance from "@components/wallet/actions/nft_collection/GetBalance";
import { useSelector } from 'react-redux';

const DaoConverter = () => {

    const { getBalance } = useContext(CoinContext);
    const [balance, setBalance] = useState([]);
    const [ambassador, setAmbassador] = useState(false);

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);

    useEffect(() => {
        getBalance()
            .then((data) => setBalance(data))
            .catch((e) => console.log(e));

        GetBalance(process.env.REACT_APP_AMBASSADOR_COLLECTION, wallet)
            .then((res) => { (res > 0) && setAmbassador(true) })
            .catch((err) => console.log(err))

    }, []);

    return (
        <>
            {balance.dao > 0 && balance.state == 0 ?
                <DaoConvert />
                :
                <>
                    <GicoDaoConvert />
                    {ambassador && <DaoDaoaConvertation />}
                </>
            }
        </>
    )
}

export default DaoConverter;
