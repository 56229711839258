import Swal from "sweetalert2"

export const passwordInput = async (repeat = false, pass = '') => {
    return await Swal.fire({
        icon: 'warning',
        title: !repeat ? 'Create new password' : 'Confirm password',
        text: 'We will not store your password and therefore is unable to recover it once it is lost',
        input: 'password',
        inputAttributes: {
            autocapitalize: 'off',
            placeholder: 'Password',
        },
        showCancelButton: true,
        confirmButtonText: 'Continue',
        showLoaderOnConfirm: true,
        backdrop: true,
        preConfirm: (password) => {
            if(password.length <= 0){
                Swal.showValidationMessage(`This field is required`)
                return false;
            }
            if(password.length < 8){
                Swal.showValidationMessage(`Minimal password length is 8 characters`)
                return false;
            }
            if(pass !== ''){
                if(pass !== password){
                    Swal.showValidationMessage(`Passwords are not the same`)
                    return false;
                }
            }
        },
        allowOutsideClick: false
        // allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            if(result.value){
                return result.value;
            }
        }
        return false;
    })
}