import React from "react";
import { Link, useParams } from "react-router-dom";
import { NFTokens } from "@config/CryptoConfig";

const BottomColorSwitch = () => {

    let { color } = useParams();

    return (
        <>
            {/* <div className="moreTokens">
                <p className="moreTokens__text">To get more UTT, go to the page.</p>
                <Link to="/en/alphabet/yellow" className="transformatorMenu__link transformatorMenu__link-yellow moreTokens__btn">
                    <span className="transformatorMenu__link-cyrcle"></span>interaction alphabet
                </Link>
            </div> */}
        </>
    )
}

export default BottomColorSwitch;