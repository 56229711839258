import { passwordCheck } from "@utils/PasswordCheck";
import { FeeDialog } from "@utils/FeeDialog";
// import { AmountInput } from "@utils/AmountInput";
import { IntermediatePreloader } from "@utils/IntermediatePreloader";
import { GetGasAmount } from "@components/wallet/actions/utils/GetGasAmount";
import Swal from "sweetalert2"

import Web3 from "web3";
// import { ethers } from "ethers";
import { rpcLink } from "@config/CryptoConfig";
import { converterConfig } from "@config/CryptoConfig";

const ConvertDao = async (walletData, address) => {

    const contractAddress = converterConfig['daoConverter']['contractAddress'];
    const converterABI = converterConfig['daoConverter']['minABI']

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(converterABI, contractAddress);

    return new Promise(async (resolve, reject) => {

        passwordCheck().then((password) => {
            if(!password) return reject(4);

            IntermediatePreloader().then(() => {
                try {
                    const wallet = web3.eth.accounts.decrypt(JSON.stringify(walletData), password, true);
                    if(!wallet) {
                        return reject(5);
                    }

                    web3.eth.getGasPrice((e, r) => {

                        const deploy = async () => {

                            var count = await web3.eth.getTransactionCount(address, 'latest').catch((e) => {
                                return reject(6);
                            });

                            try {
                                const encoded = contract.methods.convert().encodeABI();
                                const gasAmount = await GetGasAmount(address, contractAddress, encoded);

                                var rawTransaction = {
                                    to: contractAddress,
                                    from: address,
                                    data: encoded,
                                    gas: gasAmount,
                                    gasPrice: r,
                                    chainId: process.env.REACT_APP_RPC_CHAIN,
                                    value: 0,
                                    nonce: "0x" + count.toString(16),
                                }

                                var tx = await web3.eth.accounts.signTransaction(rawTransaction, wallet.privateKey). catch((e) => {
                                    return reject(7);
                                });

                                var receipt = await web3.eth.sendSignedTransaction(tx.rawTransaction).catch((e) => {
                                    return reject(8);
                                });

                                if(receipt && receipt.status) {
                                    Swal.fire({
                                        icon: 'success',
                                        timer: 3000,
                                        showCloseButton: false,
                                        showConfirmButton: false,
                                        showDenyButton: false,
                                    });

                                    return resolve(receipt);
                                } else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: 'Something went wrong. Try again later.',
                                        timer: 3000,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        showDenyButton: false,
                                    });

                                    return reject(9);
                                }
                            } catch(e) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: 'Something went wrong. Try again later.',
                                    timer: 3000,
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    showDenyButton: false,
                                });

                                return reject(61);
                            }
                        }

                        deploy();
                    });
                } catch(e) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Something went wrong. Try again later.',
                        timer: 3000,
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                    });

                    return reject(6);
                }
            }).catch((e) => {
                return reject(101);
            });
        });
    });
}

export default ConvertDao;