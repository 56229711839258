import React, { useContext } from "react"
import { Link, useParams } from "react-router-dom";
import { WisdomContext } from "@components/NFTokens/contexts/WisdomContext";
import { Romanize } from "@utils/Number";
import { NFTokens } from "@config/CryptoConfig";
import { BackgroundImage } from 'react-image-and-background-image-fade';

const NftCard = ({ guidline }) => {

    const { color = 'green' } = useParams();

    const { nftColorGuidelineData } = useContext(WisdomContext);

    const image = 'MAW-' + color[0].toUpperCase() + '-' + Romanize(guidline + 1) + '.png';
    const active = nftColorGuidelineData[guidline] > 0 ? true : false;
    const count = nftColorGuidelineData[guidline];

    return (
        <>
            {active ?
                <div className="cardWrapper">
                    <Link to={`/${NFTokens.NFTMetaWisdom.baseUrl}/${color}/collection/${guidline + 1}`} className="maCollectionContent__link-active">
                        <div className={`maCollectionContent__link ${!active ? "maCollectionContent__link-disabled" : ""}`}>
                            <BackgroundImage
                                useChild
                                width='100%'
                                lazyLoad
                                src={require(`@images/aristippus_wisdom/collection/${color}/${image}`)}
                            >
                                <div className="maCollectionContentLink__bg"></div>
                            </BackgroundImage>
                            <div className="maMainMenuLink__hover">
                                <div className="maMainMenuLinkHover__arrow">
                                    →
                                </div>
                                <div className="maMainMenuLinkHover__text">
                                    Click to view collection
                                </div>
                            </div>
                            <div className="maMainMenuLink__number">
                                <span className="maMainMenuLink__number-red">{count}</span>
                            </div>
                        </div>
                    </Link>
                </div>
                :

                <div className="cardWrapper">
                    <div className={`maCollectionContent__link ${!active ? "maCollectionContent__link-disabled" : ""}`}>
                        <BackgroundImage
                            useChild
                            width='100%'
                            lazyLoad
                            src={require(`@images/aristippus_wisdom/collection/${color}/${image}`)}
                        >
                            <div className="maCollectionContentLink__bg"></div>
                        </BackgroundImage>
                        <div className="maMainMenuLink__hover">
                            <div className="maMainMenuLinkHover__arrow">
                                →
                            </div>
                            <div className="maMainMenuLinkHover__text">
                                Click to view collection
                            </div>
                        </div>
                        <div className="maMainMenuLink__number">
                            <span className="maMainMenuLink__number-red">{count}</span>
                        </div>

                    </div>
                </div>
            }
        </>

    )
}

export default NftCard;