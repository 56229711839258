import React, { createContext, useState } from 'react'

import GetBalance from "@components/wallet/actions/nft_collection/GetBalance";
import GetDetailByIdx from "@components/wallet/actions/nft_collection/GetDetailByIdx";
import { nftCollectionsSlugs } from "@config/CryptoConfig";
import { useSelector } from 'react-redux';

import { useNavigate } from "react-router-dom";

export const NftCollectionContext = createContext();

const NftCollectionProvider = ({children}) => {

    const [count, setCount] = useState(0);
    const [tokenData, setTokenData] = useState([]);


    const navigate = useNavigate();

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);

    const getTokenData = (slug) => {

        const address = nftCollectionsSlugs[slug];
    
        GetBalance(address, wallet).then((balance) => {
            setCount(balance);
            if(balance > 0){
                for(let i = 0; i < balance; i++){
                    GetDetailByIdx(address, wallet, 0).then((result) => {
                        // //console.log(result);
                        setTokenData([...tokenData, result]);
                    }).catch((e) => {
                        //console.log(e);
                    })
                }
            } else {
                navigate('/');
            }
        }).catch((e) => {
            //console.log(e);
        })

    }
   
    return (
        <NftCollectionContext.Provider value={{
            getTokenData,
            tokenData,
            count
        }}>
            {children}
        </NftCollectionContext.Provider>
    )
}

export default NftCollectionProvider;
