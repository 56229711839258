import React from "react";
import NftCard from "./NftCard";
import { useParams } from "react-router-dom";

const NftCards = () => {

    const { color = 'yellow' } = useParams();

    return (
        <div className={'mainTransformatorCards mainCards-' + color} >
            {[...Array(40)].map((x, i) => {
                let d = Math.floor(i / 4);
                let m = i % 4;
                let idx = m * 10 + d;
                return(
                    <NftCard key={idx} guidline={idx} />
                )
            })}
        </div>
    );
}

export default NftCards;