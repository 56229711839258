import React from "react";
import RestoreUsingPrivateKey from "@components/auth/RestoreByPrivateKey";
import RestoreByKeyFile from "@components/auth/RestoreByKeyFile";
import RestoreByMnemonicPhrase from "@components/auth/RestoreByMnemonicPhrase";
import CreateWallet from "@components/auth/CreateWallet";
import CreateWalletCommingSoon from "@components/auth/CreateWalletCommingSoon";

import TransferWallet from "@components/auth/TransferWallet";
import TransferWalletBackend from "@components/auth/TransferWalletBackend";

import CardSimpleHover from "@components/cards/CardSimpleHover";
import { useSearchParams } from "react-router-dom";
import './index.scss';

const AuthPageContent = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const elements = (
        <>
            <RestoreByMnemonicPhrase />
            <RestoreByKeyFile />
            <RestoreUsingPrivateKey />
        </>
    )

    return(
        <div className="card__wrap">
            <div className="card__item">
                {process.env.REACT_APP_DEV == "1" ?
                    <CreateWallet />
                :
                    <CreateWalletCommingSoon />
                }
            </div>

            {process.env.REACT_APP_DEV == "1" &&
                <div className="card__item">
                    <CardSimpleHover 
                        title="Restore wallet"
                        subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                        elements={elements}
                        />
                </div>
            }

            <div className="card__item">
                {process.env.REACT_APP_DEV == "1" ?
                    <TransferWallet />
                :
                    <TransferWalletBackend />
                }
            </div>

            <div className="card__item"></div>

        </div>
    )
}

export default AuthPageContent;