import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Web3 from "web3";
import bip39 from "bip39js";
import { ethers, getDefaultProvider, Wallet } from "ethers";

import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import { saveAs } from "file-saver";

import MnemonicCheck from "@components/auth/create/MnemonicCheck";
import { passwordInput } from "@utils/PasswordInput";
import CardSimple from "@components/cards/CardSimple";
// import ReactHtmlParser from 'react-html-parser';


import { createCipher, createDecipher, createCipheriv, createDecipheriv } from 'crypto-browserify';

// function Hex2Bin(n){if(!checkHex(n))return 0;return parseInt(n,16).toString(2)}

// var cryp = (typeof global === 'undefined') ? require('crypto-browserify') : require('crypto');

const TransferWalletBackend = () => {

    const dispatch = useDispatch();

    const transfer = async () => {

        Swal.fire({
            icon: 'warning',
            title: 'Access Wallet with Encrypted Private Key',
            text: 'Enter your encrypted private key',
            input: 'password',
            inputAttributes: {
                autocapitalize: 'off',
            },
            showCancelButton: true,
            confirmButtonText: 'Continue',
            preConfirm: (password) => {
                if(password.length <= 0){
                    Swal.showValidationMessage(`This field is required`)
                    return false;
                }
            },
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {

                const hash = result.value;

                passwordInput(false).then((password) => {
                    if(!password) return false;
                    passwordInput(true, password).then((password2) => {
                        if(!password2) return false;

                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ hash, password})
                        };
                        
                        fetch(process.env.REACT_APP_PHP_API_URL, requestOptions)
                            .then(response => response.json())
                            .then(data => {
                                //console.log(data)
                                if(data.version == 3){
                                    dispatch({type: 'IMPORT_WALLET', payload: JSON.stringify(data)});
                                }
                            }).catch((e) => {
                                //console.log(e);

                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: 'Please try again later',
                                    // timer: 3000,
                                    showCloseButton: false,
                                    showConfirmButton: false,
                                    showDenyButton: false,
                                    showCancelButton: true
                                })
                            });
                    }).catch((e) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Please try again later',
                            // timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false,
                            showDenyButton: false,
                            showCancelButton: true
                        })
                    });
                }).catch((e) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Please try again later',
                        // timer: 3000,
                        showCloseButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        showCancelButton: true
                    })
                })
            }
        })
    }

    return (
        <CardSimple 
            title="Transfer wallet"
            subtitle={"Access your wallet with encrypted private key.\n\nThis information is sensitive. Do not share your encrypted private key anywhere else."}
            importantFlag={true}
            onClick={() => transfer()}
        />
    )
}

export default TransferWalletBackend;