import React, { useContext, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Navbar from "@components/navbars/Navbar";
import { nftCollections } from "@config/CryptoConfig";
import NftCollectionsWrapper from "@components/wrappers/NftCollectionsWrapper";
import { NftCollectionContext } from "@providers/NftCollectionContext";
import "@components/wallet/nft_collection/detail/style/style.scss";
import { BackgroundImage } from 'react-image-and-background-image-fade';

const DefaultDetail = ({ address }) => {

    const { slug } = useParams();
    const { tokenData } = useContext(NftCollectionContext);
    const { title, image, detailBackgroundImage } = nftCollections[address];

    return (
        <NftCollectionsWrapper slug={slug}>
            <Navbar />
            <div className="nft__content">
                <Link to="/" className="back__btn">← back</Link>
                <div className="nft__detailWrap">
                    {
                        tokenData.length > 0 ? <>
                            {
                                detailBackgroundImage ? <BackgroundImage
                                    useChild
                                    width='100%'
                                    lazyLoad
                                    className="tests"
                                    src={detailBackgroundImage}
                                >
                                    <div className="nft__detail">
                                        <div className="title">{title}</div>
                                        <br />
                                        {
                                            tokenData.map((data, index) =>
                                                <div key={index} className="nftCollectionsDetail">
                                                    <div className="nftCollectionsDetail__imageWrap">
                                                        <img src={image} className="nftCollectionsDetail__image" />
                                                        <div className="nftCollectionsDetail__tokenId">Token ID: {data.tokenId.substring(6, 12)}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </BackgroundImage>
                                    : <div className="nft__detail">
                                        <div className="title">{title}</div>
                                        <br />
                                        {
                                            tokenData.map((data, index) =>
                                                <div key={index} className="nftCollectionsDetail">
                                                    <div className="nftCollectionsDetail__imageWrap">
                                                        <img src={image} className="nftCollectionsDetail__image" />
                                                        <div className="nftCollectionsDetail__tokenId">Token ID: {data.tokenId.substring(6, 12)}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                            }
                        </>
                            : <>
                            </>
                    }
                </div>
            </div>
        </NftCollectionsWrapper>
    )
}

export default DefaultDetail;