export const sumArrays = (validResults) => {
    const result = [];
    validResults.map((element) => {
        element.map((count, index) => {
            result[index] ? result[index] += parseInt(count) : result[index] = parseInt(count);
        })
    })
    
    //console.log(result);
    return result;
}

export const mergeArrays = (validResults) => {
    const result = [];
    validResults.map((element) => {
        element.map((count, index) => {
            result.push(count);
        })
    })
    
    //console.log(result);
    return result;
}