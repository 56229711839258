import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { AlphabetContext } from "@components/NFTokens/contexts/AlphabetContext";
import { NFTokens } from "@config/CryptoConfig";
import { BackgroundImage } from 'react-image-and-background-image-fade';

const NftCard = ({ guidline }) => {

    const { color = 'yellow' } = useParams();
    const { nftColorGuidelineData } = useContext(AlphabetContext);

    const image = (guidline + 1) + '.png';
    const active = nftColorGuidelineData[guidline] > 0 ? true : false;
    const count = nftColorGuidelineData[guidline];

    return (
        <div className={`maCollectionContent__link ${active && "maCollectionContent__link-active"}`}>
            <BackgroundImage
                width='100%'
                height='100%'
                lazyLoad
                src={require(`@images/alphabet/collection/${color}/${image}`)}
                className="maCollectionContentLink__bg"
                style={{ backgroundSize: "cover" }}
            ></BackgroundImage>

            {active &&
                <Link to={`/${NFTokens.NFTAlphabet.baseUrl}/${color}${active ? `/collection/${guidline + 1}` : ``}`}>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            <img src={require(`@images/alphabet/arrow-right.png`)} />
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Open
                        </div>
                    </div>
                </Link>
            }

            <div className="maMainMenuLink__number">
                <span className="maMainMenuLink__number-red">{count}</span>
            </div>
        </div>
    )
}

export default NftCard;