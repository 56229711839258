import React, { useContext } from "react";
import { useNavigate } from "react-router-dom"; 
import NumberFormat from "@utils/NumberFormat";
import { coinsConfig } from "@config/CryptoConfig";
import { CoinContext } from "../../../providers/CoinContext";

const Gico = () => {

    const coin = 'GICO';
    const coinProgress = 'GICO_PROGRESS';

    const { transactions, send, balance } = useContext(CoinContext)
    const { title } = coinsConfig[coin];
    const navigate = useNavigate();

    return (
        <div className="address-card__wrap">

            <div className={`address-card address-card__video address-card__${coin}`}>

                <div className="address-card__flex">
                    <div className="address-card__title">{title}</div>
                </div>
                <div className="address-card__flex2">
                    <div className="address-card__flex">
                        {/* <div className="address-card__balance">{NumberFormat(balance[coin], 8, '.', '')}</div> */}
                        <div className="address-card__label">General account</div>
                        <div className="address-card__balance">{balance[coin]}</div>
                    </div>
                    <div className="address-card__flex">
                        <div className="address-card__label">Progress account</div>
                        <div className="address-card__balance">{balance[coinProgress]}</div>
                    </div>
                </div>

                <div className="address-card__mt address-card__buttons">
                    <div className="address-card__buttonWrap">
                        <button className="address-card__button" onClick={() => send(coin)}>Send</button>
                    </div>
                    <div className="address-card__buttonWrap">
                        <button className="address-card__button" onClick={() => navigate(`transactions/${coin}`)}>Transactions ({transactions[coin] ? transactions[coin].length : 0})</button>
                    </div>           
                </div>
            </div>

        </div>
    )

}

export default Gico;
