import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import TokenCard from '@components/NFTokens/tokens/Volume/components/TokenCard';
import { NFTVolumeContext } from '@components/NFTokens/contexts/NFTVolumeContext';
import styles from '@components/NFTokens/tokens/Volume/styles/TokenList.module.scss'


const TokenList = () => {

    const { currentColor } = useContext(NFTVolumeContext);
    const tokens = useSelector(state => state.nftVolumeReducer.nftCollectionData);

    return (
        <div className={styles.list_wrapper}>
            {
                (tokens.length > 0)
                    ? tokens.map((token, index) => <TokenCard key={`${currentColor}_${index}`} token={token} />)
                    : <p className={styles.list_empty}>You don’t have any NFT Structure Volume of this color</p>
            }
        </div>
    );
}

export default TokenList;