import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { NFTokens } from "@config/CryptoConfig";
import { NFTVolumeContext, colorEnum } from '@components/NFTokens/contexts/NFTVolumeContext';
import styles from '@components/NFTokens/tokens/Volume/styles/TopColorSwitch.module.scss';
import { useContext } from "react";

const linkColors = {
    yellow: 'linear-gradient(to bottom right, #ffe400, #8f8001)',
    red: 'linear-gradient(to bottom right, #ff0000, #a11313)',
    green: 'linear-gradient(to bottom right, #30ff00, #197802)',
    purple: 'linear-gradient(to bottom right, #cc00ff, #7f029e)',
    blue: 'linear-gradient(to bottom right, #0090ff, #005fa8)'
};

const TopColorSwitch = () => {

    let { currentColor } = useContext(NFTVolumeContext);
    const eachColorAmount = useSelector(state => state.nftVolumeReducer.nftColorData);

    return (
        <div className={styles.switch_wrapper}>
            {
                Object.entries(colorEnum).map((color) => (
                    <Link style={{ backgroundImage: linkColors[color[0]] }} className={`${styles.switch_link} ${(currentColor === color[0]) && styles.active}`} to={`/${NFTokens.NFTVolume.baseUrl}/${color[0]}`} key={`volume_${color[0]}`}>
                        <p className={styles.link_text}>{color[0]} ({eachColorAmount[color[1]] || 0})</p>
                    </Link>
                ))
            }
        </div>
    );
}

export default TopColorSwitch;