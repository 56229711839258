import React, { useContext } from "react";
import { TransformatorContext } from "@components/NFTokens/contexts/TransformatorContext";
import { useParams, Link, Navigate } from "react-router-dom";
import NumberFormat, { FarmedFormat } from "@utils/NumberFormat";
import { Romanize } from "@utils/Number";
import { BackgroundImage } from 'react-image-and-background-image-fade';

import Farm from "../actions/Farm";
import Collect from "../actions/Collect";
import Send from "../actions/Send";
import { NFTokens } from "@config/CryptoConfig";
import NotFound from "@components/NFTokens/components/NotFound";

const CollectionBlock = ({ index }) => {

    const { color = 'yellow', guidline } = useParams();
    const { nftCollectionData, loadedCollection, daoMultiplier } = useContext(TransformatorContext);

    if (!loadedCollection) return <NotFound />;

    const data = nftCollectionData[index];

    const image = (guidline) + '.png';

    const isNitro = parseFloat(data.nitro) > 0 ? true : false;
    const isCombo = parseFloat(data.bns) > 1000 ? true : false;
    const isAdvanced = parseFloat(data.akt) > 0 ? true : false;
    const hasMultiplyer = isNitro || isCombo || isAdvanced ? true : false;
    const totalMultiplyer = (parseFloat(data.bns) + parseFloat(data.nitro) + parseFloat(data.akt)) * daoMultiplier;

    return (
        <>
            <div className="cardFull__left">
                <BackgroundImage
                    src={require(`@images/transformator/collection/${color}/${image}`)}
                    className="cardFull__imgWrap"
                >
                    <div className="cardFull__nr">
                        {data.Number}
                    </div>
                </BackgroundImage>

            </div>
            <div className="cardFull__right">
                <div className="cardFull__info">
                    <div className="cardFullInfo__header">
                        <div className="cardFullInfoHeader__row">
                            <div className="cardFullInfoHeaderRow__label">
                                Number:
                            </div>
                            <div className="cardFullInfoHeaderRow__value">
                                {data.Number}
                            </div>
                        </div>
                        <div className="cardFullInfoHeader__row">
                            <div className="cardFullInfoHeaderRow__label">
                                Token ID:
                            </div>
                            <div className="cardFullInfoHeaderRow__value">
                                <span className="cardFullInfoHeaderRow__value-span">{data.tId}</span>
                            </div>
                        </div>
                    </div>
                    <div className="cardFullInfo__content">
                        <div className="cardFullInfoContent__title">
                            {data.lastFarming > 0 ?
                                <>
                                    FARMED <span className="cardFullInfoContent__title-span">{FarmedFormat(data.coin, 8)}</span> GRDA
                                </>

                                :
                                <>
                                    START FARM TO GET <span className="cardFullInfoContent__title-span">+0.001 GRDA</span> PER DAY
                                </>

                            }
                        </div>
                        <div className="cardFullInfoContent__btnWrap">
                            {data.lastFarming > 0 ?
                                <>
                                    <Collect data={data} />
                                </>
                                :
                                <>
                                    <Send data={data} />
                                </>
                            }

                            <Farm data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CollectionBlock;