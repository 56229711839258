import { doubleDigitFormat } from '@helpers/digitHelper';
import React, { useContext } from 'react';
import Send from '@components/NFTokens/components/Send';
import { CoinContext } from '@components/NFTokens/contexts/CoinContext';
import { NFTVolumeContext } from '@components/NFTokens/contexts/NFTVolumeContext';
import styles from '@components/NFTokens/tokens/Volume/styles/VolumeCard.module.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


const TokenCard = ({ token }) => {

    const coinContext = useContext(CoinContext);
    const nftVolumeContext = useContext(NFTVolumeContext);
    const { transferFee } = useSelector(state => state.nftVolumeReducer);

    const { currentColor } = nftVolumeContext;

    return (
        <div className={styles.card_container}>
            <img className={styles.card_image} loading='lazy' src={require(`@images/NFTVolume/colors/${currentColor}/${doubleDigitFormat(token.Guideline)}.webp`)} />
            <div className={styles.content_wrapper}>
                <div className={styles.info_wrapper}>
                    <div className={styles.info_block}>
                        <span>Number:</span>
                        <span className={styles.ellipsis_wrapper}>
                            <hr className={styles.ellipsis} />
                        </span>
                        <span>{token.Number}</span>
                    </div>
                    <hr className={styles.divider} />
                    <div className={styles.info_block}>
                        <span>Token ID:</span>
                        <span className={styles.ellipsis_wrapper}>
                            <hr className={styles.ellipsis} />
                        </span>
                        <span>{token.tId}</span>
                    </div>
                </div>
                <div className={styles.wrapper_divider} />
                <div className={styles.data_wrapper}>
                    <p>
                        <b>NFT Structure Volume</b> can be transferred to another GOS Wallet owner or placed in a {/*<Link className={styles.data_link} to='/nft-volume-rainbow'>Volume Stand</Link>*/}Volume Stand
                    </p>
                    <div className={styles.button_wrapper}>
                        <Send token={token} coinContext={{ ...coinContext, transferFee: transferFee / (10 ** 8) }} NFTokenContext={nftVolumeContext} TransferButton={
                            <button className={styles.cardButton}>Transfer</button>
                        } />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TokenCard;