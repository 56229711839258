import React from "react";
import NFTContainer from "@components/NFTokens/components/NFTContainer";
import Codex2023TokenDetail from "@components/NFTokens/tokens/Codex2023/Codex2023TokenDetail";
import AristippusCodex2023Provider from "@components/NFTokens/contexts/AristippusCodex2023Context";


const Codex2023DetailPage = () => (
    <NFTContainer>
        <AristippusCodex2023Provider>
            <Codex2023TokenDetail />
        </AristippusCodex2023Provider>
    </NFTContainer>
);

export default Codex2023DetailPage;