import NFTContainer from "@components/NFTokens/components/NFTContainer";
import AristippusTokenDetail from "@components/NFTokens/tokens/Aristippus/AristippusTokenDetail";
import AristippusProvider from "@components/NFTokens/contexts/AristippusContext";
import React from "react";

const AristippusDetailPage = () => (
    <NFTContainer>
        <AristippusProvider>
            <AristippusTokenDetail />
        </AristippusProvider>
    </NFTContainer>
);

export default AristippusDetailPage;