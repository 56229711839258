import { CoinContext } from "@providers/CoinContext";

import DAOIcon from '@images/dao/dao-coin.png';
import DAOAIcon from '@images/dao/dao-adv-coin.png';

import { useContext, useEffect, useState } from "react";
import { setDaoDaoaBalance, getContractCourse, setWalletData, setFee } from "@components/wallet/convertations/DaoDaoaConvertation/actions/daoDaoaActions";
import { useSelector } from "react-redux";

const useDaoDaoaConvertation = () => {

    const { balance } = useContext(CoinContext);

    const [error, setError] = useState('');
    const [daoDaoaProps, setDaoDaoaProps] = useState({
        wallet_data: null,
        coin: {
            from: {
                name: 'DAO',
                icon: DAOIcon,
                color: '#002d00'
            },
            to: {
                name: 'DAO ADVANCED',
                icon: DAOAIcon,
                color: '#b40000'
            }
        },
        balance: {
            from: 0,
            to: 0
        },
        course: {
            from: 0,
            to: 0
        },
        input: {
            from: '',
            to: ''
        },
        fee: {
            name: 'GOS Coin',
            value: 0,
            balance: 0
        }
    });

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet_data = JSON.parse(wallets[0]);

    useEffect(() => {
        setWalletData(setDaoDaoaProps, wallet_data);
        setDaoDaoaBalance(balance, setDaoDaoaProps, setError);
        setFee(balance, setDaoDaoaProps);
        getContractCourse(setDaoDaoaProps, setError);

    }, [balance, wallets]);

    useEffect(() => {
        const key = (daoDaoaProps.coin.from.name === 'DAO') ? 'from' : 'to';

        setDaoDaoaProps((prevState) => ({
            ...prevState,
            fee: {
                ...prevState.fee,
                value: prevState.input[key]
            }
        }));
    }, [daoDaoaProps.input]);

    return { daoDaoaProps, setDaoDaoaProps, error, setError };
}

export default useDaoDaoaConvertation;