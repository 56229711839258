import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "@pages/home/HomePage";
import TransactionsPage from "@pages/home/TransactionsPage";
import ExpansionPage from "@pages/home/ExpansionPage";
import CollectionPage from "@pages/home/CollectionPage";
import DaoConverterPage from "@pages/home/DaoConverterPage";

import NFTRoutes from "@navigation/NFTRoutes";

import { converterConfig } from "@config/CryptoConfig";

const AppNavigation = () => (
    <Routes>
        <Route path="/">
            <Route index element={<HomePage />} />
            <Route path="transactions/:coin" element={<TransactionsPage />} />
            <Route path="expansion/:coin" element={<ExpansionPage />} />

            <Route exact path="nft_collection/:slug" element={<CollectionPage />} />
            <Route exact path={converterConfig.daoConverter.slug} element={<DaoConverterPage />} />

            {NFTRoutes.map((NFTRoute, index) => <React.Fragment key={`${NFTRoute.key}_${index}`}>{NFTRoute}</React.Fragment>)}
        </Route>
    </Routes>
)

export default AppNavigation;
