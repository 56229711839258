import React from 'react';
import Navigator from '@navigation/Navigator';

// function App() {
// 	return (
// 		<Navigator />
//   	);
// }


class App extends React.Component {
	render() {
		// document.title = 'GOS Wallet';
		return <Navigator />;
	}
  }


export default App;
