import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const MnemonicCheck = ({mnemonicShuffledArray, mnemonic, setSuccessCheck, successCheck}) => {

    const [mnemonicAnswer, setMnemonicAnswer] = useState([]);
    
    
    const setAnswer = (index) => {
        if(mnemonicAnswer.indexOf(index) !== -1){
            const temp = [...mnemonicAnswer];
            temp.splice(mnemonicAnswer.indexOf(index), 1);
            setMnemonicAnswer(temp);
        } else {
            setMnemonicAnswer([...mnemonicAnswer, index])
        }
    }

    useEffect(() => {
        if(mnemonicAnswer.length === 12){
            let checkMnemonicString = [];
            mnemonicAnswer.map((item) => {
                checkMnemonicString.push(mnemonicShuffledArray[item])
            })

            if(mnemonic === checkMnemonicString.join(' ')){
                setSuccessCheck(true);
            } else {
                Swal.showValidationMessage(`Вы не правильно ввели фразу`)
            }
            
        } else {
            setSuccessCheck(false);
        }
    }, [mnemonicAnswer])

    return (
        <>
            
            {successCheck ?
                <>
                    Отлично, вы правильно ввели всю фразу, можно продолжать! <br/><br/>Новый кошелек почти создан 🔥🚀
                </>
                :
                <>
                    Нажмите на слова в том порядке, в котором они были на прошлом шаге<br /><br />
                    <div className="mnemonicWords__wrap">
                        {mnemonicShuffledArray.map((item, index) => {
                            return (
                                <div 
                                    key={index} 
                                    onClick={() => setAnswer(index)} 
                                    className={"mnemonicWords__item mnemonicWords__item-pointer " + (mnemonicAnswer.indexOf(index) !== -1 ? 'mnemonicWords__item-disabled' : '')}
                                >
                                    {mnemonicAnswer.indexOf(index) !== -1 ? (mnemonicAnswer.indexOf(index) + 1) + '. ' : ''} {item}
                                </div>
                            )
                        })}
                    </div>
                </>
            }
        </>
    )
}

export default MnemonicCheck;