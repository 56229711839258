import { GetDataWithOffset } from "@components/NFTokens/utils/GetDataWithOffset";
import { mergeArrays } from '@utils/Arrays';

export const GetGuidelineColorItems = (contract, wallet, color, guidline, balance) => {
    return new Promise((resolve, reject) => {
        GetDataWithOffset(contract.methods.balanceOfColorGlItems('0x' + wallet.address, 0, color, guidline), balance)
            .then(mergeArrays)
            .then((result) => {
                return resolve(result);
            }).catch((e) => {
                return reject(e);
            })
    })
}