export const unfarmPrice = 1;
export const farmPrice = 3;
export const sendPrice = 1;
export const sendAktPrice = 3;

export const titleData = {
    "1": {
        "title": "",
        "descr": "",
    },
    "2": {
        "title": "",
        "descr": "",
    },
    "3": {
        "title": "",
        "descr": "",
    },
    "4": {
        "title": "",
        "descr": "",
    },
    "5": {
        "title": "",
        "descr": "",
    },
    "6": {
        "title": "",
        "descr": "",
    },
    "7": {
        "title": "",
        "descr": "",
    },
    "8": {
        "title": "",
        "descr": "",
    },
    "9": {
        "title": "",
        "descr": "",
    },
    "10": {
        "title": "",
        "descr": "",
    },
    "11": {
        "title": "",
        "descr": "",
    },
    "12": {
        "title": "",
        "descr": "",
    },
    "13": {
        "title": "",
        "descr": "",
    },
    "14": {
        "title": "",
        "descr": "",
    },
    "15": {
        "title": "",
        "descr": "",
    },
    "16": {
        "title": "",
        "descr": "",
    },
    "17": {
        "title": "",
        "descr": "",
    },
    "18": {
        "title": "",
        "descr": "",
    },
    "19": {
        "title": "",
        "descr": "",
    },
    "20": {
        "title": "",
        "descr": "",
    },
    "21": {
        "title": "",
        "descr": "",
    },
    "22": {
        "title": "",
        "descr": "",
    },
    "23": {
        "title": "",
        "descr": "",
    },
    "24": {
        "title": "",
        "descr": "",
    },
    "25": {
        "title": "",
        "descr": "",
    },
    "26": {
        "title": "",
        "descr": "",
    },
    "27": {
        "title": "",
        "descr": "",
    },
    "28": {
        "title": "",
        "descr": "",
    },
    "29": {
        "title": "",
        "descr": "",
    },
    "30": {
        "title": "",
        "descr": "",
    },
    "31": {
        "title": "",
        "descr": "",
    },
    "32": {
        "title": "",
        "descr": "",
    },
    "33": {
        "title": "",
        "descr": "",
    },
    "34": {
        "title": "",
        "descr": "",
    },
    "35": {
        "title": "",
        "descr": "",
    },
    "36": {
        "title": "",
        "descr": "",
    },
    "37": {
        "title": "",
        "descr": "",
    },
    "38": {
        "title": "",
        "descr": "",
    },
    "39": {
        "title": "",
        "descr": "",
    },
    "40": {
        "title": "",
        "descr": "",
    },
}