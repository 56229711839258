import React from "react";
import WisdomTokenCollection from "@components/NFTokens/tokens/Wisdom/WisdomTokenCollection";
import WisdomProvider from "@components/NFTokens/contexts/WisdomContext";
import NFTContainer from "@components/NFTokens/components/NFTContainer";

const WisdomCollectionPage = () => (
    <NFTContainer>
        <WisdomProvider>
            <WisdomTokenCollection />
        </WisdomProvider>
    </NFTContainer>
);

export default WisdomCollectionPage;