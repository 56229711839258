const defaultState = {
    balance: null,
    farmingFee: 3,
    nftColorData: [],
    nftColorGuidelineData: [],
    nftCollectionData: [],
};

export const aristippusWisdomReducer = (state = defaultState, action) => {
    switch (action.type) {
        
        case 'SET_WNFT_BALANCE':
            return {...state, balance: action.payload}
        
        case 'SET_WNFT_FARMING_FEE':
            return {...state, farmingFee: action.payload}

        case 'SET_WNFT_COLOR_DATA':
            return {...state, nftColorData: action.payload}

        case 'SET_WNFT_COLOR_GUIDELINE_DATA':
            return {...state, nftColorGuidelineData: action.payload}

        case 'SET_WNFT_COLLECTION_DATA':
            return {...state, nftCollectionData: action.payload}
            
        case 'WNFT_PURGE':
            return defaultState;

        default:
            return state;
    }
}