import React, { useContext } from "react";
import { AristippusContext } from "@components/NFTokens/contexts/AristippusContext";
import { Link, useParams } from "react-router-dom";
import NftCards from "@components/NFTokens/tokens/Aristippus/components/NftCards";
import { NFTokens } from "@config/CryptoConfig";
import { BackgroundImage } from 'react-image-and-background-image-fade';
import "@components/NFTokens/tokens/Aristippus/styles/aristippus_nft.scss";
import Loading from "@components/NFTokens/components/Loading";
import TopColorSwitch from "@components/NFTokens/tokens/Aristippus/components/TopColorSwitch";
import BottomColorSwitch from "@components/NFTokens/tokens/Aristippus/components/BottomColorSwitch";


const AristippusToken = () => {

    const { color = 'yellow' } = useParams();
    const { loaded, nftColorData } = useContext(AristippusContext);

    if (!loaded) return <Loading />;

    return (
        <div className="aristippus_container">
            <TopColorSwitch />
            <div className="maMain__textWrap">
                <div className="maMain__text">
                    The <b>NFT Meta Aristippus</b> collection is the embodiment of ancient wisdom in the digital format, which helps people develop civilization, comprehend the laws of the universe and create a just and prosperous society.
                </div>
                <div className="maMain__text">
                    <b>* NFT</b> consists of five unique collections of guidelines from the ancient Greek philosopher Aristippus of Cyrene.
                </div>
            </div>

            <div className="maMain__menuWrap">
                <Link to={`/${NFTokens.NFTMetaAristippus.baseUrl}/yellow`} className={`maMain__menuLink maMain__menuLink-yellow ${color == 'yellow' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_nft/menu/MA-Y-Aristippus.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />YELLOW set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-red">{nftColorData[0] ? nftColorData[0] : 0}</span>
                    </div>
                </Link>
                <Link to={`/${NFTokens.NFTMetaAristippus.baseUrl}/red`} className={`maMain__menuLink maMain__menuLink-red ${color == 'red' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_nft/menu/MA-R-Aristippus.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />RED set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-red">{nftColorData[1] ? nftColorData[1] : 0}</span>
                    </div>
                </Link>
                <Link to={`/${NFTokens.NFTMetaAristippus.baseUrl}/green`} className={`maMain__menuLink maMain__menuLink-green ${color == 'green' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_nft/menu/MA-G-Aristippus.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />GREEN set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-red">{nftColorData[2] ? nftColorData[2] : 0}</span>
                    </div>
                </Link>
                <Link to={`/${NFTokens.NFTMetaAristippus.baseUrl}/blue`} className={`maMain__menuLink maMain__menuLink-blue ${color == 'blue' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_nft/menu/MA-B-Aristippus.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />BLUE set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-red">{nftColorData[3] ? nftColorData[3] : 0}</span>
                    </div>
                </Link>
                <Link to={`/${NFTokens.NFTMetaAristippus.baseUrl}/purple`} className={`maMain__menuLink maMain__menuLink-purple ${color == 'purple' ? 'maMain__menuLink-active' : ''}`}>
                    <BackgroundImage
                        useChild
                        width='100%'
                        lazyLoad
                        wrapperClassName="maMain__menuLink-preloader"
                        src={require('@images/aristippus_nft/menu/MA-P-Aristippus.png')}
                    >
                        <div className="maMain__menuLink__bg"></div>
                    </BackgroundImage>
                    <div className="maMainMenuLink__hover">
                        <div className="maMainMenuLinkHover__arrow">
                            →
                        </div>
                        <div className="maMainMenuLinkHover__text">
                            Go to<br />PURPLE set
                        </div>
                    </div>
                    <div className="maMainMenuLink__number">
                        <span className="maMainMenuLink__number-red">{nftColorData[4] ? nftColorData[4] : 0}</span>
                    </div>
                </Link>
            </div>

            <div className="collectionWrap">
                {/* <div className="collectionWrap__row">
                    <div className="collectionWrapRow__elem">
                        Текущая стоимость коллекции:
                    </div>
                    <div className="collectionWrapRow__elem collectionWrapRow__elem-valueWrap">
                        <div className="collectionWrapRowElem__value">
                            100 <span className="gos__logo">GOS</span>
                        </div>
                        <div className="collectionWrapRowElem__value-line"></div>
                        <div className="collectionWrapRowElem__value">
                            100 <span className="bonus__logo">BONUS</span>
                        </div>
                    </div>
                </div> */}
                <div className="maCollection__content1">
                    <NftCards />

                    {/* <CollectAll /> */}
                </div>
            </div>
            <BottomColorSwitch />
        </div>
    )
}

export default AristippusToken;