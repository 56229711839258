import React, { useEffect, useState, useContext } from "react";
import NumberFormat from "@utils/NumberFormat";
import { ExpansionContext } from "@providers/ExpansionContext";
import { coinsConfig, expansionConfig } from "@config/CryptoConfig";

const Expansion = ({coin, index}) => {
    
    const { balance, stake, expansionBalance, collect, unStake } = useContext(ExpansionContext);
    const [inputValue, setInputValue] = useState('0.0000');
    const { title } = coinsConfig[coin];
    const minValue = 0.01;

    const addMax = () => {
        setInputValue(NumberFormat(balance, 4, '.', ''));
    }

    //console.log('expansionBalance ', expansionBalance );
    // //console.log('expansionConfig ', expansionConfig);

    return (
        <div className="expansion">
            {/* {index == 2 ?
                <div className="requiredNft">
                    <div className="requiredNft__bg"></div>
                    <div className="requiredNft__text">You do not have the required amount of NFT <br/>ADVANCED for this level.</div>
                </div>
                :
                <></>
            } */}
            <div className="expansion__title">
                {title} <span className="expansion__title-span">LEVEL {index + 1}</span>
            </div>
            <div className="expansion__info">
                <div className="expansionInfo-text">{expansionConfig[coin][index].dayPercent}% daily | {expansionConfig[coin][index].yearPercent}% annually</div>
                <div className="expansionInfo__row">
                    <div className="expansionInfo__elem">
                        Reserved:
                    </div>
                    <div className="expansionInfo__elem">
                        {expansionBalance[index] && expansionBalance[index].length > 0 ?
                            <>{NumberFormat(expansionBalance[index][0], 4, '.', '')}</>
                            :
                            <>0.0000</>
                        }
                    </div>
                </div>
                <div className="expansionInfo__row">
                    <div className="expansionInfo__elem">
                        Created:
                    </div>
                    <div className="expansionInfo__elem">
                        {expansionBalance[index] && expansionBalance[index].length > 0 ?
                            <>{NumberFormat(expansionBalance[index][1], 4, '.', '')}</>
                            :
                            <>0.0000</>
                        }
                    </div>
                </div>
            </div>
            <div className="expansion__inputWrap">
                <div className="expansionInputWrap__title">
                    GRDAO COIN:
                </div>
                <div className="expansionInputWrap__input">
                    <input type="text" value={inputValue} className="expansionInputWrap__input2" onChange={(event) => setInputValue(event.target.value)} />
                </div>
                <div className="expansionInputWrap__btnWrap">
                    <button type="button" className="expansionInputWrap__btn" onClick={() => addMax()}>
                        ADD MAX <br/>
                        <span className="expansionInputWrap__btn-span">({NumberFormat(balance, 4, '.', '')})</span>
                    </button>
                </div>
            </div>
            <div className="expansion__btnWrap">
                {parseFloat(inputValue) > 0 && parseFloat(inputValue) <= parseFloat(balance) && parseFloat(inputValue) >= parseFloat(minValue) ?
                <button type="button" className="expansion__btn" onClick={() => stake(index, inputValue)}>Reserve</button>
                :
                <button type="button" className="expansion__btn expansion__btn-disabled">Reserve</button>
            }
                {expansionBalance[index] && expansionBalance[index].length > 0 && expansionBalance[index][1] > 0 ?
                    <button className="expansion__btn" onClick={() => collect(index)}>Collect</button>
                    :
                    <button className="expansion__btn expansion__btn-disabled">Collect</button>
                }
                
            </div>
            {(expansionBalance[index] && expansionBalance[index].length > 0 && expansionBalance[index][0] > 0) ?
                <button className="expansion__cancleBtn" onClick={() => unStake(index)}>Cancel the reservation</button>
                :
                <></>
            }
        </div>



        // <div>
        //     <div>{coin}/{expansionConfig[coin][index]['address']}</div>
        //     <div>balance: {NumberFormat(balance[0], 4, '.', '')}</div>
        //     <div>royalty: {NumberFormat(balance[1], 4, '.', '')}</div>
        //     <input />
        //     <button>add max</button>
        //     <button onClick={() => stake()}>reserve</button>
        //     <button>collect</button>
        //     <button>cancel</button>
        // </div>
    )

}

export default Expansion;