import NFTContainer from "@components/NFTokens/components/NFTContainer";
import AlphabetTokenDetail from "@components/NFTokens/tokens/Alphabet/AlphabetTokenDetail";
import AlphabetProvider from "@components/NFTokens/contexts/AlphabetContext";
import React from "react";

const AlphabetDetailPage = () => (
    <NFTContainer>
        <AlphabetProvider>
            <AlphabetTokenDetail />
        </AlphabetProvider>
    </NFTContainer>
);

export default AlphabetDetailPage;