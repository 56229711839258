import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { nftCollections } from "@config/CryptoConfig";
import GetBalance from "@components/wallet/actions/nft_collection/GetBalance";
import { Link } from "react-router-dom";
import { Image } from 'react-image-and-background-image-fade';

const SmallCard = ({ address }) => {

    const { slug, icon } = nftCollections[address];

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);

    const [count, setCount] = useState(0);

    useEffect(() => {
        GetBalance(address, wallet)
            .then((balance) => {
                setCount(balance);
            }).catch((e) => {
                //console.log(e, slug);
            })
    }, [])

    // //console.log(slug, count);

    if (count == 0) {
        return <></>
    }

    return (
        <Link to={count > 0 ? `nft_collection/${slug}` : ''}>
            <div className={['nftCollections__wrap', count > 0 ? 'nftCollections__wrap-has' : undefined].join(' ')}>
                <Image
                    width='100%'
                    height='100%'
                    lazyLoad
                    wrapperClassName="maDetailContentMain__img"
                    src={icon}
                />
            </div>
        </Link>
    )

}

export default SmallCard;
