const defaultState = {
    transactions: {},
};

export const transactionReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "ADD_TRANSACTION":

            if(!state.transactions[action.payload.coin]){
                state.transactions[action.payload.coin] = [];
            }

            const newTransactions = [action.payload, ...state.transactions[action.payload.coin]];

            return {
                ...state, 
                transactions: {
                    ...state.transactions,
                    [action.payload.coin]: newTransactions
                }
            }

        case 'PURGE': 
            return defaultState;
        default:
            return state;
    }
}