import React from "react";
import './index.scss';

const NftCollectionsWrapper = ({children, slug}) => {
    return(
        <div className={`defaultWrapper wrapper-${slug}`}>
            <div className="container">
                {children}
            </div>
        </div>
    )
}

export default NftCollectionsWrapper;