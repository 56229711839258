import NFTContainer from "@components/NFTokens/components/NFTContainer";
import WisdomTokenDetail from "@components/NFTokens/tokens/Wisdom/WisdomTokenDetail";
import WisdomProvider from "@components/NFTokens/contexts/WisdomContext";
import React from "react";

const WisdomDetailPage = () => (
    <NFTContainer>
        <WisdomProvider>
            <WisdomTokenDetail />
        </WisdomProvider>
    </NFTContainer>
);

export default WisdomDetailPage;