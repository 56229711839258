import React, { useContext } from "react";
import { AlphabetContext } from "@components/NFTokens/contexts/AlphabetContext";
import { useParams } from "react-router-dom";
import CollectionBlock from "@components/NFTokens/tokens/Alphabet/components/CollectionBlock";
import Loading from "@components/NFTokens/components/Loading";
import NotFound from "@components/NFTokens/components/NotFound";

const AlphabetTokenCollection = () => {

    const { color = 'yellow', guidline } = useParams();
    const { nftCollectionData, loadedCollection } = useContext(AlphabetContext);
    const count = nftCollectionData.length;

    if (!loadedCollection) return <Loading />;
    if (!count) return <NotFound />;

    return (
        <div className={`series-color-${color}`}>
            <div className="alphabet_ma__collection alphabet_ma__collectionPage">
                {[...Array(count)].map((x, i) =>
                    <CollectionBlock key={i} index={i} />
                )}
            </div>
        </div>
    )

}

export default AlphabetTokenCollection;