import React, { useContext } from "react";
import { AlphabetContext } from "@components/NFTokens/contexts/AlphabetContext";
import NftCards from "@components/NFTokens/tokens/Alphabet/components/NftCards";
import Loading from "@components/NFTokens/components/Loading";
import '@components/NFTokens/tokens/Alphabet/styles/alphabet.scss';
import TopColorSwitch from "@components/NFTokens/tokens/Alphabet/components/TopColorSwitch";
import BottomColorSwitch from "@components/NFTokens/tokens/Alphabet/components/BottomColorSwitch";


const AlphabetToken = () => {

    const { loaded } = useContext(AlphabetContext);

    if (!loaded) return <Loading />;

    return (
        <div className="alphabet_ma__mainWrap">
            <TopColorSwitch />
            <NftCards />
            <BottomColorSwitch />
        </div>
    );
}

export default AlphabetToken;