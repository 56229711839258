import Swal from "sweetalert2"
import Web3 from "web3";
import { rpcLink } from "@config/CryptoConfig";

export const CoinFeeDialog = async (coinConfig, address, amount) => {

    const { minABI, contractAddress, title, transferFee } = coinConfig;

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(minABI, contractAddress);

    //console.log(address, amount);

    return new Promise(async (resolve, reject) => {
        try{
            await contract.methods.calcCoinfees(address, amount * 100000000).call()
                .then((result) => {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Attention!',
                        text: `The cost of this operation is ${Number(parseFloat(result / 100000000)).toFixed(8)} GOS Coin`,
                        showCancelButton: true,
                        confirmButtonText: 'Continue',
                        backdrop: true,
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return resolve(true);
                        }
                        return reject(false);
                    })
                }).catch((e) => {
                    return reject(false);
                })
        } catch (e) {
            return reject(false);
        }
        
    })

}