import React, { useState, useContext } from "react";
import { AristippusCodex2023Context } from "@components/NFTokens/contexts/AristippusCodex2023Context";
import { CoinContext } from "@components/NFTokens/contexts/CoinContext";
import Swal from "sweetalert2";
import { SpinnerWhite } from '@components/spinner';
import Web3 from "web3";
import { NFTokens } from "@config/CryptoConfig";
import SendNft from "@components/NFTokens/actions/SendNft";
import { sendPrice, sendAktPrice } from "@config/AristippusNftConfig";

const Send = ({ data }) => {

    const { wallet, redirect } = useContext(AristippusCodex2023Context);
    const { balance, undateCoinBalance } = useContext(CoinContext);
    const [loading, setLoading] = useState(false);

    const price = data.akt > 0 ? sendAktPrice : sendPrice;

    const send = (tokenId) => {

        if (balance['GOS'] && balance['GOS'] < price) {
            Swal.fire({
                icon: 'error',
                width: 600,
                title: 'Not enough GOS Coin on the account.',
                text: `The cost of this operation is ${price} GOS Coin`,
            });
            return false;
        }

        setLoading(true);

        Swal.fire({
            icon: 'question',
            title: `Enter GOS Wallet address of your Partner:`,
            html: `Attention! The cost of this operation: ${price} GOS Coin`,
            showCancelButton: true,
            confirmButtonText: 'Send',
            input: 'text',
            cancelButtonColor: '#d33',
            inputAttributes: {
                autocapitalize: 'off',
                placeholder: 'GOS Wallet address'
            },
            width: 600,
            preConfirm: (address) => {
                var web3 = new Web3();
                if (!web3.utils.isAddress(address)) {
                    Swal.showValidationMessage(
                        `Incorrect address format`
                    )
                }

                if (('0x' + wallet.address).toLowerCase() == address.toLowerCase()) {
                    Swal.showValidationMessage(
                        `Incorrect address format`
                    )
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                sendFetch(result.value, tokenId);
            } else {
                setLoading(false);
            }
        })
    }

    const sendFetch = (address, tokenId) => {
        Swal.fire({
            icon: 'warning',
            title: `Are you sure that you want to transfer NFT Meta Codex 2023?`,
            html: `Send to address: ${address}`,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonColor: '#d33',
            denyButtonText: `Cancel`,
            preConfirm: async () => {

                await SendNft('NFTMetaCodex2023', tokenId, address, wallet)
                    .then((data) => {
                        return data;
                    })
                    .catch((e) => {
                        Swal.showValidationMessage(
                            `Request failed: ${e}`
                        )
                        setLoading(false);
                        return false;
                    })
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'Your request has been successfully submitted'
                    })
                        .then(() => redirect(`/${NFTokens.NFTMetaCodex2023.baseUrl}/yellow`))

                    undateCoinBalance('GOS');
                } else {
                    Swal.fire('Error', `Something went wrong. Try again later.`, 'error');
                }
            } else {
                setLoading(false);
            }
        });
    }

    return (
        <>
            {!loading ?
                <div onClick={() => send(data.tId)} className="maDetailContent__btn">send</div>
                :
                <div onClick={() => send(data.tId)} className="maDetailContent__btn"><SpinnerWhite /></div>
            }
        </>
    )
}

export default Send;
