
const NFTVolumeDetailPage = () => {

    return (
        <div>
            <h1>
                NFTVolumeDetailPage
            </h1>
        </div>
    );
}

export default NFTVolumeDetailPage;