import Web3 from "web3";
import { rpcLink } from "@config/CryptoConfig";

export const GetGasAmount = async (fromAddress, toAddress, data) => {

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);

    const gasAmount = await web3.eth.estimateGas({
        to: toAddress,
        from: fromAddress,
        data: data,
    });

    return gasAmount;
}