import Converter from "@components/wallet/converter/Converter";
import useDaoDaoaConvertation from "@components/wallet/convertations/DaoDaoaConvertation/hooks/useDaoDaoaConvertation";
import { exchangeWallet } from "@components/wallet/convertations/DaoDaoaConvertation/actions/daoDaoaActions";

const DaoDaoaConvertation = () => {

    const { daoDaoaProps, setDaoDaoaProps, error, setError } = useDaoDaoaConvertation();

    return (
        <Converter wallet={{ walletDetails: daoDaoaProps, setWalletDetails: setDaoDaoaProps }} action={exchangeWallet} error={{ error, setError }} />
    );
}

export default DaoDaoaConvertation;