import { composeWithDevTools } from '@redux-devtools/extension';
import { combineReducers, createStore } from "redux";

import { walletReducer } from "./reducers/walletReducer";
import { transactionReducer } from './reducers/transactionReducer';
import { aristippusNftReducer } from './reducers/aristippusNftReducer';
import { aristippusWisdomReducer } from './reducers/aristippusWisdomReducer';
import { transformatorReducer } from './reducers/transformatorReducer';
import { alphabetReducer } from './reducers/alphabetReducer.js';
import { nftVolumeReducer } from './reducers/nftVolumeReducer.js';
import { GOSHoldReducer } from './reducers/GOSHoldReducer.js';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const rootReducer = combineReducers({
    walletReducer,
    transactionReducer,
    aristippusNftReducer,
    aristippusWisdomReducer,
    transformatorReducer,
    alphabetReducer,
    nftVolumeReducer,
    GOSHoldReducer
})

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
// const persistedReducer = rootReducer;

export const store = createStore(persistedReducer, composeWithDevTools());
export const persistor = persistStore(store);