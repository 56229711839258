import React, { useContext } from "react";
import { TransformatorContext } from "@components/NFTokens/contexts/TransformatorContext";
import { useParams } from "react-router-dom";
import CollectionBlock from "@components/NFTokens/tokens/Transformator/components/CollectionBlock";
import Loading from "@components/NFTokens/components/Loading";
import NotFound from "@components/NFTokens/components/NotFound";

const TransformatorTokenCollection = () => {

    let { color = 'yellow', guidline } = useParams();
    const { nftCollectionData, loadedCollection } = useContext(TransformatorContext);
    const count = nftCollectionData.length;

    if (!loadedCollection) return <Loading />;
    if (!count) return <NotFound />;

    return (
        <div className="cardFull__wrap">
            <div className="cardFull">
                {[...Array(count)].map((x, i) =>
                    <CollectionBlock key={i} index={i} />
                )}
            </div>
        </div>
    );
}

export default TransformatorTokenCollection;