import NFTContainer from "@components/NFTokens/components/NFTContainer";
import AlphabetToken from "@components/NFTokens/tokens/Alphabet/AlphabetToken";
import AlphabetProvider from "@components/NFTokens/contexts/AlphabetContext";


const AlphabetPage = () => (
    <NFTContainer>
        <AlphabetProvider>
            <AlphabetToken />
        </AlphabetProvider>
    </NFTContainer>
);

export default AlphabetPage;