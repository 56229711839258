import NFTContainer from "@components/NFTokens/components/NFTContainer";
import VolumeToken from "@components/NFTokens/tokens/Volume/VolumeToken";
import NFTVolumeProvider from "@components/NFTokens/contexts/NFTVolumeContext";


const NFTVolumePage = () => (
    <NFTContainer>
        <NFTVolumeProvider>
            <VolumeToken />
        </NFTVolumeProvider>
    </NFTContainer>
);

export default NFTVolumePage;