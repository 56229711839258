import React, { useState, useContext } from "react";
import { CoinContext } from "@components/NFTokens/contexts/CoinContext";
import Swal from "sweetalert2";
import Web3 from "web3";
import SendNft from "@components/NFTokens/actions/SendNft";
import styles from '@components/NFTokens/tokens/GOSHold/styles/GOSHoldActions.module.scss';
import { useNavigate } from "react-router-dom";
import { SpinnerWhite } from "@components/spinner";
import { SendGOSHoldBonus } from "@components/NFTokens/tokens/GOSHold/fetches/fetchGOSHold";

const Send = ({ token, wallet, GOSHoldReducer, tokenName, isBonus }) => {

    const { balance, undateCoinBalance } = useContext(CoinContext);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const price = GOSHoldReducer.transferFee / (10 ** 8);

    const send = (tokenId) => {

        if (balance['GOS'] && balance['GOS'] < price) {
            Swal.fire({
                icon: 'error',
                width: 600,
                title: 'Not enough GOS Coin on the account.',
                text: `The cost of this operation is ${price} GOS Coin`,
            });
            return false;
        }

        setLoading(true);

        Swal.fire({
            icon: 'question',
            title: `Enter GOS Wallet address of your Partner:`,
            html: `Attention! The cost of this operation: ${price} GOS Coin`,
            showCancelButton: true,
            confirmButtonText: 'Send',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off',
                placeholder: 'GOS Wallet address'
            },
            width: 600,
            preConfirm: (address) => {
                var web3 = new Web3();
                if (!web3.utils.isAddress(address)) {
                    Swal.showValidationMessage(
                        `Incorrect address format`
                    )
                }

                if (('0x' + wallet.address).toLowerCase() == address.toLowerCase()) {
                    Swal.showValidationMessage(
                        `Incorrect address format`
                    )
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                sendFetch(result.value, tokenId);
            } else {
                setLoading(false);
            }
        })
    }

    const sendFetch = (address, tokenId) => {
        Swal.fire({
            icon: 'warning',
            title: `Are you sure that you want to transfer ${tokenName}`,
            html: `Send to address: ${address}`,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonColor: '#d33',
            denyButtonText: `Cancel`,
            preConfirm: async () => {
                setLoading(true);

                if (!isBonus) return await SendNft('NFTGOSHold', tokenId, address, wallet)
                    .then((data) => {
                        return data;
                    })
                    .catch((e) => {
                        Swal.showValidationMessage(
                            `Request failed: ${e}`
                        )
                        setLoading(false);
                        return false;
                    })

                await SendGOSHoldBonus(tokenId, address, wallet)
                    .then((data) => {
                        return data;
                    })
                    .catch((e) => {
                        Swal.showValidationMessage(
                            `Request failed: ${e}`
                        )
                        setLoading(false);
                        return false;
                    })
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'Your request has been successfully submitted'
                    })
                        .then(() => navigate(0))

                    undateCoinBalance('GOS');
                } else {
                    Swal.fire('Error', `Something went wrong. Try again later.`, 'error');
                }
            } else {
                setLoading(false);
            }
        });
    }

    return (
        <>
            {
                loading
                    ? <SpinnerWhite />
                    : <button onClick={() => send(token.tId)} className={styles.button}>
                        Transfer
                    </button>
            }
        </>
    );
}

export default Send;
