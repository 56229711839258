import React from "react";
import NftCollectionContext from "@providers/NftCollectionContext";
import DetailCollection from "@components/wallet/nft_collection/DetailCollection";

const CollectionPage = () => {

    return (
        <NftCollectionContext>
            <DetailCollection />
        </NftCollectionContext>
    );


}

export default CollectionPage;
