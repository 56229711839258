const defaultState = {
    wallets: [],
    passwords: [],
};

export const walletReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "IMPORT_WALLET":
            return {...state, wallets: [...state.wallets, action.payload]}
        case "SET_PASSWORD":
            return {...state, passwords: [...state.passwords, {[action.payload.address]: action.payload.password}]}
        case 'PURGE': 
            return defaultState;
        default:
            return state;
    }
}