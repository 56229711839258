import { passwordCheck } from "@utils/PasswordCheck";
import { validateConvertataionInput } from "@utils/validate";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import styles from '@components/wallet/converter/styles/Converter.module.scss';
import Web3 from "web3";

const MySwal = withReactContent(Swal);

export const handleChange = (event, walletDetails, setWalletDetails) => {
    const name = event.target.name;
    const value = event.target.value.replace(/ /g, '');

    if (validateConvertataionInput(value)) {
        if (value === '') return setWalletDetails((prevState) => ({ ...prevState, input: { from: '', to: '' } }));

        if (name === 'from') {
            if (value > parseFloat(walletDetails.balance['from'])) return setWalletDetails((prevState) => ({ ...prevState, input: { from: walletDetails.balance['from'], to: (walletDetails.balance['from'] * walletDetails.course.from).toFixed(8) } }));
            return setWalletDetails((prevState) => ({ ...prevState, input: { from: value, to: (value * walletDetails.course.from).toFixed(8) } }));
        }

        if (name === 'to') {
            if (value > parseFloat(walletDetails.balance['to'])) return setWalletDetails((prevState) => ({ ...prevState, input: { from: (walletDetails.balance['to'] * walletDetails.course.to).toFixed(8), to: walletDetails.balance['to'] } }));
            return setWalletDetails((prevState) => ({ ...prevState, input: { from: (value * walletDetails.course.to).toFixed(8), to: value } }));
        }
    }
}

export const handleWalletSwap = (setWalletDetails) => {
    setWalletDetails((prevState) => ({
        ...prevState,
        coin: {
            from: prevState.coin.to,
            to: prevState.coin.from
        },
        balance: {
            from: prevState.balance.to,
            to: prevState.balance.from
        },
        course: {
            from: prevState.course.to,
            to: prevState.course.from
        },
        input: {
            from: '',
            to: ''
        }
    }));
}

export const handleExchange = (walletDetails, setWalletDetails, action, updateBalanceAllCoins, setError) => {

    // console.log(walletDetails);
    // console.log(walletDetails.fee.value > walletDetails.fee.balance);
    // console.log(typeof walletDetails.fee.value, typeof walletDetails.fee.balance);

    // console.log("2" < "120");

    MySwal.fire({
        icon: 'warning',
        title: <p className={styles.alert_title}>Upon exchange you will receive: <br /><b>{walletDetails.input.to} {walletDetails.coin.to.name}</b></p>,
        html: <p>The cost of this operation is <b>{walletDetails.fee.value} {walletDetails.fee.name}</b></p>,
        allowOutsideClick: true,
        confirmButtonText: 'Exchange',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        customClass: {
            title: styles.alert_title
        },
        preConfirm: () => {
            // if (walletDetails.fee.value > walletDetails.fee.balance) {
            if (parseFloat(walletDetails.fee.value) > walletDetails.fee.balance) {
                Swal.showValidationMessage(`Not enough ${walletDetails.fee.name}`);
            }
        }
    })
        .then(async (result) => {
            if (!result.isConfirmed) return;

            const password = await passwordCheck();
            if (!password) return;

            let errorMessage = '';

            MySwal.fire({
                didOpen: () => {
                    MySwal.showLoading();

                    setTimeout(() => action(walletDetails, password)
                        .then(() => {
                            MySwal.clickConfirm();
                        })
                        .catch((err) => {
                            setError(err);
                            errorMessage = err;
                            MySwal.clickDeny();
                        }), 100);

                }
            })
                .then((result) => {
                    if (!result.isConfirmed) return MySwal.fire({ icon: 'error', title: 'Error', text: "Something went wrong. Try again later.", showConfirmButton: false, timer: 3000 });

                    MySwal.fire({ icon: 'success', showConfirmButton: false, timer: 3000 });
                    updateBalanceAllCoins();
                })
                .finally(() => setWalletDetails((prevState) => ({
                    ...prevState, input: {
                        from: '',
                        to: ''
                    }
                })));
        })
}