export const unfarmPrice = 1;
export const farmPrice = 3;
export const sendPrice = 1;
export const sendAktPrice = 3;

export const titleData = {
    "1": {
        "title": "SELF-CONFIDENCE",
        "descr": "Reasonable self-reliance and confidence in one\u2019s ability to perform a specific job, as well as the ability to convey this confidence to other people, is an excellent resource for success in any business."
    },
    "2": {
        "title": "ABILITY TO GET RESULTS",
        "descr": "A person\u2019s professionalism can be seen in the ability to obtain a predictable result in the expected time frame. You must have everything you need to get the result \u2014 knowledge, education, experience, instruments."
    },
    "3": {
        "title": "UNDERSTANDING YOUR CLIENT",
        "descr": "Knowing and understanding your client is one of your most important competitive advantages. The value of a product or service is manifested only at the time the customer uses this product or service."
    },
    "4": {
        "title": "CERTAINTY",
        "descr": "Certainty is the confidence in someone or something, especially such confidence that is based on concrete evidence. Certainty in a certain area is achieved by removing doubts from it."
    },
    "5": {
        "title": "RELIABILITY",
        "descr": "The basic idea of reliability lies in the genuineness and authenticity of one's plans or promises. The world of business is full of promises to customers, but the degree of compliance of these promises to what clients ultimately receive for their money reflects the degree of reliability of the person or organization."
    },
    "6": {
        "title": "ACCOUNTABILITY",
        "descr": "A responsible person often achieves the result expected from him or her, even if the external control over his or her activities is completely absent. A person can be called responsible only if this person has all the necessary knowledge, experience and skills to guarantee the high quality of the work."
    },
    "7": {
        "title": "DECENCY",
        "descr": "In general, decency implies such behavior that is accepted by most people and corresponds to the expectations existing among people in the society. Your decency creates a solid foundation for other people\u2019s trust and becomes a \u201cmagnet\u201d that attracts success to you."
    },
    "8": {
        "title": "ABILITY TO MAKE DECISIONS",
        "descr": "Decision making is inevitable for people in the course of their lives. To act effectively, it is important to understand that any situation has its own cause. The main enemy of decision-making ability is resistance to change. The path to success goes through the acceptance of numerous changes and the wise choice of one of them!"
    },
    "9": {
        "title": "DIPLOMACY",
        "descr": "From time to time we encounter difficult situations with various kinds of conflicts. In such situations, people who can show enough tact and diplomacy to cope with tense and complex interpersonal conflicts are highly appreciated."
    },
    "10": {
        "title": "CARING FOR OTHER PEOPLE",
        "descr": "One of the best ways to get people's trust is to be responsive to their problems and to URGENTLY respond to their requests. When you react in this way, you express your genuine interest in their existence and clearly demonstrate your respect and that you care."
    },
    "11": {
        "title": "DOUBT",
        "descr": "Doubt is often defined as a state of hesitance and the inability of a person to make any decision. Make decisions carefully, within a reasonable timeframe, but what is most important \u2014 be sure to make decisions! Do not be afraid to make a mistake. The biggest mistake will be the refusal to make decisions and take actions."
    },
    "12": {
        "title": "EQUIVOCATION",
        "descr": "We call someone\u2019s behavior equivocal if this person avoids answering direct questions using various tricks and ploys. Equivocation is always aimed at keeping certain important information secret. Give precise definitions of the terms you use and avoid using words that have multiple meanings."
    },
    "13": {
        "title": "INCONSISTENCY",
        "descr": "From time to time, we all come across inconsistencies in words, promises, and human behavior. This can cause a lot of worries. Inconsistent actions distort clear expectations and make potential customers unsure of you and your products."
    },
    "14": {
        "title": "LACK OF PRIORITIES",
        "descr": "In any situation, there are many factors that can influence your decisions. Your personal values and priorities in life, that are very important and valuable for you, have a strong influence on your decisions. If other people know your priorities, then your behavior will be more understandable and predictable for them."
    },
    "15": {
        "title": "LACK OF ONE UNIFIED SYSTEM FOR MEASURING SUCCESS",
        "descr": "You can get to work without reaching a clear understanding with your client on how you both will measure the effectiveness of your work. Spend a reasonable amount of time on thorough discussion of what expectations the client has regarding results of your work before getting to work."
    },
    "16": {
        "title": "INABILITY TO ASK FOR HELP",
        "descr": "From time to time you may discover some aspects which are difficult to deal with. You may be tempted to hide yourself or hide your \u201cincompetence\u201d. This approach results in loss of trust. Avoid this pitfall and ask for help after you realize that you need it."
    },
    "17": {
        "title": "IGNORING CLIENTS",
        "descr": "Acquiring a new customer is significantly more expensive than retaining an existing customer. Retaining existing customers can increase business profits by a third or more. Ignoring customers can be expensive for your business."
    },
    "18": {
        "title": "INABILITY TO LISTEN",
        "descr": "If you don\u2019t listen to what other people tell you, they will begin to believe that you don\u2019t take their feelings and problems into account. It also shows that you are only and exclusively interested in your own problems. The fastest way to trust lies in the ability to listen to a person with full attention."
    },
    "19": {
        "title": "UNCLEAR REPUTATION",
        "descr": "To succeed, you must pay particular attention to your reputation. Reputation shapes other people\u2019s expectations about your personal experience, abilities and disabilities. Good reputation opens up opportunities and provides unhindered access to your ideal customer base."
    },
    "20": {
        "title": "UNWILLINGNESS TO LEARN",
        "descr": "The main purpose of training \u2014 to figure out areas of your ignorance and correctly formulate the problem that you need to solve NOW, and not the problem that you had to solve yesterday or last year. Your path to success begins with this first step that is readiness and desire to learn."
    },
    "21": {
        "title": "BEING VALUABLE AND IMPORTANT TO PEOPLE",
        "descr": "You have more chances to succeed if you can offer people something valuable and important \u2014 what they\u2019re ready to give their attention to RIGHT NOW. The value of your activities can be measured by the amount of attention customers are willing to give to your solutions, products or services."
    },
    "22": {
        "title": "ABILITY TO SOLVE PROBLEMS",
        "descr": "Only after you identify and formulate the problem, you can find a suitable solution for it. If you are successful in solving your own problems and the problems of other people, then you will begin to build a reputation for yourself as a person whom many people will be interested to deal with."
    },
    "23": {
        "title": "READINESS TO HELP",
        "descr": "The main thing is the willingness and desire to help another person. These are the qualities that are valued in any healthy society more than anything else in the world. If people know that they can count on your help, they will keep you in the field of their attention. For you, this is a very valuable resource \u2014 to be in the field of positive attention of others."
    },
    "24": {
        "title": "FLEXIBILITY AT WORK",
        "descr": "In our time of constant change, a person\u2019s ability to adapt to changing conditions, work in different situations and understand the different needs of clients is highly valued. This requires a person to have a positive attitude to change, the ability to see new opportunities in the changes, and not only threats."
    },
    "25": {
        "title": "THE ABILITY TO INFLUENCE",
        "descr": "In the course of our lives we influence other people and submit to their influence. In order to achieve a particular goal, you need help and support from other people. People must first support your ideas, goals and actions so that you can succeed later."
    },
    "26": {
        "title": "CREATIVE THINKING",
        "descr": "The ability of creative thinking suggests the ability to create something new, deviate from accepted patterns of thinking and successfully solve problems in a non-standard way. The development of creative thinking is an important aspect for people who seek to achieve success in their field of activity."
    },
    "27": {
        "title": "LEADERSHIP QUALITIES",
        "descr": "The main concept of leadership is the leader\u2019s ability to actively promote the value of achieving a particular goal, and to show an effective way to achieve this desired goal. If you want to become a true leader, then you have a difficult task: you must act as a leader."
    },
    "28": {
        "title": "RECOGNITION OF OTHER PEOPLE'S ABILITIES",
        "descr": "Often abilities go unnoticed neither by the people themselves nor by their immediate environment. When we talk to each other about such valuable abilities, we get the feeling that these abilities in us \u201ccome to life\u201d, become stronger and ready to manifest in real actions."
    },
    "29": {
        "title": "INTEREST IN OTHER PEOPLE",
        "descr": "If you begin to show sincere interest in other people, they will \u201crepay\u201d you with the same coin and return to you as friends. By showing interest in other people, you become for them a kind of \u201cmagnet\u201d to which they will be attracted. Show your sincere interest in other people and enjoy success and well-deserved friendship."
    },
    "30": {
        "title": "EFFECTIVE COMMUNICATION",
        "descr": "The ability to communicate effectively is the most important of all the skills a person needs to succeed. With the help of communication we can convey our thoughts to other people and understand what they want to tell us. Success directly depends on those people with whom you communicate."
    },
    "31": {
        "title": "COMPLEXITY OF PRODUCTS",
        "descr": "If you offer a complex product or service that is difficult to explain, then you will need particular accuracy in presenting and structuring information. By reducing the amount of information and giving the client only the most important information, you reduce the complexity for the client and increase your chances of success."
    },
    "32": {
        "title": "ERRORS IN WORKING WITH INFORMATION",
        "descr": "To make your product or service more useful to the customers, you may be required to provide them with various information. Consistently give your customers information about the product following the given structure and do not \u201cskip\u201d from one piece of information to another."
    },
    "33": {
        "title": "LACK OF LOGIC",
        "descr": "Cooperation with a person whose thinking is illogical, contradictory and confused, will not lead to anything good. Such people are not taken seriously. To achieve success in life, a person needs to demonstrate a good ability for logical thinking."
    },
    "34": {
        "title": "UNWANTED CHANGES",
        "descr": "Changes can be diverse. If your clients do not want changes in some area of their lives that could be caused by your product or service, then your offer will not arouse their interest. And vice versa, if your product or service can at least somehow change the area in which the client wants changes, your chances of success increase significantly."
    },
    "35": {
        "title": "LACK OF FEEDBACK",
        "descr": "Feedback allows to identify problems early and fix them quickly. This simple approach will allow to avoid complex problems in the future. If you know which of your customers love and appreciate your product or service the most, then you can focus all your efforts on this particular type of customers."
    },
    "36": {
        "title": "LACK OF CLEAR VISION",
        "descr": "Having a clear vision is the ability to determine where you are now and where you are planning to go. Besides, you need to understand exactly how to get to where you want to be."
    },
    "37": {
        "title": "IGNORING CULTURAL DIFFERENCES",
        "descr": "The laws of culture are significantly different from the laws of nature. In contrast to the laws of nature, which were the same in different situations, the behavior, customs and manners of people in different societies are diverse and changeable. What determines different customs and traditions, morals and laws, wholly and completely depends on the logic that the members of these societies are guided by."
    },
    "38": {
        "title": "TRYING TO DO EVERYTHING YOURSELF",
        "descr": "In order to get results in any endeavour, it is necessary to interact and coordinate with other people. You will greatly expand your capabilities if you learn to interact effectively with people with whom you will be able to exchange value."
    },
    "39": {
        "title": "REFUSING TO TAKE RESPONSIBILITY",
        "descr": "In any relationship, there are situations when you do wrong. To restore relations, you need to take some responsibility. Without taking responsibility for your mistakes, without offering a solution to your problems QUICKLY, you cannot succeed."
    },
    "40": {
        "title": "TOO MUCH FOCUS ON SALES",
        "descr": "If you want to sell, then you need to understand the value of your products for the customer. Give the customers valuable tips and tricks that they can apply to make their lives a little better. Make sure that the tips are somehow related to your product or service."
    }
}