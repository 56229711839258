import Web3 from 'web3';
import { useEffect, useState } from "react";
import { NFTokens, rpcLink } from '@config/CryptoConfig';
import { useDispatch, useSelector } from "react-redux";
import { fetchGOSHold, fetchGOSHoldBonus } from "@components/NFTokens/tokens/GOSHold/fetches/fetchGOSHold";
import { GetTransferFee } from '@components/NFTokens/utils/GetTransferFee';

const useGOSHold = () => {
    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);

    const { minABI, contractAddress, contractAddressBonus } = NFTokens.NFTGOSHold;

    const wallet = JSON.parse(useSelector(state => state.walletReducer.wallets)[0]);
    const contract = new web3.eth.Contract(minABI, contractAddress);
    const contractBonus = new web3.eth.Contract(minABI, contractAddressBonus);
    const dispatch = useDispatch();

    const [GOSHoldToken, setGOSHoldToken] = useState([]);
    const [GOSHoldBonusToken, setGOSHoldBonusToken] = useState([]);

    const tokenName = 'GOS Hold Token';

    useEffect(() => {
        fetchGOSHold(contract, wallet)
            .then((res) => setGOSHoldToken(res))
            .catch((err) => {
                // console.log(err)
            })

        fetchGOSHoldBonus(contractBonus, wallet)
            .then((res) => setGOSHoldBonusToken(res))
            .catch((err) => {
                // console.log(err);
            })

        GetTransferFee(contract, 'feeTransfer')
            .then((res) => dispatch({ type: 'SET_GOSH_TRANSFER_FEE', payload: res }))
            .catch((err) => {
                // console.log(err)
            })

    }, []);

    return { GOSHoldToken, GOSHoldBonusToken, wallet, tokenName };
}

export default useGOSHold;