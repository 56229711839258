import React, { useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { WisdomContext } from "@components/NFTokens/contexts/WisdomContext";
import { Romanize } from "@utils/Number";
import NumberFormat, { FarmedFormat } from "@utils/NumberFormat";
import { titleData } from "@config/AristippusWisdomConfig";
import NotFound from "@components/NFTokens/components/NotFound";
import Farm from "@components/NFTokens/tokens/Wisdom/actions/Farm";
import Burn from "@components/NFTokens/tokens/Wisdom/actions/Burn";
import Collect from "@components/NFTokens/tokens/Wisdom/actions/Collect";
import Send from "@components/NFTokens/tokens/Wisdom/actions/Send";
import { NFTokens } from "@config/CryptoConfig";

const WisdomTokenDetail = () => {

    const { color, guidline, index } = useParams();
    const { nftColorGuidelineData, nftCollectionData, daoMultiplier } = useContext(WisdomContext);

    if (!nftColorGuidelineData || !nftColorGuidelineData[guidline - 1] || !nftCollectionData || !nftCollectionData[index]) return <NotFound />;

    const image = 'MAW-' + color[0].toUpperCase() + '-' + Romanize(guidline) + '.png';
    const data = nftCollectionData[index];

    const isNitro = parseFloat(data.nitro) > 0 ? true : false;
    const isCombo = parseFloat(data.bns) > 1000 ? true : false;
    const isAdvanced = parseFloat(data.akt) > 0 ? true : false;
    const hasMultiplyer = isNitro || isCombo || isAdvanced ? true : false;
    const totalMultiplyer = (parseFloat(data.bns) + parseFloat(data.nitro) + parseFloat(data.akt)) * daoMultiplier;

    return (
        <div className="wisdom_container">
            <div className="maDetail">
                <div className="maDetail__top">
                    <div className="maDetailTop__titleWrap">
                        <div className="maDetailTop__titleWrap">
                            <div className="maDetailTop__title">{Romanize(guidline)} - {titleData[guidline]['title']}</div>
                            <div className="maDetailTop__subTitle">{data.Number}</div>
                        </div>
                    </div>
                </div>
                <div className="maDetail__content">
                    <div className={["maDetailContent__main", hasMultiplyer ? 'maDetailContent__main-multiplyer' : ''].join(' ')}>
                        <div className="maDetailContentMain__left">
                            <div className="maDetailContentMain__imgWrap">
                                <div className="maDetailContentMain__value">{data.Number}</div>
                                <img height="100%" width="100%" src={require(`@images/aristippus_wisdom/detail/${color}/${image}`)} className="maDetailContentMain__img" alt="" loading="lazy" />
                                {/* <Image
                                width='100%'
                                height='100%'
                                lazyLoad
                                wrapperClassName="maDetailContentMain__img"
                                src={require(`@images/aristippus_wisdom/detail/${color}/${image}`)}
                            /> */}
                            </div>
                        </div>
                        <div className="maDetailContentMain__right">

                            <div className="maCollection__promotion-icons">
                                {isNitro &&
                                    <div className="maCollection__promotion maCollection__promotion-nitro">
                                        <img src={require(`@images/aristippus_nft/icons/n.png`)} alt="nitro" />
                                    </div>
                                }

                                {isCombo &&
                                    <div className="maCollection__promotion maCollection__promotion-combo">
                                        <img src={require(`@images/aristippus_nft/icons/c.png`)} alt="combo" />
                                    </div>
                                }

                                {isAdvanced &&
                                    <div className="maCollection__promotion maCollection__promotion-advanced">
                                        <img src={require(`@images/aristippus_nft/icons/a.png`)} alt="advanced" />
                                    </div>
                                }
                            </div>

                            {totalMultiplyer > 1 &&
                                <span className="maDetailCollection__row-combination-bg">x{NumberFormat(totalMultiplyer, 2, '.', '')}</span>
                            }

                            <div className="maDetailContentMain__info">
                                <div className="maDetailContentMainInfo__row">
                                    <div className="maDetailContentMainInfo__elem maDetailContentMainInfo__title">
                                        Name:
                                    </div>
                                    <div className="maDetailContentMainInfo__elem maDetailContentMainInfo__result">
                                        {Romanize(guidline)} - {titleData[guidline]['title']}
                                    </div>
                                </div>
                                <div className="maDetailContentMainInfo__row">
                                    <div className="maDetailContentMainInfo__elem maDetailContentMainInfo__title">
                                        Description:
                                    </div>
                                    <div className="maDetailContentMainInfo__elem maDetailContentMainInfo__result">
                                        {titleData[guidline]['descr']}
                                    </div>
                                </div>
                                <br />
                                <div className="maDetailContentMainInfo__row">
                                    <div className="maDetailContentMainInfo__elem maDetailContentMainInfo__title">
                                        Number:
                                    </div>
                                    <div className="maDetailContentMainInfo__elem maDetailContentMainInfo__result">
                                        {data.Number}
                                    </div>
                                </div>
                                <div className="maDetailContentMainInfo__row">
                                    <div className="maDetailContentMainInfo__elem maDetailContentMainInfo__title">
                                        Collection:
                                    </div>
                                    <div className="maDetailContentMainInfo__elem maDetailContentMainInfo__result">
                                        <Link to={`/${NFTokens.NFTMetaWisdom.baseUrl}/${color}`} className='maDetailContentMainInfo__elem-link' style={{ textTransform: 'capitalize' }}>{color}</Link>
                                    </div>
                                </div>
                                <div className="maDetailContentMainInfo__row">
                                    <div className="maDetailContentMainInfo__elem maDetailContentMainInfo__title">
                                        Token ID:
                                    </div>
                                    <div className="maDetailContentMainInfo__elem maDetailContentMainInfo__result maDetailContentMainInfo__result-token">
                                        <a href={`${NFTokens.NFTMetaWisdom.metaWebsiteUrl}/${data.tId}`} target="_blank" className='maDetailContentMainInfo__elem-link'>{data.tId}</a>
                                    </div>
                                </div>
                                <div className="maDetailContentMainInfo__row">
                                    <div className="maDetailContentMainInfo__elem maDetailContentMainInfo__title">
                                        Token Standard:
                                    </div>
                                    <div className="maDetailContentMainInfo__elem maDetailContentMainInfo__result">
                                        ERC-721
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <ProductBlock data={data} /> */}

                    {data.lastFarming > 0 ?
                        <div className="maDetailContent__price">

                            <div className="maDetailContentPrice__row maDetailContentPrice__row-green">
                                <div className="maDetailContentPrice__elem">
                                    Farmed (GRDA):
                                </div>
                                <div className="maDetailContentPrice__elem">
                                    <div className="maDetailContentPrice__value">
                                        {FarmedFormat(data.coin)} <span className="gico__logo grda__logo">GRDA</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <br />
                            <br />
                            <br />
                        </>
                    }

                    <div className="maDetailContent__btnWrap">
                        <Burn data={data} />

                        <div className="maDetailContentBtnWrap__right">
                            {data.lastFarming > 0 ?
                                <>
                                    <Collect data={data} />
                                </>
                                :
                                <>
                                    <Send data={data} />
                                </>
                            }

                            <Farm data={data} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}

export default WisdomTokenDetail;
