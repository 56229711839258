export const msToSQLDate = (milliseconds) => {
    const date = new Date(milliseconds);

    const dateObject = {
        year: date.getFullYear(),
        month: `0${date.getMonth() + 1}`.slice(-2),
        day: `0${date.getDate()}`.slice(-2)
    }

    const dateString = `${dateObject.year}-${dateObject.month}-${dateObject.day}`;

    return dateString;
}